import React from "react";
import classNames from 'classnames/bind';
import {TemplateProps} from "./template-props";

export const calculateIcon = (props: TemplateProps) => {
  const iconClass = classNames(
    'icon',
    'ms-1',
    'color-table-link-icon',
    props.icon
  );
  return props.icon ? <div className={iconClass}></div> : <></>;
}

export const calculateIconElement = (data: any) => {
  const iconClass = classNames(
    'icon',
    data
  );
  return <div className={iconClass}></div>;
}

export const calculateLabel = (data: any,props: TemplateProps) => {
  return <span className={'color-table-link-label'}>{data}</span>;
}
