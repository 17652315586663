import _ from 'lodash';

export interface IIcons {
  name: string;
  value: string;
}

export const ICONS =
  {
    ICON_ACCEPT: {name: 'icon_accept', value: 'icon-accept'},
    ICON_ARCHIVE_MODIFY: {name: 'icon_archive_modify', value: 'icon-archive-modify'},
    ICON_CAR_MODIFY: {name: 'icon_car_modify', value: 'icon-car-modify'},
    ICON_ARCHIVE_NEXT: {name: 'icon_archive_next', value: 'icon-archive-next'},
    ICON_ADD: {name: 'icon_add', value: 'icon-add'},
    ICON_CAR_INFO: {name: 'icon_car_info', value: 'icon-car-info'},
    ICON_DOCUMENT_MODIFY: {name: 'icon_document_modify', value: 'icon-document-modify'},
    ICON_DOCUMENT_INFO: {name: 'icon_document_info', value: 'icon-document-info'},
    ICON_DOCUMENT_HELP: {name: 'icon_document_help', value: 'icon-document-help'},
    ICON_CAR_NEXT: {name: 'icon_car_next', value: 'icon-car-next'},
    ICON_CAR_PREVIOUS: {name: 'icon_car_previous', value: 'icon-car-previous'},
    ICON_ARCHIVE_REMOVE: {name: 'icon_archive_remove', value: 'icon-archive-remove'},
    ICON_ARCHIVE_PREVIOUS: {name: 'icon_archive_previous', value: 'icon-archive-previous'},
    ICON_ALERT: {name: 'icon_alert', value: 'icon-alert'},
    ICON_ADJUST: {name: 'icon_adjust', value: 'icon-adjust'},
    ICON_ARCHIVE: {name: 'icon_archive', value: 'icon-archive'},
    ICON_ARCHIVE_UPLOAD: {name: 'icon_archive_upload', value: 'icon-archive-upload'},
    ICON_CAR_REMOVE: {name: 'icon_car_remove', value: 'icon-car-remove'},
    ICON_DOCUMENT_OPTIONS: {name: 'icon_document_options', value: 'icon-document-options'},
    ICON_DOCUMENT_NEXT: {name: 'icon_document_next', value: 'icon-document-next'},
    ICON_FOLDER: {name: 'icon_folder', value: 'icon-folder'},
    ICON_FIVE: {name: 'icon_five', value: 'icon-five'},
    ICON_FACTORY: {name: 'icon_factory', value: 'icon-factory'},
    ICON_EURO: {name: 'icon_euro', value: 'icon-euro'},
    ICON_EIGHT: {name: 'icon_eight', value: 'icon-eight'},
    ICON_FOLDER_OPTIONS: {name: 'icon_folder_options', value: 'icon-folder-options'},
    ICON_FOLDER_PREVIOUS: {name: 'icon_folder_previous', value: 'icon-folder-previous'},
    ICON_FOLDER_SAVE: {name: 'icon_folder_save', value: 'icon-folder-save'},
    ICON_FOLDER_UPLOAD: {name: 'icon_folder_upload', value: 'icon-folder-upload'},
    ICON_FOUR: {name: 'icon_four', value: 'icon-four'},
    ICON_HOME: {name: 'icon_home', value: 'icon-home'},
    ICON_HELP_ICON: {name: 'icon_help_icon', value: 'icon-help-icon'},
    ICON_FOLDER_ADD: {name: 'icon_folder_add', value: 'icon-folder-add'},
    ICON_FOLDER_ALERT: {name: 'icon_folder_alert', value: 'icon-folder-alert'},
    ICON_DOCUMENT_REMOVE: {name: 'icon_document_remove', value: 'icon-document-remove'},
    ICON_DOCUMENT_PREVIOUS: {name: 'icon_document_previous', value: 'icon-document-previous'},
    ICON_CAR_UPLOAD: {name: 'icon_car_upload', value: 'icon-car-upload'},
    ICON_CHANGE: {name: 'icon_change', value: 'icon-change'},
    ICON_CAR: {name: 'icon_car', value: 'icon-car'},
    ICON_CALENDAR: {name: 'icon_calendar', value: 'icon-calendar'},
    ICON_ARCHIVE_ADD: {name: 'icon_archive_add', value: 'icon-archive-add'},
    ICON_ARCHIVE_DELETE: {name: 'icon_archive_delete', value: 'icon-archive-delete'},
    ICON_ARCHIVE_DONE: {name: 'icon_archive_done', value: 'icon-archive-done'},
    ICON_CAR_ADD: {name: 'icon_car_add', value: 'icon-car-add'},
    ICON_DELETE: {name: 'icon_delete', value: 'icon-delete'},
    ICON_DOCUMENT_UPLOAD: {name: 'icon_document_upload', value: 'icon-document-upload'},
    ICON_FOLDER_DELETE: {name: 'icon_folder_delete', value: 'icon-folder-delete'},
    ICON_INFO: {name: 'icon_info', value: 'icon-info'},
    ICON_FOLDER_DOWNLOAD: {name: 'icon_folder_download', value: 'icon-folder-download'},
    ICON_DOCUMET_SAVE: {name: 'icon_documet_save', value: 'icon-documet-save'},
    ICON_DOCUMENT_ADD: {name: 'icon_document_add', value: 'icon-document-add'},
    ICON_CAR_DELETE: {name: 'icon_car_delete', value: 'icon-car-delete'},
    ICON_ARCHIVE_DOWNLOAD: {name: 'icon_archive_download', value: 'icon-archive-download'},
    ICON_ARCHIVE_ERROR: {name: 'icon_archive_error', value: 'icon-archive-error'},
    ICON_CAR_DONE: {name: 'icon_car_done', value: 'icon-car-done'},
    ICON_DOCUMENT_ALERT: {name: 'icon_document_alert', value: 'icon-document-alert'},
    ICON_DOWNLOAD_CLOUD: {name: 'icon_download_cloud', value: 'icon-download-cloud'},
    ICON_FOLDER_HELP: {name: 'icon_folder_help', value: 'icon-folder-help'},
    ICON_LEFT_ARROW: {name: 'icon_left_arrow', value: 'icon-left-arrow'},
    ICON_MAILBOX_INCOMING: {name: 'icon_mailbox_incoming', value: 'icon-mailbox-incoming'},
    ICON_LINK: {name: 'icon_link', value: 'icon-link'},
    ICON_FOLDER_INFORMATION: {name: 'icon_folder_information', value: 'icon-folder-information'},
    ICON_DROPDOWN_ICON: {name: 'icon_dropdown_icon', value: 'icon-dropdown-icon'},
    ICON_DOCUMENT_DELETE: {name: 'icon_document_delete', value: 'icon-document-delete'},
    ICON_CAR_DOWNLOAD: {name: 'icon_car_download', value: 'icon-car-download'},
    ICON_ARCHIVE_EXTRA: {name: 'icon_archive_extra', value: 'icon-archive-extra'},
    ICON_ARCHIVE_HELP: {name: 'icon_archive_help', value: 'icon-archive-help'},
    ICON_CAR_ERROR: {name: 'icon_car_error', value: 'icon-car-error'},
    ICON_DOCUMENT_DOWNLOAD: {name: 'icon_document_download', value: 'icon-document-download'},
    ICON_DROPDOWN_UP: {name: 'icon_dropdown_up', value: 'icon-dropdown-up'},
    ICON_FOLDER_MODIFY: {name: 'icon_folder_modify', value: 'icon-folder-modify'},
    ICON_LOCKED: {name: 'icon_locked', value: 'icon-locked'},
    ICON_MAP_PIN: {name: 'icon_map_pin', value: 'icon-map-pin'},
    ICON_MENU_ICON: {name: 'icon_menu_icon', value: 'icon-menu-icon'},
    ICON_LOG_OUT: {name: 'icon_log_out', value: 'icon-log-out'},
    ICON_FOLDER_NEXT: {name: 'icon_folder_next', value: 'icon-folder-next'},
    ICON_EDIT: {name: 'icon_edit', value: 'icon-edit'},
    ICON_DOCUMENT_EXCEL: {name: 'icon_document_excel', value: 'icon-document-excel'},
    ICON_CAR_HELP: {name: 'icon_car_help', value: 'icon-car-help'},
    ICON_ARCHIVE_INFO: {name: 'icon_archive_info', value: 'icon-archive-info'},
    ICON_WOOD: {name: 'icon_wood', value: 'icon-wood'},
    ICON_VILICAR: {name: 'icon_vilicar', value: 'icon-vilicar'},
    ICON_UPLOAD: {name: 'icon_upload', value: 'icon-upload'},
    ICON_UNLOCKED: {name: 'icon_unlocked', value: 'icon-unlocked'},
    ICON_UNSELECTED: {name: 'icon_unselected', value: 'icon-unselected'},
    ICON_TWO: {name: 'icon_two', value: 'icon-two'},
    ICON_TRASH: {name: 'icon_trash', value: 'icon-trash'},
    ICON_TIME: {name: 'icon_time', value: 'icon-time'},
    ICON_THREE: {name: 'icon_three', value: 'icon-three'},
    ICON_TOOLS: {name: 'icon_tools', value: 'icon-tools'},
    ICON_TRANSPORT: {name: 'icon_transport', value: 'icon-transport'},
    ICON_SEARCH: {name: 'icon_search', value: 'icon-search'},
    ICON_RIGHT_ARROW: {name: 'icon_right_arrow', value: 'icon-right-arrow'},
    ICON_REMOVE: {name: 'icon_remove', value: 'icon-remove'},
    ICON_SAVE: {name: 'icon_save', value: 'icon-save'},
    ICON_PACKAGING_NEXT: {name: 'icon_packaging_next', value: 'icon-packaging-next'},
    ICON_PACKAGING_MODIFY: {name: 'icon_packaging_modify', value: 'icon-packaging-modify'},
    ICON_MESSAGES: {name: 'icon_messages', value: 'icon-messages'},
    ICON_PACKAGING_INFO: {name: 'icon_packaging_info', value: 'icon-packaging-info'},
    ICON_MODIFY: {name: 'icon_modify', value: 'icon-modify'},
    ICON_MORE: {name: 'icon_more', value: 'icon-more'},
    ICON_NEW_DOCUMENT: {name: 'icon_new_document', value: 'icon-new-document'},
    ICON_PACKAGING_OPTIONS: {name: 'icon_packaging_options', value: 'icon-packaging-options'},
    ICON_PACKAGING_PREVIOUS: {name: 'icon_packaging_previous', value: 'icon-packaging-previous'},
    ICON_SELECTED: {name: 'icon_selected', value: 'icon-selected'},
    ICON_NEXT: {name: 'icon_next', value: 'icon-next'},
    ICON_NINE: {name: 'icon_nine', value: 'icon-nine'},
    ICON_PACKAGING_REMOVE: {name: 'icon_packaging_remove', value: 'icon-packaging-remove'},
    ICON_SETTINGS: {name: 'icon_settings', value: 'icon-settings'},
    ICON_MAIL_INFO: {name: 'icon_mail_info', value: 'icon-mail-info'},
    ICON_MAIL_ERROR: {name: 'icon_mail_error', value: 'icon-mail-error'},
    ICON_MAIL_DOWNLOAD: {name: 'icon_mail_download', value: 'icon-mail-download'},
    ICON_MAIL_DELETE: {name: 'icon_mail_delete', value: 'icon-mail-delete'},
    ICON_MAIL_ADD: {name: 'icon_mail_add', value: 'icon-mail-add'},
    ICON_MAIL: {name: 'icon_mail', value: 'icon-mail'},
    ICON_MAIL_UPLOAD: {name: 'icon_mail_upload', value: 'icon-mail-upload'},
    ICON_MAILBOX: {name: 'icon_mailbox', value: 'icon-mailbox'},
    ICON_PACKAGING: {name: 'icon_packaging', value: 'icon-packaging'},
    ICON_PACKAGING_ALERT: {name: 'icon_packaging_alert', value: 'icon-packaging-alert'},
    ICON_MAILBOX_UPLOAD: {name: 'icon_mailbox_upload', value: 'icon-mailbox-upload'},
    ICON_PACKAGING_DELETE: {name: 'icon_packaging_delete', value: 'icon-packaging-delete'},
    ICON_PACKAGING_ADD: {name: 'icon_packaging_add', value: 'icon-packaging-add'},
    ICON_PACKAGING_UPLOAD: {name: 'icon_packaging_upload', value: 'icon-packaging-upload'},
    ICON_SIGNATURE: {name: 'icon_signature', value: 'icon-signature'},
    ICON_SIX: {name: 'icon_six', value: 'icon-six'},
    ICON_PAUSE_ICON: {name: 'icon_pause_icon', value: 'icon-pause-icon'},
    ICON_ONE: {name: 'icon_one', value: 'icon-one'},
    ICON_MAIL_REMOVE: {name: 'icon_mail_remove', value: 'icon-mail-remove'},
    ICON_SEVEN: {name: 'icon_seven', value: 'icon-seven'},
    ICON_PACKAGING_SAVE: {name: 'icon_packaging_save', value: 'icon-packaging-save'},
    ICON_START: {name: 'icon_start', value: 'icon-start'},
    ICON_PREVIOUS: {name: 'icon_previous', value: 'icon-previous'},
    ICON_STATS: {name: 'icon_stats', value: 'icon-stats'},
    ICON_PRINT: {name: 'icon_print', value: 'icon-print'},
    ICON_STATUS_OFF: {name: 'icon_status_off', value: 'icon-status-off'},
    ICON_PROFILE: {name: 'icon_profile', value: 'icon-profile'},
    ICON_PACKAGING_DOWNLOAD: {name: 'icon_packaging_download', value: 'icon-packaging-download'},
    ICON_STATUS_ON: {name: 'icon_status_on', value: 'icon-status-on'},
    ICON_REFRESH: {name: 'icon_refresh', value: 'icon-refresh'},
    ICON_PACKAGING_HELP: {name: 'icon_packaging_help', value: 'icon-packaging-help'}
  }


export const ICONS_LIST: IIcons[] = [
  {name: 'icon_accept', value: 'icon-accept'},
  {name: 'icon_archive_modify', value: 'icon-archive-modify'},
  {name: 'icon_car_modify', value: 'icon-car-modify'},
  {name: 'icon_archive_next', value: 'icon-archive-next'},
  {name: 'icon_add', value: 'icon-add'},
  {name: 'icon_car_info', value: 'icon-car-info'},
  {name: 'icon_document_modify', value: 'icon-document-modify'},
  {name: 'icon_document_info', value: 'icon-document-info'},
  {name: 'icon_document_help', value: 'icon-document-help'},
  {name: 'icon_car_next', value: 'icon-car-next'},
  {name: 'icon_car_previous', value: 'icon-car-previous'},
  {name: 'icon_archive_remove', value: 'icon-archive-remove'},
  {name: 'icon_archive_previous', value: 'icon-archive-previous'},
  {name: 'icon_alert', value: 'icon-alert'},
  {name: 'icon_adjust', value: 'icon-adjust'},
  {name: 'icon_archive', value: 'icon-archive'},
  {name: 'icon_archive_upload', value: 'icon-archive-upload'},
  {name: 'icon_car_remove', value: 'icon-car-remove'},
  {name: 'icon_document_options', value: 'icon-document-options'},
  {name: 'icon_document_next', value: 'icon-document-next'},
  {name: 'icon_folder', value: 'icon-folder'},
  {name: 'icon_five', value: 'icon-five'},
  {name: 'icon_factory', value: 'icon-factory'},
  {name: 'icon_euro', value: 'icon-euro'},
  {name: 'icon_eight', value: 'icon-eight'},
  {name: 'icon_folder_options', value: 'icon-folder-options'},
  {name: 'icon_folder_previous', value: 'icon-folder-previous'},
  {name: 'icon_folder_save', value: 'icon-folder-save'},
  {name: 'icon_folder_upload', value: 'icon-folder-upload'},
  {name: 'icon_four', value: 'icon-four'},
  {name: 'icon_home', value: 'icon-home'},
  {name: 'icon_help_icon', value: 'icon-help-icon'},
  {name: 'icon_folder_add', value: 'icon-folder-add'},
  {name: 'icon_folder_alert', value: 'icon-folder-alert'},
  {name: 'icon_document_remove', value: 'icon-document-remove'},
  {name: 'icon_document_previous', value: 'icon-document-previous'},
  {name: 'icon_car_upload', value: 'icon-car-upload'},
  {name: 'icon_change', value: 'icon-change'},
  {name: 'icon_car', value: 'icon-car'},
  {name: 'icon_calendar', value: 'icon-calendar'},
  {name: 'icon_archive_add', value: 'icon-archive-add'},
  {name: 'icon_archive_delete', value: 'icon-archive-delete'},
  {name: 'icon_archive_done', value: 'icon-archive-done'},
  {name: 'icon_car_add', value: 'icon-car-add'},
  {name: 'icon_delete', value: 'icon-delete'},
  {name: 'icon_document_upload', value: 'icon-document-upload'},
  {name: 'icon_folder_delete', value: 'icon-folder-delete'},
  {name: 'icon_info', value: 'icon-info'},
  {name: 'icon_folder_download', value: 'icon-folder-download'},
  {name: 'icon_documet_save', value: 'icon-documet-save'},
  {name: 'icon_document_add', value: 'icon-document-add'},
  {name: 'icon_car_delete', value: 'icon-car-delete'},
  {name: 'icon_archive_download', value: 'icon-archive-download'},
  {name: 'icon_archive_error', value: 'icon-archive-error'},
  {name: 'icon_car_done', value: 'icon-car-done'},
  {name: 'icon_document_alert', value: 'icon-document-alert'},
  {name: 'icon_download_cloud', value: 'icon-download-cloud'},
  {name: 'icon_folder_help', value: 'icon-folder-help'},
  {name: 'icon_left_arrow', value: 'icon-left-arrow'},
  {name: 'icon_mailbox_incoming', value: 'icon-mailbox-incoming'},
  {name: 'icon_link', value: 'icon-link'},
  {name: 'icon_folder_information', value: 'icon-folder-information'},
  {name: 'icon_dropdown_icon', value: 'icon-dropdown-icon'},
  {name: 'icon_document_delete', value: 'icon-document-delete'},
  {name: 'icon_car_download', value: 'icon-car-download'},
  {name: 'icon_archive_extra', value: 'icon-archive-extra'},
  {name: 'icon_archive_help', value: 'icon-archive-help'},
  {name: 'icon_car_error', value: 'icon-car-error'},
  {name: 'icon_document_download', value: 'icon-document-download'},
  {name: 'icon_dropdown_up', value: 'icon-dropdown-up'},
  {name: 'icon_folder_modify', value: 'icon-folder-modify'},
  {name: 'icon_locked', value: 'icon-locked'},
  {name: 'icon_map_pin', value: 'icon-map-pin'},
  {name: 'icon_menu_icon', value: 'icon-menu-icon'},
  {name: 'icon_log_out', value: 'icon-log-out'},
  {name: 'icon_folder_next', value: 'icon-folder-next'},
  {name: 'icon_edit', value: 'icon-edit'},
  {name: 'icon_document_excel', value: 'icon-document-excel'},
  {name: 'icon_car_help', value: 'icon-car-help'},
  {name: 'icon_archive_info', value: 'icon-archive-info'},
  {name: 'icon_wood', value: 'icon-wood'},
  {name: 'icon_vilicar', value: 'icon-vilicar'},
  {name: 'icon_upload', value: 'icon-upload'},
  {name: 'icon_unlocked', value: 'icon-unlocked'},
  {name: 'icon_unselected', value: 'icon-unselected'},
  {name: 'icon_two', value: 'icon-two'},
  {name: 'icon_trash', value: 'icon-trash'},
  {name: 'icon_time', value: 'icon-time'},
  {name: 'icon_three', value: 'icon-three'},
  {name: 'icon_tools', value: 'icon-tools'},
  {name: 'icon_transport', value: 'icon-transport'},
  {name: 'icon_search', value: 'icon-search'},
  {name: 'icon_right_arrow', value: 'icon-right-arrow'},
  {name: 'icon_remove', value: 'icon-remove'},
  {name: 'icon_save', value: 'icon-save'},
  {name: 'icon_packaging_next', value: 'icon-packaging-next'},
  {name: 'icon_packaging_modify', value: 'icon-packaging-modify'},
  {name: 'icon_messages', value: 'icon-messages'},
  {name: 'icon_packaging_info', value: 'icon-packaging-info'},
  {name: 'icon_modify', value: 'icon-modify'},
  {name: 'icon_more', value: 'icon-more'},
  {name: 'icon_new_document', value: 'icon-new-document'},
  {name: 'icon_packaging_options', value: 'icon-packaging-options'},
  {name: 'icon_packaging_previous', value: 'icon-packaging-previous'},
  {name: 'icon_selected', value: 'icon-selected'},
  {name: 'icon_next', value: 'icon-next'},
  {name: 'icon_nine', value: 'icon-nine'},
  {name: 'icon_packaging_remove', value: 'icon-packaging-remove'},
  {name: 'icon_settings', value: 'icon-settings'},
  {name: 'icon_mail_info', value: 'icon-mail-info'},
  {name: 'icon_mail_error', value: 'icon-mail-error'},
  {name: 'icon_mail_download', value: 'icon-mail-download'},
  {name: 'icon_mail_delete', value: 'icon-mail-delete'},
  {name: 'icon_mail_add', value: 'icon-mail-add'},
  {name: 'icon_mail', value: 'icon-mail'},
  {name: 'icon_mail_upload', value: 'icon-mail-upload'},
  {name: 'icon_mailbox', value: 'icon-mailbox'},
  {name: 'icon_packaging', value: 'icon-packaging'},
  {name: 'icon_packaging_alert', value: 'icon-packaging-alert'},
  {name: 'icon_mailbox_upload', value: 'icon-mailbox-upload'},
  {name: 'icon_packaging_delete', value: 'icon-packaging-delete'},
  {name: 'icon_packaging_add', value: 'icon-packaging-add'},
  {name: 'icon_packaging_upload', value: 'icon-packaging-upload'},
  {name: 'icon_signature', value: 'icon-signature'},
  {name: 'icon_six', value: 'icon-six'},
  {name: 'icon_pause_icon', value: 'icon-pause-icon'},
  {name: 'icon_one', value: 'icon-one'},
  {name: 'icon_mail_remove', value: 'icon-mail-remove'},
  {name: 'icon_seven', value: 'icon-seven'},
  {name: 'icon_packaging_save', value: 'icon-packaging-save'},
  {name: 'icon_start', value: 'icon-start'},
  {name: 'icon_previous', value: 'icon-previous'},
  {name: 'icon_stats', value: 'icon-stats'},
  {name: 'icon_print', value: 'icon-print'},
  {name: 'icon_status_off', value: 'icon-status-off'},
  {name: 'icon_profile', value: 'icon-profile'},
  {name: 'icon_packaging_download', value: 'icon-packaging-download'},
  {name: 'icon_status_on', value: 'icon-status-on'},
  {name: 'icon_refresh', value: 'icon-refresh'},
  {name: 'icon_packaging_help', value: 'icon-packaging-help'}
];
