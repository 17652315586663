import {getUrlParameter} from "../url/url.utils";

export interface IPaginationBaseState {
  itemsPerPage: number;
  sort: string;
  order: string;
  activePage: number;
  first: number;
}

export const getSortState = (location, itemsPerPage): IPaginationBaseState => {
  const pageParam = getUrlParameter('page', location.search);
  const sortParam = getUrlParameter('sort', location.search);
  let sort = 'id';
  let order = 'asc';
  let activePage = 1;
  let first = 0;
  if (pageParam !== '' && !isNaN(parseInt(pageParam, 10))) {
    activePage = parseInt(pageParam, 10);
  }
  if (sortParam !== '') {
    sort = sortParam.split(',')[0];
    order = sortParam.split(',')[1];
  }
  first = (activePage-1)*itemsPerPage;
  return { itemsPerPage, sort, order, activePage, first };
};

export const getPageBeginIndex = (page: number, pageSize:number) => {
  return (page-1)*pageSize + 1;
}

export const getPageEndIndex = (page: number, pageSize:number, totalItems:number) => {
  const curr = (page-1)*pageSize + pageSize;
  return curr > totalItems?totalItems : curr;
}
