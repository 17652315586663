import React from "react";
import classNames from 'classnames/bind';

export interface IPaginationProps{
  className?:string;
}

class Pagination extends React.Component<IPaginationProps>{

  render() {
    return (
      <ul className={classNames('pagination',this.props.className)}>
        {this.props.children}
      </ul>
    );
  }
}

export default Pagination;
