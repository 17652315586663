import React from 'react';
import classNames from 'classnames/bind';

export interface IGridProps{
  classname?: string;
  visible:boolean;
}

export class Grid extends React.Component<IGridProps> {
  static defaultProps = {
    visible: true
  }
  render() {
    const gridClass = classNames(
      'grid-ui',
      {'d-none': !this.props.visible},
      this.props.classname
    );
    return (
      this.props.visible?<div className={gridClass}>
        {this.props.children}
      </div>:<></>
    );
  }
}

export interface IRowProps {
  classname?: string;
  visible:boolean;
}

export class Row extends React.Component<IRowProps> {

  static defaultProps = {
    visible: true
  }

  render() {
    const rowClass = classNames(
      'row',
      {'d-none': !this.props.visible},
      this.props.classname
    );
    return (
      this.props.visible?<div className={rowClass}>
        {this.props.children}
      </div>:<></>
    );
  }
}

export interface IColProps{
  span?:number;
  classname?: string;
  visible:boolean;
}

export class Col extends React.Component<IColProps>{
  static defaultProps = {
    visible: true
  }
  render(){
    const cc = this.props.span?`col-${this.props.span}`:'col';
    const colClass = classNames(
      cc,
      {'d-none': !this.props.visible},
      this.props.classname
    );
    return (
      this.props.visible?<div className={colClass}>
        {this.props.children}
      </div>:<></>
    );
  }
}
