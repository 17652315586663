import React from "react";
import {DataTableColumn} from "./data-table-column";
import DataTableRow from "./data-table-row";
import classNames from 'classnames/bind';

export interface IDataTableBodyProps {
  columns: DataTableColumn[];
  data: any[];
}

const DataTableBody = (props:IDataTableBodyProps) => {
  return (
    <tbody className={classNames('data-table-body','tablica_regular')}>
      {props.data&&props.data.map((row, index)=><DataTableRow key={`dr-${index}`} columns={props.columns} row={row} rowIndex={index}/>)}
    </tbody>
  );
}

export default DataTableBody;
