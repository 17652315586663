import React from "react";

/**
 *
 */
export class FlatTable extends React.Component<any, any> {

  render() {
    return (
      <div {...this.props}>
        {this.props.children}
      </div>
    );
  }
};
