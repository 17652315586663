import React from "react";
import {DataTableColumn} from "./data-table-column";
import classNames from 'classnames/bind';

export interface IDataTableRowCellProps {
  column: DataTableColumn;
  row: any;
  rowIndex: number;
  colIndex: number;
  columnCount: number;
}

const DataTableRowCell = (props: IDataTableRowCellProps) => {
  return props.column.cellTemplate ?
    props.column.cellTemplate(props.column, props.row, props.rowIndex, props.colIndex, props.columnCount) :
    <td className={classNames(
      'cell',
      {'first':props.colIndex===0},
      {'middle':props.columnCount-1!==props.colIndex&&props.colIndex!=0},
      {'last':props.columnCount-1===props.colIndex},
      props.column.cellClassName
    )}>
      {props.column.itemTemplate?
        props.column.itemTemplate(props.column, props.row, props.rowIndex, props.colIndex, props.columnCount):
        props.row[props.column.field]}
    </td>;
}

export default DataTableRowCell;
