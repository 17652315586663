import React from "react";


export class NavigationWrapper extends React.Component{

  render() {
    return (
      <div className={"navigation-modules w-100 landing container-flow"}>
        <div className={"navigation-wrapper d-flex flex-column justify-content-center align-items-center m-3"}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
