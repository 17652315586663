import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classNames/bind";

interface IFlatTableDataRowActionsProps {
  className?: string;
  appendClassName?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  showBorder?:boolean;
  gridLayout?: boolean;
}

/**
 *
 */
export class FlatTableDataRowActions extends React.Component<IFlatTableDataRowActionsProps> {

  static defaultProps = {
    showEdit: true,
    showDelete: true,
    onEdit: () => {
    },
    onDelete: () => {
    },
    appendClassName: true,
    showBorder: true,
    gridLayout: true
  };

  render() {
    const {showEdit, showDelete, onDelete, onEdit, appendClassName, className} = this.props;
    const cn = className && !appendClassName ? className :
      classNames({'col':this.props.gridLayout}, "d-flex flex-row", 'align-items-stretch align-self-center justify-content-center px-2 text-center',{"border-left-color-silver border-left-1":this.props.showBorder}, this.props.className);
    return (
      <div className={cn}>
        {showEdit ?
          <div className={"mx-1"} onClick={() => {
            if (onEdit) {
              onEdit()
            }
          }}>
            <FontAwesomeIcon icon={["far", "pencil-alt"]}/>
          </div> : <></>}
        {showDelete ?
          <div className={"mx-1"} onClick={() => {
            if (onDelete) {
              onDelete()
            }
          }}>
            <FontAwesomeIcon className={"color-red"} icon={["far", "times"]}/>
          </div> : <></>}
        {this.props.children}
      </div>
    );
  }

};

