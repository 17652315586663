import React from "react";
import {Link} from "react-router-dom";
import {ProfileLink} from "../../utils/profile.link";
/**
 *  ProfileLink
 *
 */
export interface ILayoutStandardProfileLinkProps{
  link: ProfileLink;
}

export const LayoutStandardProfileLink = (props:ILayoutStandardProfileLinkProps) => {
  return (
    <div className={"d-flex no-gutters"}>
      <div className={"profile ps-3 pt-2 pe-3"}>
        {props.link.link?<Link to={props.link.link}>{props.link.label}</Link>:<div onClick={()=>props.link.command}>{props.link.label}</div>}
        </div>
    </div>
  );
};

