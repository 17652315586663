import React from "react";
import classNames from 'classnames/bind';
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavigationRow} from "./navigation.row";
export interface INavigationBoxProps{
  icon?:string|string[];
  faIcon:boolean;
  label:string;
  target:string;
  visible?: boolean;
}

export const NavigationBox = (props:INavigationBoxProps) => {
  const linkClass = classNames('module-link d-flex flex-column justify-content-between align-items-center m-3');

  const iconClass = props.faIcon?
    classNames('icon'):
    classNames('icon', props.icon);

  const iconEl = props.faIcon?
    // @ts-ignore
    <FontAwesomeIcon className={iconClass} icon={[].concat(props.icon)}/>:
    <div className={iconClass}></div>;

  const labelClass = classNames('label');
  return (
    props.visible?
    <Link to={props.target}>
      <div className={linkClass}>
        {iconEl}
        <div className={labelClass}>{props.label}</div>
      </div>
    </Link>:<></>
  );
};

NavigationBox.defaultProps = {
  visible: true
}

