import React, {useEffect, useState} from 'react';
import {INPUT_ELEMENT_TYPE, InputElementProps} from "./input_element_contstants";
import NumberFormat from 'react-number-format';
import classNames from 'classnames/bind';
import {SingleDatePicker} from 'react-dates';
import {IconChooser} from "../../components";
import ReactLoading from "react-loading";
import * as momentImported from 'moment';
import 'react-dates/initialize';
import {useField} from "formik";
import {AutoComplete} from "primereact/autocomplete";
import {
  createBooleanLabelWrapperClass,
  createElementWrapperClass,
  createIconWrapperClass,
  createInputElementClass,
  createInputElementWrapperClass, createInputTextClass,
  createInputWrapperClass,
  createLabelWrapperClass, createErrorWrapperClass
} from "./utils/create_styles";


const moment = momentImported;

export const createInputElement = (props: InputElementProps) => {
  if (!props.visible) return <></>;
  switch (props.type) {
    case INPUT_ELEMENT_TYPE.STRING_NORMAL:
    case INPUT_ELEMENT_TYPE.STRING_NORMAL:
    case INPUT_ELEMENT_TYPE.STRING_LARGE:
    case INPUT_ELEMENT_TYPE.PASSWORD:
    case INPUT_ELEMENT_TYPE.MAIL:
      return createStringElement(props);
    //
    case INPUT_ELEMENT_TYPE.NUMBER:
      return createNumberElement(props);
    //
    case INPUT_ELEMENT_TYPE.DATE:
    case INPUT_ELEMENT_TYPE.DATETIME:
    case INPUT_ELEMENT_TYPE.TIME:
      return createDateElement(props);
    //
    case INPUT_ELEMENT_TYPE.DATE_RANGE:
      return <></>
    //createRangeDateElement(props);
    case INPUT_ELEMENT_TYPE.BOOLEAN:
      return createBooleanElement(props);
    //
    case INPUT_ELEMENT_TYPE.LIST_ONE_LARGE:
    case INPUT_ELEMENT_TYPE.LIST_ONE_SMALL:
    case INPUT_ELEMENT_TYPE.LIST_MANY_LARGE:
    case INPUT_ELEMENT_TYPE.LIST_MANY_SMALL:
      return createListOneElement(props);
    //
    case INPUT_ELEMENT_TYPE.LABELS:
      return createPropElement(props);
    case INPUT_ELEMENT_TYPE.ICONS:
      return createIconChooseElement(props);
    case INPUT_ELEMENT_TYPE.DUMMY:
      return createDummyElement(props);
    default:
      return createDefaultElement(props);
  }
  return createDefaultElement(props);
};



const createDefaultElement = (props: InputElementProps) => {
};

const createDummyElement = (props: InputElementProps) => {
  const [field, meta, helpers] = useField(props.name);
  const inError = meta.error !== undefined && meta.touched;
  return (
    <div className={createInputElementWrapperClass(props.showBackground)}>
      <div className={createInputElementClass()}>
        {props.label ? <div className={createLabelWrapperClass(props.required, props.label?true:false)} >{props.label}</div> : <></>}
        <div className={createInputWrapperClass(props.focused === undefined ? false : props.focused, inError)}>
          // @ts-ignore
          <div className={createIconWrapperClass(props.icon)}></div>
          <div className={createElementWrapperClass()}>
            <div
              className={createInputTextClass(props.required, inError)}
              style={{minHeight: '1rem'}}
              onClick={props.onClick}
            >
              {props.showValue ? field.value : ''}
            </div>
            {inError ? <div className={createErrorWrapperClass()}>{meta.error}</div> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
}

const createStringElement = (props: InputElementProps) => {
  const [inFocus, setInFocus] = useState(false);
  const [field, meta, helpers] = useField(props.name);
  const inError = meta.error !== undefined && meta.touched;
  return (
    <div className={createInputElementWrapperClass(props.showBackground)}>
      <div className={createInputElementClass()}>
        {props.label ? <div className={createLabelWrapperClass(props.required, props.label?true:false)} >{props.label}</div> : <></>}
        <div className={createInputWrapperClass(inFocus, inError)}>
          <div className={createIconWrapperClass(props.icon)}></div>
          <div className={createElementWrapperClass()}>
            <input
              id={props.id}
              name={field.name}
              value={field.value}
              type={props.type === INPUT_ELEMENT_TYPE.PASSWORD ? 'password' : props.type === INPUT_ELEMENT_TYPE.MAIL ? 'email' : 'text'}

              className={createInputTextClass(props.required, inError)}

              onChange={(e) => {
                if (props.onChange) props.onChange(e);
                field.onChange(e);
              }}
              onFocus={(e) => setInFocus(true)}
              onBlur={(e) => {
                setInFocus(false);
                if (props.onBlur) props.onBlur(e)
                field.onBlur(e);
              }}

            />
            {inError ? <div className={createErrorWrapperClass()}>{meta.error}</div> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};
const createNumberElement = (props: InputElementProps) => {
  const [inFocus, setInFocus] = useState(false);
  const [field, meta, helpers] = useField(props.name);
  const inError = meta.error !== undefined && meta.touched;
  return (
    <div className={createInputElementWrapperClass(props.showBackground)}>
      <div className={createInputElementClass()}>
        {props.label ? <div className={createLabelWrapperClass(props.required, props.label?true:false)} >{props.label}</div> : <></>}
        <div className={createInputWrapperClass(inFocus, inError)}>

          <div className={createIconWrapperClass(props.icon)}></div>
          <div className={createElementWrapperClass()}>
            <NumberFormat
              id={props.id}
              name={field.name}
              thousandSeparator={props.thousandSeparator}
              decimalSeparator={props.decimalSeparator}
              decimalScale={props.decimalScale}
              fixedDecimalScale={props.fixedDecimalScale}
              allowNegative={props.allowNegative}
              allowLeadingZeros={props.allowLeadingZeros}
              allowEmptyFormatting={props.allowEmptyFormating}
              prefix={props.prefix}
              suffix={props.suffix}
              value={field.value}
              className={createInputTextClass(props.required, inError)}
              onChange={(e) => {
                if (props.onChange) props.onChange(e);
                field.onChange(e);
              }}
              onFocus={(e) => setInFocus(true)}
              onBlur={(e) => {
                setInFocus(false);
                if (props.onBlur) props.onBlur(e)
                field.onBlur(e);
              }}
            />
            {inError ?
              <div className={createErrorWrapperClass()}>{meta.error}</div> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};
const createDateElement = (props: InputElementProps) => {
  const [inFocus, setInFocus] = useState(false);

  const [field, meta, helpers] = useField(props.name);
  const inError = meta.error !== undefined && meta.touched;

  const value = typeof field.value === 'string' ? moment(field.value) : field.value instanceof String ? moment(field.value.toString()) : field.value;
  return (
    <div className={createInputElementWrapperClass(props.showBackground)}>
      <div className={createInputElementClass()}>
        {props.label ? <div className={createLabelWrapperClass(props.required, props.label?true:false)} >{props.label}</div> : <></>}
        <div className={createInputWrapperClass(inFocus, inError)}>

          <div className={createIconWrapperClass(props.icon)}></div>
          <div className={createElementWrapperClass()}>
            <SingleDatePicker
              id={props.id}
              date={value}
              placeholder={props.placeholder}
              disabled={props.disabled}
              required={props.required}

              showClearDate={false}
              noBorder={true}
              block={true}
              enableOutsideDays={true}
              displayFormat={props.dateDisplayFormat}
              isDayBlocked={props.isDayBlocked}
              isOutsideRange={props.isOutsideRange}
              numberOfMonths={props.numberOfMonthsToDisplay}
              hideKeyboardShortcutsPanel={true}
              onDateChange={
                (date) => {
                  if (props.onChange) props.onChange(date);
                  helpers.setValue(date);
                }
              }
              focused={inFocus}
              onFocusChange={({focused}) => setInFocus(focused)}
            />
            {inError ? <div className={createErrorWrapperClass()}>{meta.error}</div> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};

const createBooleanElement = (props: InputElementProps) => {

  const [selected, setSelected] = useState(false);
  const [field, meta, helpers] = useField(props.name);
  const inError = meta.error !== undefined && meta.touched;

  useEffect(() => setSelected(field.value), [field.value]);
  const elementClass = classNames(
    'rounded-circle',
    'square-4',
    'border-top-1',
    'border-right-1',
    'border-bottom-1',
    'border-left-1',
    'me-2',
    {'background-dark-lime-green': selected},
    'border-left-color-dark-lime-green',
    'border-top-color-dark-lime-green',
    'border-right-color-dark-lime-green',
    'border-bottom-color-dark-lime-green',
  );

  return (
    <div className={createInputElementWrapperClass(false)}>
      <div className={createInputElementClass()}>
        <div className={elementClass} onClick={
          (e) => {
            setSelected(!selected);
            // @ts-ignore
            e.currentTarget.value = !selected;
            // @ts-ignore
            e.target.value = !selected;
            // if (field.onChange) field.onChange(e);
            helpers.setValue(!selected);
          }
        }></div>
        {props.label ? <div className={createBooleanLabelWrapperClass(props.required, props.label?true:false)}>{props.label}</div> : <></>}
      </div>
    </div>
  );
};
const createListOneElement = (props: InputElementProps) => {
  const [inFocus, setInFocus] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [field, meta, helpers] = useField(props.name);
  const inError = meta.error !== undefined && meta.touched;

  useEffect(() => {
      if (props.itemValue) {
        const val = props.itemValue(field.value);
        setInputValue(!val && val === undefined ? '' : val.toString());
      }
    },
    [field.value]);

  return (
    <div className={createInputElementWrapperClass(props.showBackground)}>
      <div className={createInputElementClass()}>
        {props.label ? <div className={createLabelWrapperClass(props.required, props.label?true:false)} >{props.label}</div> : <></>}
        <div className={createInputWrapperClass(inFocus, inError)}>

          <div className={createIconWrapperClass(props.icon)}></div>
          <div className={createElementWrapperClass()}>
            <AutoComplete
              id={props.id}
              dropdown={true}
              value={inputValue}

              onChange={
                (e) => {
                  if (typeof e.value === "string") {
                    setInputValue(e.value);
                  }
                }
              }
              onSelect={(e) => {
                helpers.setValue(e.value);
              }}
              suggestions={props.listElements}
              completeMethod={(e) => {
                if (props.onListSearch) props.onListSearch(e.originalEvent, e.query)
              }}
              itemTemplate={props.itemTemplate}

            />
            {inError ?
              <div className={createErrorWrapperClass()}>{meta.error}</div> : <></>}
          </div>
        </div>
      </div>
    </div>
  )
};

// onChange={(e,value) => setValue(value)}
// suggestions={props.listElements}
// itemTemplate={props.itemValue}
// completeMethod={(e,search)=> {if(props.onListSearch)props.onListSearch(e, search)}}

const createIconChooseElement = (props: InputElementProps) => {
  const [inFocus, setInFocus] = useState(false);
  const [field, meta, helpers] = useField(props.name);
  const inError = meta.error !== undefined && meta.touched;
  const menuNoSearch = () => <div className="item enter-search">{props.enterSearchLabel}</div>;

  const menuLoading = () => {
    return (
      <div className={"item d-flex h-100 w-100 justify-content-center align-items-center"}>
        <ReactLoading type={'bubbles'} color={'#000000'} height={'20%'} width={'20%'}/>
      </div>
    )
  };

  const menuNoItems = () => <div className="item no-results">{props.noResultsLabel}</div>;

  const itemsEl = (items) => <>{items}
    <div className={"item number_of_results d-flex justify-content-end px-1"}>{props.numberOfResultsLabel} </div>
  </>


  return (
    <div className={createInputElementWrapperClass(props.showBackground)}>
      <div className={createInputElementClass()}>
        {props.label ? <div className={createLabelWrapperClass(props.required, props.label?true:false)} >{props.label}</div> : <></>}
        <div className={createInputWrapperClass(inFocus, inError)}>

          <div className={createIconWrapperClass(props.icon)}></div>
          <div className={createElementWrapperClass()}>
            <IconChooser
              value={field.value}
              onFocus={(e) => setInFocus(true)}
              onItemSelect={props.onItemSelect}
            />
            {inError ?
              <div className={createErrorWrapperClass()}>{meta.error}</div> : <></>}
          </div>
        </div>
      </div>

    </div>
  )
};

const createPropElement = (props: InputElementProps) => {
};


