import React, {MutableRefObject, useEffect, useState} from "react";
import {useField, useFormikContext} from "formik";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import {
  createInputTextClass
} from "../utils/create_styles";
import {InputComponent} from "./input-component";
import {Storage} from "../../../utils";
import {Empty} from "./input-component-empty";
import {getObjectValueFromPath} from "../../../utils/common/object_path";

export enum INPUT_COMPONENT_TEXT_INPUT_TYPE {
  STRING = 0,
  MAIL = 1,
  PASSWORD = 2
}

export interface IInputComponentTextProps {
  id: string;
  name: string;
  label?: string;
  type?: INPUT_COMPONENT_TEXT_INPUT_TYPE;
  icon?: DEFAULT_INPUT_ICONS;

  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?:boolean;
  /** Used as value if wrapInForm === false*/
  value?:any;
  /**If saveFormState.*/
  formId?:string;
  /**Does the state of this field is saved*/
  saveFormState?:boolean;

  editable?:boolean;

  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  info?:any;

  onChange?: (e) => void;
  onBlur?: (e) => void;
  onFocus?: (e) => void;

  styleClassWrapper?:string;
  styleClassRoot?:string;
  styleClassLabel?:string;
  styleClassInput?:string;
  styleClassElement?:string;
  styleClassError?:string;
  styleClassIcon?:string;
  styleClassInfo?:string;

}

export const InputComponentText = React.forwardRef((props: IInputComponentTextProps, ref:MutableRefObject<any>) => {
  if(!props.visible)return null;
  const [field, meta, helpers] = props.wrapInForm?useField(props.name):[null,null,null];
  const [inFocus, setInFocus] = useState(false);
  const formik = useFormikContext();
  const [inError, setInError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.wrapInForm && meta?.error && meta?.touched) {
      setInError(true);
      // @ts-ignore
      setError(meta.error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm, meta, meta?.error, meta?.touched]);

  useEffect(() => {
    if(!formik){return;}
    const error = getObjectValueFromPath(props.name, formik.errors);
    //const touched = getObjectValueFromPath(props.name, formik.touched);
    if (props.wrapInForm && formik.errors && error){
      setInError(true);
      // @ts-ignore
      setError(error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm,formik, formik?.errors]);

  return (
    // @ts-ignore
    <InputComponent {...props} error={error} focused={inFocus}>
      {props.editable?
      <input
              ref={ref}
              id={props.id}
              name={props.name}
              value={props.wrapInForm?field?.value:props.value}
              type={props.type === INPUT_COMPONENT_TEXT_INPUT_TYPE.PASSWORD ? 'password' : props.type === INPUT_COMPONENT_TEXT_INPUT_TYPE.MAIL ? 'email' : 'text'}
              className={createInputTextClass(props.required, inError)}
              onChange={(e) => {
                if (props.onChange) props.onChange(e);
                field?.onChange(e);
                if(props.saveFormState&&props.formId){
                  const entity = {
                    ...Storage.local.get(props.formId),
                  };
                  entity[props.name] = e.target.value;
                  Storage.local.set(props.formId, entity);
                }
              }}
              onFocus={(e) => {
                setInFocus(true);
                if (props.onFocus) {
                  props.onFocus(e);
                }
              }}
              onBlur={(e) => {
                setInFocus(false);
                if (props.onBlur) props.onBlur(e)
                field?.onBlur(e);
              }}

            /> :
        <Empty required={props.required} inError={inError} value={props.wrapInForm?field?.value:props.value} ref={ref}/>}
    </InputComponent>
  );
});

InputComponentText.defaultProps = {
  showBackground: false,
  required: false,
  visible: true,
  wrapInForm: true,
  editable: true,
  type: INPUT_COMPONENT_TEXT_INPUT_TYPE.STRING
}
