import * as React from 'react';
//import styles from './styles.css';
import {InputTextarea} from "primereact/inputtextarea";


export interface IEchoComponent {
  text: string;
}

export class EchoComponent extends React.Component<IEchoComponent> {
  render() {
    const {text} = this.props

    return (
      <div className={'summit'}>
        <InputTextarea value={text}></InputTextarea>
        Echo: {text}
      </div>
    )
  }
};



