import React from "react";
import {createInputElementClass, createInputElementWrapperClass} from "../utils/create_styles";

export interface IInputComponentRootProps {
  styleClass?:string;
}

export class InputComponentRoot extends React.Component<IInputComponentRootProps>{
  render() {
    return <div className={createInputElementClass(this.props.styleClass)}>{this.props.children}</div>;
  }
}
