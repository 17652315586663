import React from "react";
import Avatar from "react-avatar";
import {UserContext} from "../../../../../utils/auth/User";
import {StandardProfileMenuContext} from "../layout.standard";

/**
 *  LayoutStandardHeaderProfile
 *
 */
const LayoutStandardHeaderProfile = (props) => {
  return (
    <StandardProfileMenuContext.Consumer>{ profile => (
      <UserContext.Consumer>
        {(user) => (
          <div className={"d-flex flex-row"}>
            <div>
              <div className={"d-flex justify-content-end  fw-bold"}>{user.name}</div>
              <div className={"d-flex justify-content-end"}>{user.orgUnit}</div>
            </div>
            <div className={"justify-content-end align-center mx-3"} onClick={(e) => profile.setShowProfile(!profile.showProfile)}>
              <Avatar className={"profile"} name={`${user.firstName} ${user.lastName}`} {...user.avatar} size={"30"} round="20px"/>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    )}
    </StandardProfileMenuContext.Consumer>
  );
};

export default LayoutStandardHeaderProfile;
