import React from "react";
import classNames from 'classnames/bind';
import {useHistory} from "react-router-dom";

export interface ITabRouterLinkProps {
  id:string;
  label: string|JSX.Element;
  target: string;
  showAlert: boolean;
  className?: string;
  selected:boolean;
  first:boolean;
  last:boolean;
  visible:boolean;
  onSelectTab:(selectedTab:string)=>void;
}

export const TabRouterLink = (props: ITabRouterLinkProps) => {

  const cn = classNames(
    'tab-router-link',
    {'selected-tab':props.selected},
    'd-flex',
    'flex-row',
    {'mx-2': !props.first},
    "justify-content-center",
    "align-items-center",
    props.className
  );

  const history = useHistory();
  const handleNavigation = () => {
    props.onSelectTab(props.id);
    history.push(props.target);
  }
  return (
    props.visible?<div className={cn} onClick={handleNavigation}>
      {props.showAlert?<div className={'alert rounded-circle background-primary me-2'} style={{width: "15px", height: "15px"}}></div>:null}
        <div className={"label"}>{props.label}</div>
      </div>:<></>
  );
}

TabRouterLink.defaultProps = {
  showAlert: false,
  visible: true
}
