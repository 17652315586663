import React, {useState} from "react";
import {DataTableColumn} from "./data-table-column";
import DataTableHeaderCell from "./data-table-header-cell";

export interface IDataTableHeaderProps {
  columns: DataTableColumn[];
  onSort: (column:DataTableColumn, order:string)=>void;
}

const DataTableHeader = (props: IDataTableHeaderProps) => {
  const [sortColumn, setSortColumn] = useState(-1);
  const handleSort = (col:DataTableColumn, index:number, order:string) => {
    setSortColumn(index);
    props.onSort(col,order);
  }
  return (
    <thead>
    <tr className={"data-table-header"}>
      {props.columns && props.columns.map((col, index) => {
        return <>
          <DataTableHeaderCell
            key={`ch-#{col.header}-${index}`}
            column={col}
            colIndex={index}
            columnCount={props.columns.length}
            onSort={handleSort}
            defaultSortOrder={sortColumn !== index}
          />

        </>
      })}
    </tr>
    </thead>
  );
}

export default DataTableHeader;

