import React, {useContext, useState} from "react";
import classNames from 'classnames/bind';
import {
  Link
} from "react-router-dom";
import {Module} from "./navigation-modules";
import {ActionButton, IButtonProps} from "../../components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {v4} from "uuid";

export interface ISideBarProps {
  modules?: Module[];
  actions?: IButtonProps[];
  visible?:boolean;
}

export const SideBar = (props: ISideBarProps) => {

  const acts = props.actions && props.actions.map((act, index) => {
    const cn = classNames('', act.styleRoot)
    return <ActionButton key={`btn-${act.label}-${index}`} {...act} styleRootAppend={true} styleRoot={cn}/>
  });

  const elems = props.modules && props.modules.map((module) => {
    const mod = classNames(
      'd-flex',
      'flex-column',
      'justify-content-center',
      'align-items-center',
      'square-4',
      'w-100',
      'py-4',
      'border-bottom-1',
      'module-element',
      module.classNameRoot
    );

    const inner = classNames('py-4', 'icon-wrapper', module.classNameIcon)
    const icon = module.faIcon?classNames('icon'):classNames('icon', module.icon);

    return (
      <Link key={v4()} to={module.target} className={""}>
        <div className={mod}>
          <div className={inner}>
            {
              // @ts-ignore
              module.faIcon?<FontAwesomeIcon className={icon} icon={[].concat(module.icon)}/>:<div className={icon}></div>
            }
          </div>
        </div>
      </Link>
    );
  });
  const cn = classNames(
    'd-flex',
    'w-100',
    'flex-column',
    'justify-content-center',
    'align-items-center',
    'my-2',
    'sidebar-column'
  );
  const actionHolder = classNames(
    'actions',
    'mb-2',
    'w-100'
  );
  const linksHolder = classNames(
    'links',
    'w-100'
    );
  return (
    props.visible?<div className={cn}>
      <div className={actionHolder}>{acts}</div>
      <div className={linksHolder}>{elems}</div>
    </div>:<></>
  );
}

SideBar.defaultProps = {
  visible: true
}


