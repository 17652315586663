import {DataTableColumn} from "../data-table/data-table-column";
import {FONT_WEIGHT, TemplateProps, TEXT_POSITION, TEXT_TRANSFORM} from "./template-props";
import React from "react";
import {calculateRootClassName} from "./template-styles";
import {calculateIcon, calculateLabel} from "./template-elements";


export const defaultTemplateString: TemplateProps = {
  position: TEXT_POSITION.LEFT,
  truncate: true,
  fontWeight: FONT_WEIGHT.NORMAL,
  textTransform: TEXT_TRANSFORM.NONE,
  iconOnHover: false
};

export const defaultTemplateStringCenter: TemplateProps = {
  position: TEXT_POSITION.CENTER,
  truncate: true,
  fontWeight: FONT_WEIGHT.NORMAL,
  textTransform: TEXT_TRANSFORM.NONE,
  iconOnHover: false
};

export const defaultTemplateStringRight: TemplateProps = {
  position: TEXT_POSITION.RIGHT,
  truncate: true,
  fontWeight: FONT_WEIGHT.NORMAL,
  textTransform: TEXT_TRANSFORM.NONE,
  iconOnHover: false
};
export const defaultTemplateStringLeft = defaultTemplateString;

export const dataTableItemTemplateString = (column:DataTableColumn, rowData:any, rowIndex:number, colIndex:number, columnCount:number, templateProps?: TemplateProps, fkValueExtractFunction?: (rowData: any, column:DataTableColumn) => any)=>{
  const pr = templateProps ? templateProps : defaultTemplateString;
  return (
    <div className={calculateRootClassName(pr)}>
      {calculateIcon(pr)}
      {calculateLabel(fkValueExtractFunction?fkValueExtractFunction(rowData, column):rowData[column.field], pr)}
    </div>
  );
}

export const dataTableItemTemplateStringPlain = (data:any, templateProps?: TemplateProps)=>{
  const pr = templateProps ? templateProps : defaultTemplateString;
  return (
    <div className={calculateRootClassName(pr)}>
      {calculateIcon(pr)}
      {calculateLabel(data, pr)}
    </div>
  );
}
