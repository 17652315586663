import * as React from 'react';

export interface IHeaderElementProps{
  title: string;
  subtitle: string;
}


export const HeaderElement = (props:IHeaderElementProps) => {
  return (
    <>
    <div className="row no-gutters align-items-center justify-content-center">
      <div className="col title">{props.title}</div>
    </div>
    <div className="row no-gutters align-items-center justify-content-center">
      <div className="col subtitle">{props.subtitle}</div>
    </div>
  </>)
}
