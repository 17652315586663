import React from "react";
import classNames from 'classnames/bind';

export interface IHeadlineH3Props{
  headline?:string;
  className?:string;
}

export class HeadlineH3 extends React.Component<IHeadlineH3Props>{
  constructor(props) {
    super(props);
  }

  render() {
    const headlineH3CN = classNames('headline-h3','d-inline-block',this.props.className);

    return (
        <div className={headlineH3CN}>{this.props.headline}</div>
    );
  }

}

