import React from "react";
import {InputComponentLabelWrapper} from "./input-component-label-wrapper";
import {InputComponentInputWrapper} from "./input-component-input-wrapper";
import {InputComponentWrapper} from "./input-component-wrapper";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import {InputComponentRoot} from "./input-component-root";

export interface IInputComponentProps {
  showBackground?: boolean;
  visible:boolean;
  label?: string;
  required: boolean;
  focused: boolean;
  icon?: string | string[];
  faIcon?: boolean;
  error?:string;
  info?:any;
  styleClassWrapper?:string;
  styleClassRoot?:string;
  styleClassLabel?:string;
  styleClassInput?:string;
  styleClassElement?:string;
  styleClassError?:string;
  styleClassIcon?:string;
  styleClassInfo?:string;
}

export class InputComponent extends React.Component<IInputComponentProps>{
  static defaultProps = {
    error: null,
    showBackground: true,
    required: false,
    visible: true
  }
  render() {
    if(!this.props.visible) return null;
    return (
      <InputComponentWrapper
        showBackground={this.props.showBackground}
        styleClass={this.props.styleClassWrapper}
      >
        <InputComponentRoot styleClass={this.props.styleClassRoot}>
          <InputComponentLabelWrapper
            required={this.props.required}
            label={this.props.label}
            styleClass={this.props.styleClassLabel}
          />
          <InputComponentInputWrapper
              focused={this.props.focused}
              icon={this.props.icon}
              error={this.props.error}
              info={this.props.info}
              inputStyleClass={this.props.styleClassInput}
              elementStyleClass={this.props.styleClassElement}
              errorStyleClass={this.props.styleClassError}
              iconStyleClass={this.props.styleClassIcon}
              infoStyleClass={this.props.styleClassInfo}
          >
            {this.props.children}
          </InputComponentInputWrapper>
        </InputComponentRoot>
      </InputComponentWrapper>
    );
  }
}
