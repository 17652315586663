import React, {useState} from "react";
import {DEFAULT_INPUT_ICONS, DEFAULT_LIST_ITEM_RENDER, INPUT_ELEMENT_TYPE} from "./input_element_contstants";
import {LIST_ITEMS_PER_PAGE} from "../../utils";
import {InputElement} from "./input_element";

export interface IAutocompleteProps {
  componentName:string;
  icon?:DEFAULT_INPUT_ICONS;
  showBackground?:boolean;
  required?:boolean;
  label?:string;
  itemValue: (item:any)=>{};
  lookupItems: (search?: string, page?: number, size?: number, sort?: string)=>Promise<any>;
  prepareQuery: (value:string)=>string;
}

export const Autocomplete = (props:IAutocompleteProps)=>{

  const [items, setItems] = useState([]);


  return (
    <InputElement
      id={props.componentName}
      name={props.componentName}
      type={INPUT_ELEMENT_TYPE.LIST_ONE_LARGE}
      icon={props.icon}
      required={props.required}
      showBackground={props.showBackground}
      itemValue={props.itemValue}

      label={props.label}
      listLoading={false}
      listElements={items}

      onListSearch={(event, value) => {
        props.lookupItems(props.prepareQuery(value), 0, LIST_ITEMS_PER_PAGE, 'asc').then(
          (response)=>{
            setItems(response&&response.data ? response.data:[]);
          }
        );
      }}
      itemTemplate={
        (item) => {
          return DEFAULT_LIST_ITEM_RENDER(item,false, it=>props.itemValue(it) )}
      }

      selectedItemTemplate={
        (item) => {
          return DEFAULT_LIST_ITEM_RENDER(item,true, it=>it)
        }
      }
    />
  );
}

Autocomplete.defaultProps={
  icon:DEFAULT_INPUT_ICONS.DOCUMENT,
  required:false,
  label: 'autocomplete.label',
  labelNoResults:'NoResults.label',
  labelEnterSearch:'Enter.Search.label',
  itemValue: (item) => item?.id.toString()
}
