import React from 'react';
import createWrapper from "../../utils/hooks/createWrapper";

// @ts-ignore
export const LoginPage = ({children})=>{
  return createWrapper('login-page-wrapper', children);
}



