import React, {MutableRefObject, useEffect, useState} from "react";
import {useField, useFormikContext} from "formik";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import * as momentImported from "moment";
import {InputComponent} from "./input-component";
import {getObjectValueFromPath} from "../../../utils/common/object_path";
import {IMaskInput} from 'react-imask';
import "react-datepicker/dist/react-datepicker.css";
import {format, isValid, parse, toDate} from 'date-fns'
import {Storage} from "../../../utils";

const moment = momentImported;

export interface IInputComponentDateProps {
  id: string;
  name: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;
  visible?: boolean;
  disabled: boolean;
  required: boolean;
  showBackground?: boolean;
  info?: any;

  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?: boolean;
  /** Used as value if wrapInForm === false*/
  value?: any;

  /**If saveFormState.*/
  formId?: string;
  /**Does the state of this field is saved*/
  saveFormState?: boolean;

  editable?: boolean;


  placeholder?: string;
  dateDisplayFormat?: string;

  onDateChange?: (date) => void;
  onFocus?: (e) => void;
  onBlur?: (e) => void;
}


export const InputComponentDate = React.forwardRef((props: IInputComponentDateProps, ref: MutableRefObject<any>) => {
    if (!props.visible) return null;
    const formik = useFormikContext();
    const [inFocus, setInFocus] = useState(false);
    const [field, meta, helpers] = props.wrapInForm ? useField(props.name) : [null, null, null];
    const [inError, setInError] = useState(false);
    const [error, setError] = useState(null);


    useEffect(() => {
      if (props.wrapInForm && meta?.error && meta?.touched) {
        setInError(true);
        // @ts-ignore
        setError(meta.error);
      } else {
        setInError(false);
      }
    }, [props.wrapInForm, meta, meta?.error, meta?.touched]);

    useEffect(() => {
      if (!formik) {
        return;
      }
      const error = getObjectValueFromPath(props.name, formik.errors);
      if (props.wrapInForm && formik.errors && error) {
        setInError(true);
        // @ts-ignore
        setError(error);
      } else {
        setInError(false);
      }
    }, [props.wrapInForm, formik, formik?.errors]);


    let value = null;
    if (props.wrapInForm) {
      value = typeof field?.value === 'string' ? moment(field?.value).toDate() :
        field?.value instanceof String ? new Date(field?.value.toString()) :
          moment.isMoment(field?.value) ? field?.value.toDate() :
            field?.value;
    } else {
      value = props.value;
    }


    const handleOnChange = (date) => {
      if (props.onDateChange) props.onDateChange(date);
      if (props.wrapInForm) helpers?.setValue(date);
      if (props.saveFormState && props.formId) {
        const entity = {
          ...Storage.local.get(props.formId),
        };
        entity[props.name] = date;
        Storage.local.set(props.formId, entity);
      }
    }

    const formatValue = () => {
      try {
        if (!value) {
          return '';
        }
        if (typeof value === "string") {
          // @ts-ignore
          return parse(value, props.dateDisplayFormat, new Date(2000, 1, 1));
        }
        // @ts-ignore
        return value ? format(value, `${props.dateDisplayFormat}`) : '';
      } catch (error) {
        return '';
      }
      return null;
    }

    return (
      // @ts-ignore
      <InputComponent {...props} error={error} focused={inFocus}>
        {props.editable ?
          <IMaskInput
            mask={Date}
            value={formatValue()}
            unmask={true}
            inputRef={el => {if(ref && ref.current)ref.current=el}}
            min={new Date(1000, 0, 1)}
            max={new Date(3000, 0, 1)}
            lazy={false}
            placeholder={props.placeholder}
            onAccept={(val: string, mask) => {
              try {
                const refDate = toDate(new Date(Date.UTC(1, 1, 1, 0, 0, 0, 0)));
                // @ts-ignore
                const date = parse(val, `${props.dateDisplayFormat}`, refDate);
                if (isValid(date) && props.dateDisplayFormat?.length === val?.length) {
                  handleOnChange(date);
                }
              } catch (error) {
              }
            }
            }
            onBlur={(e) => {
              setInFocus(false);
              if (props.onBlur) {
                props.onBlur(e);
              }
            }
            }

            onFocus={
              (focus) => {
                setInFocus(focus);
                if (ref && ref.current && ref.current.select) {
                  ref?.current?.select();
                }
                if (props.onFocus) {
                  props.onFocus(focus);
                }
              }
            }
          /> : formatValue()
        }
      </InputComponent>
    );
  })
;

InputComponentDate.defaultProps = {
  showBackground: false,
  disabled: false,
  required: false,
  visible: true,
  editable: true,
  wrapInForm: true,
}
