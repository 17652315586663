import React, {useEffect, useLayoutEffect, useState} from "react";
import {useHistory, useLocation} from 'react-router-dom';
import classNames from 'classnames/bind';
import {ITEMS_PER_PAGE} from '../../utils/pagination/pagination.constants';
import {ACTION_TYPE, ActionButton, BUTTON_SHAPE, IButtonProps} from "../../components";
import {getSortState} from "../../utils/pagination/pagination.utils";
import {useFormik} from "formik";
import {InputText} from "primereact/inputtext";
import ReactLoading from 'react-loading';
import _ from 'lodash';
import {DataTable, DataTableColumn} from "../../elements";

export interface IFormTable {
  loading: boolean;
  loadEntities: (activepage: number, itemsPerPage: number, sort: string) => void;
  filterEntities: (query: string, activepage: number, itemsPerPage: number, sort: string) => void;
  exportEntities: () => void;
  columns: DataTableColumn[];
  items: ReadonlyArray<any>;
  count: number;
  showExport?: boolean;
  showSearch: boolean;
  labelFilter?: string;
  actions?: IButtonProps[];
}

export const FormTable = (props: IFormTable) => {
  const history = useHistory();
  const location = useLocation();

  const [paginationState, setPaginationState] = useState(getSortState(location, ITEMS_PER_PAGE));

  const loadTable = () => props.loadEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  useEffect(() => {
    loadTable()
  }, []);

  const sortTable = () => {
    loadTable();
    history.push(
      `${location.pathname}?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`
    );
  };
  useEffect(() => {
    sortTable()
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const sort = (column: string, order: string) => {
    setSortField(column);
    setSortOrder(order);
    setPaginationState({
      ...paginationState,
      order: order,
      sort: column
    });
  };

  const handlePagination = (currentPage) => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  };


  /*
   *
   *  SEARCH
   *
   */
  const {filterEntities, labelFilter} = props;
  const [showFilter, setShowFilter] = useState(false);
  const searchFormik = useFormik({
    initialValues: {query: ''},
    onSubmit: (values) => filterEntities(values.query, paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`)
  });

  // FIXME
  // Ovdje je dosta toga čudno napravljeno - ako bi se trebala desiti tranzicija, onda stalno mora biti postojati underlying element...
  //
  const filterElementOpen = (
    <div className={"col-12"}>
      <form onSubmit={searchFormik.handleSubmit}>
        <div className={"row"}>
          <div className={"col-19 py-1 table-search-input-wrapper"}>
            <span className="p-float-label w-100">
              <InputText
                id="query"
                name="query"
                onChange={searchFormik.handleChange}
                onBlur={searchFormik.handleBlur}
                value={searchFormik.values.query}
                className={"w-100"}
              />
              <label htmlFor="query">{labelFilter}</label>
            </span>

          </div>
          <div className={"col-2"}>
            <ActionButton type={ACTION_TYPE.SUBMIT}
                          shape={BUTTON_SHAPE.ROUNDED}
                          submit={true}
                          showLabel={false}
                          icon={'icon-refresh'}
            />
          </div>
          <div className={"col-2"}>
            <ActionButton type={ACTION_TYPE.BUTTON}
                          shape={BUTTON_SHAPE.ROUNDED}
                          showLabel={false}
                          icon={'icon-delete'}
                          command={
                            (e) => {
                              searchFormik.values.query = '';
                              searchFormik.handleSubmit(e);
                              setShowFilter(false);
                            }
                          }
            />
          </div>
          <div className={"col-1"}>
            <></>
          </div>
        </div>
      </form>
    </div>
  );
  const filterElementClosed = (
    <>
      <div className={"col-1"}>
        <ActionButton type={ACTION_TYPE.BUTTON}
                      shape={BUTTON_SHAPE.ROUNDED}
                      showLabel={false}
                      icon={'icon-search'}
                      command={(e) => setShowFilter(true)}
        />
      </div>
      <div className={"col-11"}>
        <></>
      </div>
    </>
  );


  /*
  *
  *  TABLE
  *
  */
  // @ts-ignore
  const {columns, items, count, dataTableProps} = props;

  /*
  *
  *  SEARCH
  *
  */
  const {actions, exportEntities} = props;
  const exportAction: IButtonProps = {
    type: ACTION_TYPE.CUSTOM,
    shape: BUTTON_SHAPE.ROUNDED,
    showLabel: true,
    label: 'Export',
    command: exportEntities,
    icon: 'icon-document-excel'
  };
  const [actBtns, setActBtns] = useState([props.showExport?exportAction:null]);
  // @ts-ignore
  useLayoutEffect(() => {
    setActBtns(_.concat(actions, actBtns));
  }, []);

  const actionElements = (
    <div className={"col-12"}>
      <div className={"d-flex justify-content-end"}>
        {actBtns && actBtns.map((act, index) => {
          if(act === null){return;}
          // @ts-ignore
          const cn = classNames('', act.styleRoot)
          // @ts-ignore
          return <ActionButton key={`btn-${act.label}-${index}`} {...act} styleRootAppend={true} styleRoot={cn}/>
        })}
      </div>
    </div>);


  const loadingElement = (
    <div className={"d-flex h-100 w-100 justify-content-center align-items-center"}>
      <ReactLoading type={'spinningBubbles'} color={'#eeeeee'} height={'30%'} width={'30%'}/>
    </div>
  );

  const showRow = props.showExport || props.showSearch || props.actions;
  const headerRow =  (
    showRow?
        <div className={"row py-2"}>
          {props.showSearch?showFilter ? filterElementOpen : filterElementClosed:<div className={"col-12"}></div>}
          {actionElements}
        </div>:
      <></>
);

  const tableElement = (
    <div className={"container-flow"}>
      {headerRow}
      <div className={"row"}>
        <div className={"col-24"}>
          <DataTable
            columns={columns}
            data={items.concat([])}
            activePage={paginationState.activePage}
            totalItems={count}
            itemsPerPage={paginationState.itemsPerPage}
            maxButtons={4}
            onSelectPage={handlePagination}
            onSort={(column, order) => {
              sort(column.field, order);
            }}
            onReload={loadTable}
          />
        </div>
      </div>
    </div>
  );


  return props.loading ? loadingElement : tableElement;

};

FormTable.defaultProps = {
  loading: true,
  labelFilter: 'Search',
  actions: [],
  showExport: true,
  showSearch: true
}








