import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LayoutNotification} from "../../utils/layout.notification";
import {v4} from 'uuid';

/**
 *  LayoutStandardNotification
 *
 */
export interface ILayoutStandardNotificationProps {
  notifications: LayoutNotification[];
}

const LayoutStandardNotification = (props: ILayoutStandardNotificationProps) => {
  return (
    <div className={"d-flex flex-row mx-2"}>
      {
        props.notifications && props.notifications.map(notification => {
          return (
            <span key={v4()} className="fa-layers fa-fw fs-1 px-2" >
              {
                // @ts-ignore
                notification.icon? <FontAwesomeIcon className={notification.className} icon={notification.icon} /> :<></>
              }
              <span className="fa-layers-counter" style={{background: "Tomato"}}>{notification.count}</span>
            </span>
          );
        })
      }
    </div>
  );
};

export default LayoutStandardNotification;
