import classNames from 'classnames/bind';
import {TemplateProps} from "./template-props";

export const calculateRootClassName = (props: TemplateProps) => {
  return classNames(
    'd-block',
    props.position,
    {'text-truncate': props.truncate},
    {'icon-on-hover': props.iconOnHover},
    props.textTransform,
    props.fontWeight
  );
};

export const calculateRootBooleanClassName = () => {
  return classNames(
    'd-flex',
    'w-100',
    'justify-content-center',
    'align-items-center'
  );
};

export const calculateIconBoolean = (data:any)=>{
  return classNames(
    'rounded-circle',
    'square-4',
    'border-top-1',
    'border-right-1',
    'border-bottom-1',
    'border-left-1',
    {'background-dark-lime-green':data},
    'border-left-color-dark-lime-green',
    'border-top-color-dark-lime-green',
    'border-right-color-dark-lime-green',
    'border-bottom-color-dark-lime-green',
  );
}


