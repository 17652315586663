import React from "react";
import {DataTableColumn} from "./data-table-column";
import DataTableRowCell from "./data-table-row-cell";
import classNames from 'classnames/bind';

export interface IDataTableRowProps{
  columns: DataTableColumn[];
  row: any;
  rowIndex:number;
}

const DataTableRow = (props:IDataTableRowProps)=>{
return (
  <tr className={classNames('data-table-row')}>
    {props.columns&&props.columns.map((column, index)=><DataTableRowCell key={`dtrc-${index}`} column={column} row={props.row} rowIndex={props.rowIndex} colIndex={index} columnCount={props.columns.length}/>)}
  </tr>
);
}

export default DataTableRow;
