import React, {useEffect, useState} from "react";
import PaginationItem from "../pagination/pagination-item";
import {PaginationLink} from "../pagination/pagination-link";
import Pagination from "../pagination/pagination";

interface IFlatTablePaginationProps {
  activePage: number;
  onSelect: (page: number) => void;
  maxButtons: number;
  totalItems: number;
  itemsPerPage: number;
  showPagination: boolean;
  paginationLabel?:any;
}

/**
 *
 */
export const FlatTablePagination = (props:IFlatTablePaginationProps) => {
  const [currentpage, setCurrentPage] = useState(1);
  const {activePage} = props;
  useEffect(() => cleanActivePage(), []);
  useEffect(() => {
    setCurrentPage(props.activePage)
  }, [props.activePage]);

  const getMaxPage = () => {
    const {itemsPerPage, totalItems} = props;
    const division = Math.floor(totalItems / itemsPerPage);
    const modulo = totalItems % itemsPerPage;
    return division + (modulo !== 0 ? 1 : 0);
  };
  const maxPage = getMaxPage();

  const updateActivePage = (page) => () => {
    setCurrentPage(page);
    props.onSelect(page);
  }
  const previousPage = () => {
    const cp = currentpage-1;
    if(cp < 1){return;}
    setCurrentPage(cp);
    props.onSelect(cp);
  }
  const nextPage = () => {
    const cp = currentpage+1;
    if(cp > maxPage){return;}
    setCurrentPage(cp);
    props.onSelect(cp);
  };

  const cleanActivePage = () => {
    const {totalItems, itemsPerPage, activePage} = props;
    const ap = totalItems === 0 ? 1 : Math.min(activePage, Math.ceil(totalItems / itemsPerPage));

    if (ap !== activePage) {
      updateActivePage(cleanActivePage)();
    }
  };



  const itemsToDisplay = activePage => {
    const items = [];
    let item: any = {};
    let previousItem: any = {};
    const padSup = Math.floor((props.maxButtons - 1) / 2);
    const modulo = (props.maxButtons - 1) % 2;
    const padInf = padSup + modulo;
    for (let j = 0; j < maxPage; j++) {
      item = {};
      if (
        j === 0 ||
        j === maxPage - 1 ||
        j === activePage - 1 ||
        j === activePage - 2 ||
        (activePage === 1 && j === 1) ||
        (activePage - padInf < j && j < activePage + padSup)
      ) {
        item.display = 'display';
      } else if (previousItem.display === 'disabled') {
        item.display = 'hidden';
      } else {
        item.display = 'disabled';
      }
      // @ts-ignore
      items.push(item);
      previousItem = {...item};
      if (item.display === 'hidden') {
        previousItem.display = 'disabled';
      }
    }
    return items;
  };

  const displayPaginationItem = i => (
    <PaginationItem key={i} active={i===currentpage-1}>
      <PaginationLink onClick={updateActivePage(i + 1)}>
        {i + 1}
      </PaginationLink>
    </PaginationItem>
  );



  // @ts-ignore
  const items: any[] = itemsToDisplay(activePage);
  return (
    <div className={'pagination-wrapper d-flex justify-content-center align-items-center'}>
      {props.showPagination?<Pagination>
        <PaginationItem {...activePage === 1 && {disabled: true}}>
          <PaginationLink previous onClick={previousPage} className={"p-1 m-0"}>
            <div className={"pagination-left"}></div>
          </PaginationLink>
        </PaginationItem>
        {
          items.map(
            (paginationItem, i) =>
              paginationItem.display === 'display' ? (
                displayPaginationItem(i)
              ) : paginationItem.display === 'disabled' ? (
                <PaginationItem disabled key={i}>
                  <PaginationLink>...</PaginationLink>
                </PaginationItem>
              ) : null
          )}
        <PaginationItem {...activePage === maxPage && {disabled: true}}>
          <PaginationLink next onClick={nextPage} className={"p-1 m-0"}>
            <div className={"pagination-right"}></div>
          </PaginationLink>
        </PaginationItem>
      </Pagination>:<></>}
      {props.paginationLabel?<div className={"pagination-label me-1"}>{props.paginationLabel}</div>:<div></div>}
    </div>
  );
};
