/* eslint-disable object-shorthand */
import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Formik} from "formik";

import {FormInputSimple} from "./form-input-simple";
import {MESSAGE_TYPE} from "./form-message";
import {Card, CARD_IMPORTANCE, Col, Row} from "../../elements";
import {ActionButton, STANDARD_CANCEL_PROPS, STANDARD_SAVE_SUBMIT_PROPS} from "../../components";
import {Storage} from "../../utils";

export interface IFormInputCrudProps {
  formId?:string;
  entity: any;
  updating: boolean;
  updateSuccess: boolean;
  errorMessage?: string;
  loading: boolean;
  validationSchema?: any;
  saveFormState?:boolean;

  navigationOnClose: string;

  component: JSX.Element;

  titleForm: string;
  titleCard: string;
  labelButtonDelete: string;
  labelButtonSave: string;
  labelButtonCancel: string;

  createEntity: (data: any) => void;
  updateEntity: (data: any) => void;
  deleteEntity: (id?: string | number) => void;
  getEntity: (id: string | number) => void;
  reset: () => void;
}

export const FormInputCrud = (props: IFormInputCrudProps) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [isNew, setIsNew] = useState(!match.params || !match.params.id || match.params.id === 'new');

  const handleClose = () => {
    props.reset();
    history.push(props.navigationOnClose);
  };

  const handleDelete = () => {
    props.deleteEntity(match.params.id);
    props.reset();
  };

  const handleSave = (values) => {
    const entity = {
      ...props.entity,
      ...values
    };
    if (isNew) {
      props.createEntity(entity);
    } else {
      props.updateEntity(entity);
    }
    props.reset();
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(match.params.id);
    }
  }, []);

  const [initialValues, setInitialValues] = useState(props.entity);

  useEffect(()=>{
    if( props.saveFormState&&props.formId ) {
      const val = {
        ...props.entity,
        ...Storage.local.get(props.formId, props.entity),
      }
      Storage.local.set(props.formId, val);
      setInitialValues(val);
    } else {
      setInitialValues(props.entity);
    }
  },[props.entity]);


  return (
    <FormInputSimple
      title={props.titleForm}
      onEsc={(e) => handleClose()}
      messageType={MESSAGE_TYPE.ERROR}
      message={props.errorMessage}
      loading={props.loading}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnBlur={true}
        validationSchema={props.validationSchema}
        onSubmit={(values) => {
          // if (!formik.isValid) {return;}
          if(props.saveFormState&&props.formId)Storage.session.remove(props.formId);
          handleSave(values);
        }}

      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Card
              title={props.titleCard}
              showSideSpace

              importance={CARD_IMPORTANCE.HIGH}
              showSideButton={!isNew}
              sideButtonBordered={false}
              sideButtonIcon={'icon-delete'}
              sideButtonLabel={props.labelButtonDelete}
              sideButtonBackgroundClassName={'background-red'}
              sideButtonColorClassName={'color-white'}
              onSideAction={(e) => {
                handleDelete();
              }}
              content={props.component}
            />
            <Row visible={true}>
              <Col classname={"d-flex justify-content-center"}>
                <ActionButton
                  {...STANDARD_SAVE_SUBMIT_PROPS}
                  label={props.labelButtonSave}
                />
                <ActionButton
                  {...STANDARD_CANCEL_PROPS}
                  label={props.labelButtonCancel}
                  command={() => {
                    handleClose();
                  }}
                />
              </Col>
            </Row>
          </form>
        )}

      </Formik>
    </FormInputSimple>
  );
};

FormInputCrud.defaultProps = {
  saveFormState: true
}





