import React from "react";

import ReactLoading from "react-loading";

export interface ILoadingProps {
  type?: string;
  className?:string;
  color?: string;
  height?:string;
  width?:string;
  visible?: boolean;
  delay?:number;
}

export class Loading extends React.Component<ILoadingProps> {
  static defaultProps = {
    type: 'spinningBubbles',
    color: '#000000',
    height:'30%',
    width:'30%',
    visible: true
  }

  render() {


    return (
      <div className={"d-flex h-100 w-100 justify-content-center align-items-center"}>
        <ReactLoading  type={"spinningBubbles"} color={this.props.color} height={this.props.height} width={this.props.width} delay={this.props.delay} />
      </div>
    );
  }
};
