import React from "react";
import LayoutStandardHeaderLogo from "./layout.standard.header.logo";
import LayoutStandardHeaderProfile from "./layout.standard.header.profile";
import LayoutStandardNotification from "../notification/layout.standard.notification";
import {LayoutNotification} from "../../utils/layout.notification";

/**
 *  LayoutStandardHeader
 *
 */
export interface ILayoutStandardHeaderProps{
  logo?: JSX.Element;
  logoLink?:string;
  showProfile: boolean;
  notifications?: LayoutNotification[];
}


export class LayoutStandardHeader extends React.Component<ILayoutStandardHeaderProps>{
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={"d-flex flex-row align-items-center justify-content-between standard-layout-header-wrapper"}>
      <LayoutStandardHeaderLogo logo={this.props.logo} link={this.props.logoLink}/>
      <div className={"d-flex flex-grow-1 align-items-center"}>
        {this.props.children}
      </div>
      <div className={"d-flex flex-row align-items-center"}>
        {this.props.notifications?<div className={"d-flex"}><LayoutStandardNotification notifications={this.props.notifications}/></div>:<></>}
        {this.props.showProfile?<div className={"d-flex"}><LayoutStandardHeaderProfile/></div>:<></>}
      </div>
    </div>
    );
  }
};

