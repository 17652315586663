import React from "react";
import classNames from "classNames/bind";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export enum SORT_TYPE{
  NO,
  ASC,
  DESC
}


export interface IFlatTableHeaderCellProps {
  first?: boolean;
  className?: string;
  gridLayout?:boolean;
  titleClassName?: string;
  appendClassName?:boolean;
  appendTitleClassName?:boolean;
  showSort?:boolean;
  sortType:SORT_TYPE;
  onHeaderClick?:(sort:SORT_TYPE)=>void;
  showBorder?:boolean;
  showResizer?:boolean;
  resizerProps?:any;
  isResizing?:boolean;
};

interface IFlatTableHeaderCellState{
  sort: SORT_TYPE
}


/**
 *
 */
export class FlatTableHeaderCell extends React.Component<IFlatTableHeaderCellProps, IFlatTableHeaderCellState> {
static defaultProps = {
  appendClassName: true,
  appendTitleClassName: true,
  gridLayout: true,
  showSort: false,
  sortType: SORT_TYPE.NO,
  showBorder: true,
  showResizer: false,
  isResizing:false
};

  constructor(props) {
    super(props);
    this.state = {
      sort: this.props.sortType
    }
    this.handleSort = this.handleSort.bind(this);
  }

  handleSort = ()=>{
    const st = this.state.sort === SORT_TYPE.ASC?SORT_TYPE.DESC:this.state.sort === SORT_TYPE.DESC?SORT_TYPE.NO:SORT_TYPE.ASC;
    this.setState({sort: st},()=>{
      if(this.props.onHeaderClick)this.props.onHeaderClick(this.state.sort);
    });
  }

  calculateStyle = () => {
    if (this.props.className && !this.props.appendClassName) {
      return this.props.className;
    }
    if (this.props.first) {
      return classNames({'col':this.props.gridLayout}, "justify-content-center","align-items-center",{"d-flex flex-row":this.props.showSort},{"border-right-color-silver border-right-1":!this.props.showResizer}, this.props.className);
    }
    return classNames({'col':this.props.gridLayout}, "justify-content-center", "align-items-center",
      {"border-left-color-silver border-left-1":this.props.showBorder},
      {"d-flex flex-row":this.props.showSort}, this.props.className);
  }

  calculateResizerStyle = ()=> {
    return classNames("resizer",{"isResizing": this.props.isResizing},"border-left-color-silver border-left-1")
  }

  render() {
    const title = this.props.titleClassName && !this.props.appendTitleClassName?this.props.titleClassName:
      classNames('text-uppercase', 'text-center', 'font-weight-bold', 'description-text','flex-grow-1',this.props.titleClassName);
    return (
      <div {...this.props} className={this.calculateStyle()} onClick={()=>this.handleSort()}>
        <div className={title}>{this.props.children}</div>
        {this.props.showResizer?
          <div {...this.props.resizerProps} className={this.calculateResizerStyle()}>
          </div>:<></>
        }
        {this.props.showSort?
          <div className={"d-flex flex-column flex-shrink-1 px-2"}>
            <FontAwesomeIcon icon={[this.state.sort === SORT_TYPE.ASC?"fas":"far","caret-up"]} />
            <FontAwesomeIcon icon={[this.state.sort === SORT_TYPE.DESC?"fas":"far","caret-down"]} />
         </div>:<></>}
      </div>
    );
  }
};

