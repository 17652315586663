import React from "react";
import classNames from 'classnames/bind';
/**
 *  LayoutStandardSidebar
 *
 */
export interface ILayoutStandardSidebarProps{
  showSideBar: boolean;
  sideBarElement?: JSX.Element;
}

const LayoutStandardSidebar = (props:ILayoutStandardSidebarProps) => {
  const cn = classNames(
    'standard-layout-sidebar-wrapper',
    'd-flex',
    'flex-column',
    'justify-content-start',
    'p-0'
  );
  //
  return props.showSideBar&&props.sideBarElement ? <div className={cn}>{props.sideBarElement}</div> : <></>;
};

export default LayoutStandardSidebar;
