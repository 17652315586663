import React from "react";
import classNames from 'classnames/bind';

export interface IHeadlineH1Props {
  headline?: string;
  rootClassName?: string;
  headlineClassName?:string;
  childrenClassName?:string;
}

export class HeadlineH1 extends React.Component<IHeadlineH1Props> {
  constructor(props) {
    super(props);
  }

  render() {
    const rootCN = classNames('p-2', 'm-2', 'border-bottom-1', 'border-bottom-color-light-peach', 'd-flex', 'flex-row', 'justify-content-between',this.props.rootClassName);
    const headlineH1CN = classNames('headline-h1', 'd-inline-block',this.props.headlineClassName);
    const childWCN = classNames('d-inline-block',this.props.childrenClassName);

    return (
      <div className={rootCN}>
        <div className={headlineH1CN}>{this.props.headline}</div>
        {this.props.children ? <div className={childWCN}>{this.props.children}</div> : <></>}
      </div>
    );
  }

}

