import React from "react";
import classNames from 'classnames/bind';

export interface IHeadlineCopyRegularProps{
  headline?:string;
  className?:string;
}

export class HeadlineCopyRegular extends React.Component<IHeadlineCopyRegularProps>{
  constructor(props) {
    super(props);
  }

  render() {
    const headlineCopyBoldCN = classNames('copy_regular','d-inline-block', this.props.className);

    return (
      <div className={headlineCopyBoldCN}>{this.props.headline}</div>
    );
  }

}

