import React, {MutableRefObject, useEffect, useState} from "react";
import {InputComponent} from "./input-component";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import {useField, useFormikContext} from "formik";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {Empty} from "./input-component-empty";
import {getObjectValueFromPath} from "../../../utils/common/object_path";

export interface IInputComponentSelectProps {
  id: string;
  name: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;

  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  editable?: boolean;
  info?: any;
  loading?: boolean;


  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?: boolean;
  /** Used as value if wrapInForm === false*/
  value?: any;
  // props for Select element
  // https://react-select.com/home
  selectProps?: any;
  //
  options: Array<any> | ReadonlyArray<any>;
  getOptionValue?: (option) => any;
  getOptionLabel?: (option) => any;
  onChangeOption?: (option) => void;

  styleClassWrapper?: string;
  styleClassRoot?: string;
  styleClassLabel?: string;
  styleClassInput?: string;
  styleClassElement?: string;
  styleClassError?: string;
  styleClassIcon?: string;
  styleClassInfo?: string;

};

const animatedComponents = makeAnimated();

export const InputComponentSelect = React.forwardRef((props: IInputComponentSelectProps, ref: MutableRefObject<any>) => {
  // Return empty if not visible
  if (!props.visible) return <></>;
  // If wrapi in form, fetch fields
  const [field, meta, helpers] = props.wrapInForm ? useField(props.name) : [null, null, null];
  const value = props.wrapInForm ?
    field?.value ? field.value : '' :
    props.value;

  const formik = useFormikContext();
  const [inError, setInError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.wrapInForm && meta?.error && meta?.touched) {
      setInError(true);
      // @ts-ignore
      setError(meta.error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm, meta, meta?.error, meta?.touched]);

  useEffect(() => {
    if(!formik){return;}const error = getObjectValueFromPath(props.name, formik.errors);
    //const touched = getObjectValueFromPath(props.name, formik.touched);
    if (props.wrapInForm && formik.errors && error){
      setInError(true);
      // @ts-ignore
      setError(error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm,formik, formik?.errors]);
  const [inFocus, setInFocus] = useState(false);

  const handleChange = (newValue: any, actionMeta: any) => {
    switch (actionMeta?.action) {
      case "select-option":
      case "set-value":
        props.getOptionValue ? helpers?.setValue(props.getOptionValue(newValue)) : helpers?.setValue(newValue);
        if (props.onChangeOption) props.getOptionValue ? props.onChangeOption(props.getOptionValue(newValue)) : props.onChangeOption(newValue);
        break;
      case "remove-value":
      case "clear":
      case 'pop-value':
        helpers?.setValue(null);
        if (props.onChangeOption) props.onChangeOption(null);
        break;
      default:
        break;

    }
  };

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      border: 'none'
    }),
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: props.editable ? provided.display : 'none'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      display: props.editable ? provided.display : 'none'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontWeight: props.required ? '700 !important' : '400 !important',
      color: '#000000',

    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex:'999999 !important'
    })
  }


  return (
    // @ts-ignore
    <InputComponent {...props} error={error} focused={inFocus}>
      {props.editable ? <Select
          ref={ref}
          defaultValue={value}
          components={animatedComponents}
          isDisabled={!props.editable}
          styles={customStyles}
          isLoading={props.loading}
          isClearable={true}
          options={props.options}
          getOptionLabel={props.getOptionLabel}
          getOptionValue={props.getOptionValue}
          {...props.selectProps}
          onChange={handleChange}
          onFocus={(e) => {
            setInFocus(true);
          }}
          onBlur={(e) => setInFocus(false)}
        /> :
        <Empty required={props.required} inError={inError} value={props.wrapInForm ? field?.value : props.value}
               ref={ref}/>}
    </InputComponent>
  );
});

InputComponentSelect.defaultProps = {
  showBackground: false,
  required: false,
  visible: true,
  editable: true,
  wrapInForm: true,
  loading: false,
  selectProps: {}
}
