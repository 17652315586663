import React from "react";
import {BrowserRouter as Router, Switch, Route, useRouteMatch, useParams} from "react-router-dom";
import {FormikHelpers} from "formik/dist/types";
import {LoginForm, UserData} from "./login_form";
import {ForgotPasswordData, LoginForgotPassword} from "./login_forgot_password";
import {LoginPage} from "./login_page";

export interface ILogin {
  loginData: UserData;
  changePasswordData: ForgotPasswordData;
  labelTitle?: string;
  labelSubtitle?: string;
  labelSubtitleForgotPassword?: string;
  labelUsername?: string;
  labelPassword?: string;
  labelRememberMe?: string;
  labelForgotPassword?: string;
  labelChangePassword?: string;
  labelEmail?: string;
  labelLogin?: string;
  validationSchema?: any;
  loginError?: boolean;
  capchaSiteKey: string;
  showRememberMe?: boolean;
  showForgotPassword?: boolean;
  forgotPasswordLink?: string;
  redirectToAfterForgotPassword?: string;
  makeRedirectAfterChangeRequest?: boolean;
  onForgotPasswordRequest: (values: ForgotPasswordData, formikHelpers: FormikHelpers<ForgotPasswordData>) => void | Promise<any>
  onLogin: (values: UserData, formikHelpers: FormikHelpers<UserData>) => void | Promise<any>
}

export const Login = (props: ILogin) => {
  let match = useRouteMatch();
  return (
    <LoginPage>
      <Router>
        <Switch>
          <Route path={`${match.url}`}>
            <LoginForm {...props} initialValues={props.loginData} forgotPasswordLink={`${match.url}forgotPassword`}/>
          </Route>
          <Route path={`${match.url}forgotPassword`}>
            <LoginForgotPassword {...props} initialValues={props.changePasswordData}
                                 labelSubtitle={props.labelForgotPassword}/>
          </Route>
        </Switch>
      </Router>
    </LoginPage>
  );
};


