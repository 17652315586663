import React, {useEffect} from "react";
import {useFlexLayout, useResizeColumns, useRowSelect, useTable,} from 'react-table';
import classNames from "classNames/bind";
import {FlatTable} from "../flat-table/flat-table";
import {FlatTableHeader} from "../flat-table/flat-table-header";
import {FlatTableHeaderCell} from "../flat-table/flat-table-header-cell";
import {FlatTableData} from "../flat-table/flat-table-data";
import {FlatTableDataRow} from "../flat-table/flat-table-data-row";
import {FlatTableDataCell} from "../flat-table/flat-table-data-cell";


// @ts-ignore
const SelectRow = React.forwardRef(({indeterminate, isSelected, ...rest}, ref) => {
    React.useEffect(() => {
      if (ref) {
        ref = indeterminate
      }

    }, [ref, indeterminate])

// @ts-ignore
    return <input type="checkbox" ref={ref} {...rest} />
  }
);

interface IFullTableProps {
  columns: any;
  data: any;
  showRowSelection?: boolean;
  onRowSelectionChange?: (selectedRows) => void;
}


/**
 *
 */
export const FullTable = (props: IFullTableProps) => {
  const getStyles = (props, align = 'left') => [
    props,
    {
      style: {
        justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
      },
    },
  ]

  const headerProps = (props, {column}) => getStyles(props, column.align)

  const cellProps = (props, {cell}) => getStyles(props, cell.column.align)

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 15, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      //maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: {selectedRowIds}
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn: defaultColumn,
    },
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
    hooks => {
      if (props.showRowSelection) {
        hooks.allColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            disableResizing: true,
            minWidth: 35,
            width: 35,
            maxWidth: 35,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({getToggleAllRowsSelectedProps}) => (
              <div>
                <SelectRow {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({row}) => (
              <div>
                <SelectRow {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
        hooks.useInstanceBeforeDimensions.push(({headerGroups}) => {
          // fix the parent group of the selection button to not be resizable
          const selectionGroupHeader = headerGroups[0].headers[0]
          selectionGroupHeader.canResize = false
        });
      }
    }
  );

  useEffect(() => {
    if (props.onRowSelectionChange) {
      props.onRowSelectionChange(selectedFlatRows);
    }
  }, [selectedFlatRows]);

  return (
    <div className={classNames("overflow-auto", "d-block", "p-1")}>
      <FlatTable {...getTableProps()}>
        {/*<div>*/}
        {headerGroups.map(headerGroup => (
          <FlatTableHeader {...headerGroup.getHeaderGroupProps({})}>
            {headerGroup.headers.map((column, index) => (
              <FlatTableHeaderCell first={index === 0}
                                   {...column.getHeaderProps(headerProps)}
                                   showResizer={column.canResize}
                                   showBorder={!column.canResize}
                                   resizerProps={column.canResize ? {...column.getResizerProps()} : null}
                                   isResizing={column.isResizing}
                                   {...column}
              >
                {column.render('Header')}
              </FlatTableHeaderCell>
            ))}
          </FlatTableHeader>
        ))}
        <FlatTableData>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <FlatTableDataRow {...row.getRowProps()} last={index === rows.length - 1}>
                {row.cells.map((cell, index) => {
                  return (
                    <FlatTableDataCell showBorder={index !== 0} {...cell.getCellProps(cellProps)}>
                      {cell.render('Cell')}
                    </FlatTableDataCell>
                  )
                })}
              </FlatTableDataRow>
            )
          })}
        </FlatTableData>
      </FlatTable>
    </div>
  )
};

