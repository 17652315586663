import React from "react";
import {
  createElementWrapperClass, createErrorWrapperClass,
  createIconWrapperClass, createInfoWrapperClass, createInputTextClass,
  createInputWrapperClass,
  createLabelWrapperClass
} from "../utils/create_styles";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface IInputComponentInputWrapperProps {
  focused: boolean;
  icon?: string | string[];
  faIcon?: boolean;
  error?:string;
  info?:any;
  inputStyleClass?:string;
  iconStyleClass?:string;
  elementStyleClass?:string;
  errorStyleClass?:string;
  infoStyleClass?:string;
}

export class InputComponentInputWrapper extends React.Component<IInputComponentInputWrapperProps> {
  render() {
    return (
      <div className={createInputWrapperClass(this.props.focused === undefined ? false : this.props.focused, this.props.error!==null&&this.props.error!==undefined, this.props.inputStyleClass)}>

        {
          this.props.icon?
          this.props.faIcon?
            // @ts-ignore
            <FontAwesomeIcon className={createIconWrapperClass(this.props.icon, this.props.iconStyleClass)} icon={this.props.icon}/>:
            <div className={createIconWrapperClass(this.props.icon, this.props.iconStyleClass)}></div>:
            <></>
        }
        <div className={createElementWrapperClass(this.props.elementStyleClass)}>
          {this.props.children}
          {this.props.error ? <div className={createErrorWrapperClass(this.props.errorStyleClass)}>{this.props.error}</div> : <></>}
          {this.props.info ? <div className={createInfoWrapperClass(this.props.infoStyleClass)}>{this.props.info}</div> : <></>}
        </div>
      </div>
    );
  }
}
