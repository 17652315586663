import React, {useState} from "react";
import {DEFAULT_INPUT_ICONS, DEFAULT_LIST_ITEM_RENDER, INPUT_ELEMENT_TYPE} from "./input_element_contstants";
import {InputElement} from "./input_element";
import _ from 'lodash';

export interface IListChooserProps {
  componentName:string;
  icon?:DEFAULT_INPUT_ICONS;
  required?:boolean;
  label?:string;
  itemValue: (item:any)=>{};
  items:any[];
  lookupItems: (search?: string)=>any[];
  showBackground?:boolean;
  listLoading?:boolean;
}

export const ListChooser = (props:IListChooserProps)=>{

  const [items, setItems] = useState(props.items);


  return (
    <InputElement
      id={props.componentName}
      name={props.componentName}
      type={INPUT_ELEMENT_TYPE.LIST_ONE_LARGE}
      icon={props.icon}
      required={props.required}

      itemValue={props.itemValue}
      showBackground={props.showBackground}

      label={props.label}
      listLoading={props.listLoading}
      listElements={items}

      onListSearch={(event, value) => {
        setItems(_.concat([], props.lookupItems(value)));
      }}
      itemTemplate={
        (item) => {
          return DEFAULT_LIST_ITEM_RENDER(item,false, it=>props.itemValue(it) )}
      }

      selectedItemTemplate={
        (item) => {
          return DEFAULT_LIST_ITEM_RENDER(item,true, it=>it)
        }
      }
    />
  );
}

ListChooser.defaultProps={
  icon:DEFAULT_INPUT_ICONS.DOCUMENT,
  required:false,
  label: 'autocomplete.label',
  labelNoResults:'NoResults.label',
  labelEnterSearch:'Enter.Search.label',
  itemValue: item => item
}
