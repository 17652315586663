import React from "react";
import {LayoutStandardHeader} from "./header/layout.standard.header";
import LayoutStandardProfile from "./profile/layout.standard.profile";
import classNames from 'classnames/bind';
import {ProfileLink} from "../utils/profile.link";
import {LayoutHelp} from "../utils/layout.help";
import {LayoutLogout} from "../utils/layout.logout";
import LayoutStandardSidebar from "./sidebar/layout.standard.sidebar";
import {LayoutNotification} from "../utils/layout.notification";

/**
 *  LayoutStandard
 *
 */
export const StandardProfileMenuContext = React.createContext({
  showProfile: false, setShowProfile: (showProfile: boolean) => {
  }
});


export interface ILayoutStandardProps {
  profileLinks?: ProfileLink[];
  help?: LayoutHelp;
  logout: LayoutLogout;
  logo?:JSX.Element;
  logoLink?:string;
  notifications?: LayoutNotification[];
  showSideBar: boolean;
  showProfileElement: boolean;
  sideBarElement?: JSX.Element;
}

export interface ILayoutStandardState {
  showProfile: boolean;
}

export class LayoutStandard extends React.Component<ILayoutStandardProps, ILayoutStandardState> {

  constructor(props) {
    super(props);
    this.state = {
      showProfile: false
    }
    this.setShowProfile = this.setShowProfile.bind(this);
  }

  setShowProfile = (show: boolean) => {
    this.setState({showProfile: show});
  }

  render() {
    const profileMenuStyle = classNames(
      {'d-none': !this.state.showProfile},
      {'profile-menu': this.state.showProfile}
    );

    return (
      <StandardProfileMenuContext.Provider
        value={{showProfile: this.state.showProfile, setShowProfile: this.setShowProfile}}>
        <div className={profileMenuStyle}>
          <LayoutStandardProfile setShowProfile={this.setShowProfile}
                                 links={this.props.profileLinks}
                                 help={this.props.help}
                                 logout={this.props.logout}/>
        </div>
        <div className={"d-flex flex-column h-100 w-100 main-layout-wrapper"}>
          <LayoutStandardHeader notifications={this.props.notifications} showProfile={this.props.showProfileElement} logo={this.props.logo} logoLink={this.props.logoLink}/>
          <div className={"d-flex flex-row flex-grow-1 w-100 standard-layout-bottom-wrapper"}>
            <LayoutStandardSidebar showSideBar={this.props.showSideBar} sideBarElement={this.props.sideBarElement}/>
            <div className={"d-flex flex-column flex-grow-1"}>{this.props.children}</div>
          </div>
        </div>
      </StandardProfileMenuContext.Provider>
    );
  }


};
