import React from "react";
import {createLabelWrapperClass} from "../utils/create_styles";

export interface IInputComponentLabelWrapperProps {
  label?: string;
  required: boolean;
  styleClass?:string;
}

export class InputComponentLabelWrapper extends React.Component<IInputComponentLabelWrapperProps>{
  render() {
    return (
      this.props.label ?
        <div className={createLabelWrapperClass(this.props.required, this.props.label ? true : false,this.props.styleClass)}>{this.props.label}</div> :
        <></>
    );
  }
}
