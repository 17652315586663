import React, {MutableRefObject} from "react";
import {createInputTextClass} from "../utils/create_styles";

export interface IEmptyProps {
  value?: any;
  required:boolean;
  inError: boolean;
  onClick?:(e)=>void;

}

export const Empty = React.forwardRef((props:IEmptyProps,ref:MutableRefObject<any>)=>{
  return (
    <div
      className={createInputTextClass(props.required?false:props.required, props.inError)}
      ref={ref}
      style={{minHeight: '1rem'}}
      onClick={props.onClick}
    >
      {props.value ? typeof props.value === 'object' && props.value !== null?JSON.stringify(props.value) : props.value: ''}
    </div>
  )
});

Empty.defaultProps={
  onClick: (e)=>{}
}
