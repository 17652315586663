import React from "react";
import {FONT_WEIGHT, TemplateProps, TEXT_POSITION, TEXT_TRANSFORM} from "./template-props";
import {calculateIconBoolean, calculateRootBooleanClassName} from "./template-styles";
import {DataTableColumn} from "../data-table/data-table-column";

export const defaultTemplateBoolean:TemplateProps = {
  position: TEXT_POSITION.CENTER,
  truncate: true,
  fontWeight: FONT_WEIGHT.NORMAL,
  textTransform: TEXT_TRANSFORM.NONE,
  iconOnHover: false
}

export const dataTableItemTemplateBoolean = (column:DataTableColumn, rowData:any, rowIndex:number, colIndex:number, columnCount:number, props?:TemplateProps)=>{
  const pr = props ? props : defaultTemplateBoolean;
  return (
    <div className={calculateRootBooleanClassName()}>
      <div className={calculateIconBoolean(rowData[column.field])}></div>
    </div>
  );
};
