export enum TEXT_POSITION {
  LEFT = 'text-left',
  CENTER = 'text-center',
  RIGHT = 'text-right'
};

export enum FONT_WEIGHT {
  NORMAL = 'font-weight-normal',
  BOLD = 'fw-bold',
  LIGHT = 'font-weight-light',
  ITALIC = 'font-italic'
};

export enum TEXT_TRANSFORM {
  NONE = '',
  LOWERCASE = 'text-lowercase',
  UPPERCASE = 'text-uppercase',
  CAPITALIZE = 'text-capitalize'
}

export interface TemplateProps {
  position?: TEXT_POSITION;
  truncate?: boolean;
  fontWeight?: FONT_WEIGHT;
  textTransform?: TEXT_TRANSFORM;
  icon?: string;
  dateFormat?:string;
  iconOnHover: boolean;
}
