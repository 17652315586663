import React, {useEffect, useState} from "react";
import {useField, useFormikContext} from "formik";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import {createInputTextClass} from "../utils/create_styles";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import {InputComponent} from "./input-component";
import {Storage} from "../../../utils";
import {getObjectValueFromPath} from "../../../utils/common/object_path";

export interface IInputComponentNumberProps {
  id: string;
  name: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;

  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  info?: any;

  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?:boolean;
  /** Used as value if wrapInForm === false*/
  value?:any;
  /**If saveFormState.*/
  formId?:string;
  /**Does the state of this field is saved*/
  saveFormState?:boolean;

  editable?:boolean;

  thousandSeparator?: string | boolean;
  decimalSeparator?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
  allowEmptyFormating?: boolean;
  allowLeadingZeros?: boolean;
  prefix?: string;
  suffix?: string;
  isNumericString?: boolean;
  displayType?: string;

  onValueChange?: (values: NumberFormatValues) => void;
  onChange?: (e) => void;
  onBlur?: (e) => void;
  onFocus?: (e) => void;

  styleClassWrapper?:string;
  styleClassRoot?:string;
  styleClassLabel?:string;
  styleClassInput?:string;
  styleClassElement?:string;
  styleClassError?:string;
  styleClassIcon?:string;
  styleClassInfo?:string;

  appendStyleClassWrapper?:boolean;
  appendStyleClassRoot?:boolean;
  appendStyleClassLabel?:boolean;
  appendStyleClassInput?:boolean;
  appendStyleClassElement?:boolean;
  appendStyleClassError?:boolean;
  appendStyleClassIcon?:boolean;
  appendStyleClassInfo?:boolean;
}

export const InputComponentNumber = React.forwardRef((props: IInputComponentNumberProps, ref) => {
  if (!props.visible) return null;
  const [inFocus, setInFocus] = useState(false);
  const [numericValue, setNumericValue]=useState(0);
  const [field, meta, helper] = props.wrapInForm?useField(props.name):[null,null,null];
  const formik = useFormikContext();
  const [inError, setInError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.wrapInForm && meta?.error && meta?.touched) {
      setInError(true);
      // @ts-ignore
      setError(meta.error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm, meta, meta?.error, meta?.touched]);

  useEffect(() => {
    if(!formik){return;}const error = getObjectValueFromPath(props.name, formik.errors);
    //const touched = getObjectValueFromPath(props.name, formik.touched);
    if (props.wrapInForm && formik.errors && error){
      setInError(true);
      // @ts-ignore
      setError(error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm,formik, formik?.errors]);
  return (
    // @ts-ignore
    <InputComponent {...props} error={error} focused={inFocus}>
      <NumberFormat
        id={props.id}
        getInputRef={ref}
        name={props.name}
        thousandSeparator={props.thousandSeparator}
        decimalSeparator={props.decimalSeparator}
        decimalScale={props.decimalScale}
        fixedDecimalScale={props.fixedDecimalScale}
        allowNegative={props.allowNegative}
        allowLeadingZeros={props.allowLeadingZeros}
        allowEmptyFormatting={props.allowEmptyFormating}
        prefix={props.prefix}
        suffix={props.suffix}
        value={props.wrapInForm?field?.value:props.value}
        className={createInputTextClass(props.required, inError, props.styleClassInput, props.appendStyleClassInput)}
        displayType={props.editable?'input':'text'}
        onValueChange={
          (values) => {
            if (props.onChange) props.onChange(values.value);
            if (props.onValueChange) props.onValueChange(values);
            field?.onChange(values.value);
            setNumericValue(values.floatValue===undefined?0:values.floatValue);
          }
        }
        onChange={(e)=>field?.onChange(e)}
        onFocus={(e) => {
          setInFocus(true);
          if (props.onFocus) {
            props.onFocus(e);
          }
        }}
        onBlur={(e) => {
          setInFocus(false);
          if (props.onBlur) props.onBlur(e)
          field?.onBlur(e);
          helper?.setValue(numericValue);
          if(props.saveFormState&&props.formId){
            const entity = {
              ...Storage.local.get(props.formId),
            };
            entity[props.name] = numericValue;
            Storage.local.set(props.formId, entity);
          }
        }}
      />
    </InputComponent>
  );
});

InputComponentNumber.defaultProps = {
  showBackground: false,
  required: false,
  visible: true,
  editable: true,
  wrapInForm: true,
  thousandSeparator: true,
  decimalSeparator: '.',
  decimalScale: 2,
  fixedDecimalScale: true,
  allowNegative: true,
  allowEmptyFormating: true,
  allowLeadingZeros: false,
  displayType: 'input',
  appendStyleClassWrapper:true,
  appendStyleClassRoot:true,
  appendStyleClassLabel:true,
  appendStyleClassInput:true,
  appendStyleClassElement:true,
  appendStyleClassError:true,
  appendStyleClassIcon:true,
  appendStyleClassInfo:true
}
