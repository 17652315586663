import * as React from 'react';
import {
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames/bind';
import _ from 'lodash';
import {
  ACTION_TYPE,
  ActionButton,
  BUTTON_SHAPE,
  LAYOUT_TYPE,
  NavigationBox,
  NavigationRow,
  NavigationWrapper
} from "../../components";
import {Functionality, Module} from "./navigation-modules";


export interface INavigationModulesLandingProps {
  modules: Module[];
  modulesPerRow: number;
  headerName?: string | React.Component | any;
  headerTitle?: string | React.Component | any;
  labelReturn?: string | React.Component | any;
}


export const NavigationModulesLanding = (props: INavigationModulesLandingProps) => {
  let match = useRouteMatch();
  const chunks = _.chunk(props.modules, 4);
  const moduleLinks = chunks && chunks.map((chunk, index) => {
    return (
      <div key={`chunk-${index}`} className={"d-flex flex-row"}>
        {chunk && chunk.map((module, ind) => {
          return <ModuleLinkElement key={`module-link-${module.name}-${ind}`} {...module}/>
        })}
      </div>
    )
  });

  return (
    <>
      <Switch>
        <Route path={`${match.url}`} exact>
          <NavigationWrapper>
            <div className={"header d-flex flex-column justify-content-center align-items-center mt-3 mb-3"}>
              {props.headerName ? <div className={"name"}>{props.headerName}</div> : <></>}
              {props.headerTitle ? <div className={"title"}>{props.headerTitle}</div> : <></>}
            </div>
            {moduleLinks}
          </NavigationWrapper>
        </Route>
        {
          props.modules && props.modules.map(
            (module, index) => {
              return (
                <Route key={`module-route-${module.name}-${index}`} path={module.target} exact={module.exact}>
                  <div className={"navigation-modules w-100 landing container-flow"}>
                    <div
                      className={"navigation-wrapper w-100 d-flex flex-column justify-content-begin align-items-center m-3"}>
                      <div className={"header-light w-100 d-flex flex-column justify-content-center align-items-center mb-2"}>
                        {props.headerName ? <div className={"name"}>{props.headerName}</div> : <></>}
                        {props.headerTitle ? <div className={"title"}>{props.headerTitle}</div> : <></>}
                      </div>
                      <div
                        className={"header2 w-100 d-flex flex-row justify-content-between align-items-center mt-2 mb-2"}>
                        <div className={"title"}>{module.label}</div>
                        <ActionButton shape={BUTTON_SHAPE.ROUNDED} layout={LAYOUT_TYPE.TOP_ICON_BOTTOM_TEXT}
                                      type={ACTION_TYPE.LINK}
                                      link={`${match.url}`} icon={'icon-left-arrow'} label={props.labelReturn}/>
                      </div>
                      {module.functionalities && module.functionalities.map((funct, index) => {
                        return (
                          <FunctionalityLinkElement key={`funct-link-${funct.name}-${index}`} {...funct} />
                        )
                      })}
                    </div>
                  </div>
                </Route>
              );
            }
          )
        }
      </Switch>
    </>
  );
};
//
const ModuleLinkElement = (module: Module) => {
  return <NavigationBox {...module} label={module.name}/>
};

const FunctionalityLinkElement = (funct: Functionality) => {
  return <NavigationRow {...funct} label={funct.name}/>
};






