import React from "react";
import {createInputElementWrapperClass} from "../utils/create_styles";

export interface IInputElementWrapperProps {
  showBackground?: boolean;
  styleClass?:string;
}

export class InputComponentWrapper extends React.Component<IInputElementWrapperProps>{
  render() {
    return <div className={createInputElementWrapperClass(this.props.showBackground, this.props.styleClass)}>{this.props.children}</div>;
  }
}
