import React from "react";
import classNames from "classNames/bind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IFlatTableHeaderCellActionsProps {
  className?: string;
  appendClassName?: boolean;
  showBorder?: boolean;
  onInsertNew: () => void;
  gridLayout?: boolean;
}
/**
 *
 */
export class FlatTableHeaderCellActions extends React.Component<IFlatTableHeaderCellActionsProps>{

  static defaultProps = {
    appendClassName: true,
    showBorder: true,
    gridLayout: true
  };

  render() {
    const {onInsertNew, appendClassName, className} = this.props;
    const cn = className && !appendClassName ? className :
      classNames({'col':this.props.gridLayout}, "d-flex flex-row", 'align-items-stretch align-self-center justify-content-center px-2 text-center',this.props.className, 'table-action');
    return (
      <div className={cn}>
          <div className={"mx-1 table-action-wrapper"} onClick={() => {
            if (onInsertNew) {onInsertNew();}
          }}>
            <FontAwesomeIcon className={"fs-3 table-action-icon"} icon={["far", "plus"]}/>
          </div>
        {this.props.children}
      </div>
    );
  }

};

