import React from "react";
import {DataTableColumn} from "./data-table-column";
import DataTableHeader from "./data-table-header";
import DataTableBody from "./data-table-body";

import classNames from 'classnames/bind';
import DataTablePagination from "./data-table-pagination";

export interface IDataTableProps {
  columns: DataTableColumn[];
  data: any[];
  activePage: number;
  totalItems: number;
  itemsPerPage: number;
  showPagination?: boolean;
  showReload?:boolean;
  maxButtons?: number;
  paginationLabel?:any;
  onSort: (column:DataTableColumn, order:string)=>void;
  onSelectPage: (page: number) => void;
  onReload?:()=>void;
}

export const DataTable = (props: IDataTableProps) => {
  return (
    <div className={classNames('data-table')}>
      <div className={'data-table-wrapper'}>
        <div className={'title-row'}></div>
        <table className={'table-element'}>
          <DataTableHeader columns={props.columns} onSort={props.onSort}/>
          <DataTableBody columns={props.columns} data={props.data}/>
        </table>
          <DataTablePagination
          activePage={props.activePage}
          onSelect={props.onSelectPage}
          maxButtons={props.maxButtons?props.maxButtons:4}
          showPagination={props.showPagination?props.showPagination:true}
          showReload={props.showReload?props.showReload:true}
          totalItems={props.totalItems}
          itemsPerPage={props.itemsPerPage}
          paginationLabel={props.paginationLabel}
          onReload={props.onReload}
        />
      </div>
    </div>
  );
}

DataTable.defaultProps = {
  showPagination: true,
  showReload: true,
  maxButtons: 4
}
