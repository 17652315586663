import React, {MutableRefObject, useEffect, useState} from "react";
import {ITabRouterLinkProps, TabRouterLink} from "./tab-router-link";
import classNames from 'classnames/bind';
import {TabsRouter} from "./tabs-router";

export enum TAB_ROUTER_LAYOUT{
  DEFAULT=0,
  CENTER=1,
  EVEN=2,
  SPREAD=3
}

export interface ITabRouterProps {
  tabs: TabsRouter[];
  layout?:TAB_ROUTER_LAYOUT;
  className?: string;
  classNameLinks?:string;
  classNameContent?:string;

}

export interface ITabRouterState {
  selectedTab:string;
}

export class TabRouter extends React.Component<ITabRouterProps, ITabRouterState> {
  static defaultProps = {
    layout: TAB_ROUTER_LAYOUT.DEFAULT
  }
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: this.props.tabs[0].id
    }
    this.calculatePath = this.calculatePath.bind(this);
  }

  calculatePath = ()=>{
    const path = window.location.pathname;
    const tabName = path.substring(path.lastIndexOf('/')+1);
    const selectedTab = this.props.tabs && this.props.tabs.find((tab, index, obj) => {
      const tabPath = tab.target.substring(tab.target.lastIndexOf('/')+1);
      if(tabPath===tabName)return tab;
      return null;
    });
    if(selectedTab) {
      this.setState({selectedTab: selectedTab.id});
    }
  }

  componentDidMount() {
    this.calculatePath();
  }

  render() {
    const cn = classNames(
      'tab-router',
      'flex-column',
      'w-100',
      this.props.className);
    const cnLinks = classNames(
      'tab-router-links',
      'flex-row',
      {'center':this.props.layout===TAB_ROUTER_LAYOUT.CENTER},
      {'even':this.props.layout===TAB_ROUTER_LAYOUT.EVEN},
      {'spread':this.props.layout===TAB_ROUTER_LAYOUT.SPREAD},
      'w-100', this.props.classNameLinks);
    const cnContent = classNames('tab-router-content', 'center', this.props.classNameContent)
    const {tabs} = this.props;
    return (
      <div className={cn}>
        <div className={cnLinks}>{tabs && tabs.map(
          (tab, index) => {
            return <TabRouterLink
              key={`tab-${tab.id}-${index}`}
              selected={tab.id===this.state.selectedTab}
              onSelectTab={(tabId)=>this.setState({selectedTab:tabId})}
              first={index===0}
              last={index===tabs.length-1}
              {...tab}/>
          }
        )}
        </div>
        <div className={cnContent}> {this.props.children}</div>
      </div>
    );
  }
}

