import React, {useState} from "react";
import AutoComplete from 'react-autocomplete';
import {ICONS_LIST,IIcons} from "./icon-constants";
import classNames from 'classnames/bind';

export interface IIconChooserProps {
  value?: IIcons;
  onItemSelect?: (value: any, item: any) => void;
  onFocus?: (e) => void;
}


export const IconChooser = (props: IIconChooserProps) => {
  const [search, setSearch] = useState(props.value?.value);
  const [open, setOpen] = useState(false);
  return (
    <AutoComplete
      value={search}
      items={ICONS_LIST}
      getItemValue={item => item.value}
      inputProps={{onFocus: props.onFocus}}
      renderInput={
        (props) => {
          const cn = classNames('icon',  search,'w-100');
          return <div className={cn} {...props} onClick={(e) => setOpen(!open)}></div>
        }
      }
      wrapperStyle={{ position: 'relative', display: 'inline-block', width:'100%' }}
      renderItem={
        (item, isHighlighted) => {
          const cn = classNames(
            'icon_chooser',
            'icon',
            'my-2',
            'px-4',
            item.value,
            {'selected':isHighlighted},
            {'not-selected':!isHighlighted}
            );
          return <div className={cn}></div>
        }
      }
      // renderMenu={
      //   (items) => {
      //     return <div>{items}</div>;
      //   }
      // }

      onChange={
        (event, value) => {setSearch(value?.value)}
      }
      onSelect={
        value => {
          setSearch(value);
          setOpen(false);
          if(props.onItemSelect)props.onItemSelect(value,value);
        }
      }

      onMenuVisibilityChange={isOpen => setOpen(isOpen)}
      open={open}
    />
  );

}


