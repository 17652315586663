import React, {MutableRefObject, useEffect, useState} from "react";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import {useField, useFormikContext} from "formik";
import {InputComponentRoot} from "./input-component-root";
import {InputComponentWrapper} from "./input-component-wrapper";
import {InputComponentLabelWrapper} from "./input-component-label-wrapper";
import {v4} from 'uuid';
import classNames from 'classnames/bind';
import {getObjectValueFromPath} from "../../../utils/common/object_path";

export interface Status {
  selected?: boolean;
  editable?:boolean;
  value?: any;
  label?: string | JSX.Element | any;
  theme?: string;
  className?: string;
  labelClassName?: string;
  onSelect?: (selected: boolean) => void;
};

const StatusElement = (props: Status) => {
  const [sel, setSel] = useState(props.selected ? true : false);

  const bckg = sel?`background-${props.theme}`:'background-white';

  const elementClass = classNames(
    bckg,
    'border-top-2',
    'border-right-2',
    'border-bottom-2',
    'border-left-2',
    `border-left-color-${props.theme}`,
    `border-top-color-${props.theme}`,
    `border-right-color-${props.theme}`,
    `border-bottom-color-${props.theme}`,
    'mx-1',
    'p-1',
    'rounded',
    {'color-white': sel},
    {'color-black': !sel},
    'fw-bold',
    'shadow'
  );

  return (
    <div className={props.className?props.className:elementClass} onClick={
      e => {
        if(!props.editable){return;}
        setSel(!sel);
        if (props.onSelect) {
          props.onSelect(!sel);
        }
      }
    }>
      <div className={props.labelClassName}>{props.label}</div>
    </div>
  );
}


export interface ISelectionComponentStatusProps {
  id: string;
  name: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;

  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  editable?: boolean;
  info?: any;

  options: Status[];

  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?: boolean;
  /** Used as value if wrapInForm === false*/
  value?: any;

  styleClassWrapper?: string;
  styleClassRoot?: string;
  styleClassLabel?: string;
  styleClassInput?: string;
  styleClassElement?: string;
  styleClassError?: string;
  styleClassIcon?: string;
  styleClassInfo?: string;
};


export const InputComponentStatus = React.forwardRef((props: ISelectionComponentStatusProps, ref: MutableRefObject<any>) => {
  // Return empty if not visible
  if (!props.visible) return <></>;
  // If wrapi in form, fetch fields
  const [field, meta, helpers] = props.wrapInForm ? useField(props.name) : [null, null, null];
  const value = props.wrapInForm ?
    field?.value ? field.value : '' :
    props.value;
  const formik = useFormikContext();
  const [inError, setInError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.wrapInForm && meta?.error && meta?.touched) {
      setInError(true);
      // @ts-ignore
      setError(meta.error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm, meta, meta?.error, meta?.touched]);

  useEffect(() => {
    if(!formik){return;}const error = getObjectValueFromPath(props.name, formik.errors);
    if (props.wrapInForm && formik.errors && error) {
      setInError(true);
      // @ts-ignore
      setError(error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm,formik, formik?.errors]);

  const [selected, setSelected] = useState(null);

  useEffect(()=>{setSelected(value)},[value]);

  return (
    <InputComponentWrapper showBackground={props.showBackground} styleClass={props.styleClassWrapper}>
      <InputComponentRoot styleClass={props.styleClassRoot}>
        <InputComponentLabelWrapper
          required={props.required}
          label={props.label}
          styleClass={props.styleClassLabel}
        />
        <div className={"d-flex flex-row flex-wrap"}>
          {props.options && props.options.map(status => <StatusElement key={v4()} {...status}
                                                                       selected={selected === status.value}
                                                                       editable={props.editable}
                                                                       onSelect={(sel)=>{
                                                                         if(!props.editable){return;}
                                                                         setSelected(status.value);
                                                                         if(props.wrapInForm && sel){
                                                                           helpers?.setValue(status.value);
                                                                         }
                                                                         if(status.onSelect){status.onSelect(sel);}
                                                                       }}
          />)}
        </div>
      </InputComponentRoot>
    </InputComponentWrapper>
  );
});


InputComponentStatus.defaultProps = {
  showBackground: false,
  required: false,
  visible: true,
  editable: true,
  wrapInForm: true
}
