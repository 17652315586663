import React, {MutableRefObject, useState} from "react";
import {useField} from "formik";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";

const moment = momentImported;
import {DateRangePicker} from 'react-dates';
import * as momentImported from "moment";
import {INPUT_COMPONENT_TEXT_INPUT_TYPE} from "./input-component-text";
import {InputComponent} from "./input-component";
import {Storage} from "../../../utils";
import NumberFormat from "react-number-format";
import {Empty} from "./input-component-empty";

export interface IInputComponentRangeProps {
  idStart: string;
  nameStart: string;
  idEnd: string;
  nameEnd: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;

  disabled?: boolean;
  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  info?: string;

  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?:boolean;
  /** Used as value for start if wrapInForm === false*/
  valueStart?:any;
  /** Used as value for end if wrapInForm === false*/
  valueEnd?:any;
  /**If saveFormState.*/
  formId?:string;
  /**Does the state of this field is saved*/
  saveFormState?:boolean;

  editable?:boolean;

  startDatePlaceholderText?: string;
  endDatePlaceholderText?: string;
  showClearDates?: boolean;

  dateDisplayFormat?: string;
  numberOfMonthsToDisplay?: number;

  isDayBlocked?: (day) => boolean;
  isOutsideRange?: (day) => boolean;
  isDayHighlighted?: (day) => boolean;
  focusedInput?: string; // PropTypes.oneOf([START_DATE, END_DATE]) or null,

  onDatesChange?: (start: Date, end: Date) => void;
  onSelectionFinished?:()=>void;
}

export const InputComponentRange = React.forwardRef((props: IInputComponentRangeProps, ref: MutableRefObject<any>) => {
  const [inFocus, setInFocus] = useState(false);
  const [focusedInput, setFocusedInput] = useState('START_DATE');

  const [fieldStart, metaStart, helperStart] = props.wrapInForm?useField(props.nameStart):[null, null, null];
  const [fieldEnd, metaEnd, helperEnd] = props.wrapInForm?useField(props.nameEnd):[null, null, null];

  const valueStart = props.wrapInForm ? typeof fieldStart?.value === 'string' ? moment(fieldStart?.value) : fieldStart?.value instanceof String ? moment(fieldStart?.value.toString()) : fieldStart?.value:props.valueStart;
  const valueEnd = props.wrapInForm ? typeof fieldEnd?.value === 'string' ? moment(fieldEnd?.value) : fieldEnd?.value instanceof String ? moment(fieldEnd?.value.toString()) : fieldEnd?.value : props.valueEnd;
  return (
    <InputComponent {...props} focused={inFocus}>
      <DateRangePicker
        startDate={valueStart}
        startDateId={props.idStart}
        endDate={valueEnd}
        endDataId={props.idEnd}
        focusedInput={focusedInput}
        disabled={!props.editable}
        noBorder={true}
        block={true}
        enableOutsideDays={true}
        showClearDates={props.showClearDates}
        showDefaultInputIcon={false}
        displayFormat={props.dateDisplayFormat}
        hideKeyboardShortcutsPanel={true}
        numberOfMonths={props.numberOfMonthsToDisplay}

        isDayBlocked={props.isDayBlocked}
        isOutsideRange={props.isOutsideRange}
        isDayHighlighted={props.isDayHighlighted}

        onDatesChange={
          (dates) => {
            if(props.wrapInForm){
              helperStart?.setValue(dates.startDate);
              helperEnd?.setValue(dates.endDate);
            }
            if (props.onDatesChange) props.onDatesChange(dates.startDate, dates.endDate);
            if(props.saveFormState&&props.formId){
              const entity = {
                ...Storage.local.get(props.formId),
              };
              entity[props.nameStart] = dates.startDate;
              entity[props.nameEnd] = dates.endDate;
              Storage.local.set(props.formId, entity);
            }
          }
        }
        onFocusChange={
          (fu) => {
            setFocusedInput(fu);
            if (!fu) {
              setInFocus(false);
              if(props.onSelectionFinished)props.onSelectionFinished();
            } else {
              setInFocus(true);
            }
          }}
      />
    </InputComponent>
  );
});

InputComponentRange.defaultProps = {
  showBackground: true,
  showClearDates: false,
  disabled: false,
  required: false,
  wrapInForm: true,
  visible: true,
  editable: true,
  isDayBlocked: day => false,
  isOutsideRange: day => false
}
