import React, {MutableRefObject, useState} from "react";
import {useField} from "formik";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import {
  createInputTextClass
} from "../utils/create_styles";
import {InputComponent} from "./input-component";
import {Storage} from "../../../utils";
import {Empty} from "./input-component-empty";
import {Editor} from 'primereact/editor';
import {InputComponentRoot} from "./input-component-root";
import {InputComponentWrapper} from "./input-component-wrapper";
import {InputComponentLabelWrapper} from "./input-component-label-wrapper";

export interface IInputComponentEditorProps {
  id: string;
  name: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;
 editorStyle?:object;
  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?: boolean;
  /** Used as value if wrapInForm === false*/
  value?: any;
  /**If saveFormState.*/
  formId?: string;
  /**Does the state of this field is saved*/
  saveFormState?: boolean;

  placeholder?: string;
  readonly?: boolean;
  modules?: any;
  formats?: any[];
  theme?: string;
  headerTemplate?: JSX.Element | undefined,

  editable?: boolean;

  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  info?: any;

  onChange?: (e) => void;
  onBlur?: (e) => void;
  onFocus?: (e) => void;
}


export const InputComponentEditor = React.forwardRef((props: IInputComponentEditorProps, ref: MutableRefObject<any>) => {
  if (!props.visible) return null;
  const [field, meta, helpers] = props.wrapInForm ? useField(props.name) : [null, null, null];
  const [inFocus, setInFocus] = useState(false);
  const inError = props.wrapInForm ? (meta?.error !== undefined && meta?.touched) : false;

  return (
    <InputComponentWrapper showBackground={props.showBackground}>
      <InputComponentRoot>
        <InputComponentLabelWrapper required={props.required} label={props.label}/>
        {props.editable ?
          <Editor
            {...props}
            style={props.editorStyle}
            value={props.wrapInForm ? field?.value : props.value}
            onTextChange={
              (e) => {
                helpers?.setValue(e.htmlValue);
              }
            }

          />
          :
          <section dangerouslySetInnerHTML={{__html: props.wrapInForm ? field?.value : props.value}}></section>
        }

      </InputComponentRoot>
    </InputComponentWrapper>
  );
});

InputComponentEditor.defaultProps = {
  showBackground: false,
  required: false,
  visible: true,
  wrapInForm: true,
  editable: true
}

