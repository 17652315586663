import React from "react";
import classNames from 'classnames/bind';
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SideBar} from "../../layouts";
export interface INavigationRowProps{
  icon?:string|string[];
  faIcon:boolean;
  label:string;
  target:string;
  last?:boolean;
  visible?: boolean;
}

export const NavigationRow = (props:INavigationRowProps) => {
  const linkClass = classNames('functionality-link d-flex flex-row justify-content-start align-items-center pt-1 pb-1', {'last': props.last});

  const iconClass = props.faIcon?
    classNames('icon','ms-1'):
    classNames('icon','ms-1', {'icon-next': !props.icon}, props.icon)
  ;
  const iconEl = props.faIcon?
    // @ts-ignore
  <FontAwesomeIcon className={iconClass} icon={[].concat(props.icon)}></FontAwesomeIcon>:
    <div className={iconClass}></div>;


  const labelClass = classNames('label');
  return (
    props.visible?<Link to={props.target} className={"w-100"}>
      <div className={linkClass}>
        {iconEl}
        <div className={labelClass}>{props.label}</div>
      </div>
    </Link>:<></>
  );
};

NavigationRow.defaultProps = {
  visible: true
}

