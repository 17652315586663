import React, {useState} from 'react';
import classNames from 'classnames/bind';

import {ACTION_TYPE, ActionButton, IButtonProps, BUTTON_SHAPE} from "../../components";

export const CARD_IMPORTANCE = {
  HIGH: 'high',
  MID: 'mid',
  LOW: 'low',
  NONE: 'none'
};

export interface ICardProps {
  importance?: string;
  title?: string;

  showSideSpace: boolean;

  rootClassName?: string;
  rootClassNameAppend?:boolean;
  cardClassName?: string;
  cardClassNameAppend?:boolean;
  headerClassNameAppend?:boolean;
  headerClassName?:string;
  actionsClassNameAppend?:boolean;
  actionsClassName?: string;
  titleClassNameAppend?:boolean;
  titleClassName?: string;
  sideButtonClassNameAppend?:boolean;
  sideButtonClassName?:string;
  containerClassName?:string;
  containerClassNameAppend?:boolean;
  contentClassName?:string;
  contentClassNameAppend?:boolean;
  sideSpaceClassName?:string;
  sideSpaceClassNameAppend?:boolean;


  backgroundClassName?:string;
  colorClassName?:string;

  showSideButton: boolean;
  titleBordered: boolean;
  sideButtonBordered: boolean;
  actionsBordered: boolean;

  sideButtonLabel?: string;
  sideButtonIcon?: string|string[];
  sideButtonFaIcon?:boolean;
  sideButtonIconClassName?:string;
  sideButtonBackgroundClassName?: string;
  sideButtonColorClassName?: string;

  actions?: IButtonProps[];
  content: any;
  onSideAction?: (e) => void;
};

export const Card = (props: ICardProps) => {

  const cardClass = props.rootClassNameAppend?classNames(
    'card-ui',
    'm-2',
    props.rootClassName
  ):props.rootClassName;

  const cardWrapperClass = props.cardClassNameAppend?classNames(
    'card-wrapper',
    'py-2',
    'ps-3',
    props.cardClassName,
    {'default_background': !props.backgroundClassName},
    {'default_color': !props.colorClassName},
    props.backgroundClassName,
    props.colorClassName,
    {'imp-high': props.importance === CARD_IMPORTANCE.HIGH},
    {'imp-mid': props.importance === CARD_IMPORTANCE.MID},
    {'imp-low': props.importance === CARD_IMPORTANCE.LOW}
  ):props.cardClassName;

  const headerClass = props.headerClassNameAppend?classNames(
    'header',
    'row',
    'justify-content-between',
    'px-2',
    'py-1',
    'no-gutters',
    props.headerClassName
  ):props.headerClassName;
  const titleWrapperClass = props.titleClassNameAppend?classNames(
    'header-title',
    'order-0',
    'col',
    'pb-1',
    'd-flex',
    'justify-content-start',
    'align-items-end',
    {'bordered': props.titleBordered},
    props.titleClassName
  ):props.titleClassName;
  const actionsWrapperClass = props.actionsClassNameAppend?classNames(
    'actions',
    'order-1',
    'col',
    'pb-1',
    'd-flex',
    'justify-content-end',
    {'bordered': props.actionsBordered},
    props.actionsClassName
  ):props.actionsClassName;
  const sideButtonClass = props.sideButtonClassNameAppend?classNames(
    'side-button',
    'order-2',
    'col',
    'pb-1',
    {'bordered': props.sideButtonBordered},
    props.sideButtonClassName
  ):props.sideButtonClassName;

  const actions = props.actions && props.actions.map((act, index) => {
    const cn = classNames('', act.styleRoot)
    return <ActionButton key={`btn-${act.label}-${index}`} {...act} styleRootAppend={true} styleRoot={cn}/>
  });

  const header = (
    <div className={headerClass}>
      {props.title ?
        <div className={titleWrapperClass}>
          {props.title}
        </div> : null
      }
      {
        props.actions ?
          <div className={actionsWrapperClass}>{actions}</div> :
          <></>
      }
      {
        props.showSideButton ?
          <div className={sideButtonClass}>
            <ActionButton
              type={ACTION_TYPE.CUSTOM}
              shape={BUTTON_SHAPE.ROUNDED}
              icon={props.sideButtonIcon}
              faIcon={props.sideButtonFaIcon}
              showLabel={props.sideButtonLabel ? true : false}
              styleIcon={props.sideButtonIconClassName}
              label={props.sideButtonLabel}
              command={props.onSideAction}
              background={props.sideButtonBackgroundClassName}
              color={props.sideButtonColorClassName}
            />
          </div> :
          <></>
      }
    </div>

  );

  //Content
  const contentContainerClass = props.containerClassName?classNames(
    'content',
    'row',
    'px-2',
    'py-1',
    'no-gutters',
    props.containerClassName
  ):props.containerClassName;
  const contentClass = props.contentClassNameAppend?classNames(
    'col',
    props.contentClassName
  ):props.contentClassName;
  const sideSpaceClass = props.sideSpaceClassNameAppend?classNames(
    'side-space',
    'col',
    {'d-none': !props.showSideSpace},
    props.sideSpaceClassName
  ):props.sideSpaceClassName;


  const content = (
    <div className={contentContainerClass}>
      <div className={contentClass}>
        {props.content}
      </div>
      {props.showSideSpace ? <div className={sideSpaceClass}></div> : <></>}
    </div>
  );


  return (
    <div className={cardClass}>
      <div className={cardWrapperClass}>
        {header}
        {content}
      </div>
    </div>
  );
};

Card.defaultProps = {

  showSideButton: false,
  showSideSpace: false,
  sideButtonIcon: 'icon-save',
  importance: CARD_IMPORTANCE.NONE,
  titleBordered: true,
  sideButtonBordered: true,
  actionsBordered: true,

  rootClassNameAppend:true,
  cardClassNameAppend:true,
  headerClassNameAppend:true,
  actionsClassNameAppend:true,
  titleClassNameAppend:true,
  sideButtonClassNameAppend:true,
  containerClassNameAppend:true,
  contentClassNameAppend:true,
  sideSpaceClassNameAppend:true

}
