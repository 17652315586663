import React from "react";
import classNames from "classNames/bind";

interface IFlatTableDataCellProps {
  type?: "end"|"center"|"begin"|"custom";
  className?:string;
  showBorder?:boolean;
  gridLayout?: boolean;
}
/**
 *
 */
export class FlatTableDataCell extends React.Component<IFlatTableDataCellProps>  {
  static defaultProps = {
    type: "center",
    showBorder: true,
    gridLayout: true
  };
  constructor(props) {
    super(props);
  }

  calculateClassName = ()=>{
    switch (this.props.type){
      case "custom":
        return this.props.className;
      case "center":
        return classNames({'col':this.props.gridLayout}, 'align-items-stretch align-self-center justify-content-end px-2 text-center',{"border-left-color-silver border-left-1":this.props.showBorder}, this.props.className);
      case "begin":
        return classNames({'col':this.props.gridLayout}, 'align-items-stretch align-self-center justify-content-end px-2 text-begin',{"border-left-color-silver border-left-1":this.props.showBorder}, this.props.className);
      case "end":
      default:
        return classNames({'col':this.props.gridLayout}, 'align-items-stretch align-self-center justify-content-end px-2 text-end',{"border-left-color-silver border-left-1":this.props.showBorder}, this.props.className);
    }
  }

  render() {
    return <div {...this.props} className={this.calculateClassName()}>
      {this.props.children}
    </div>;
  }
};

