import React from "react";
import {FONT_WEIGHT, TemplateProps, TEXT_POSITION, TEXT_TRANSFORM} from "./template-props";
import {calculateRootClassName} from "./template-styles";
import {calculateIconElement} from "./template-elements";
import {DataTableColumn} from "../data-table/data-table-column";

export const defaultTemplateIconCenter: TemplateProps = {
  position: TEXT_POSITION.CENTER,
  truncate: true,
  fontWeight: FONT_WEIGHT.NORMAL,
  textTransform: TEXT_TRANSFORM.NONE,
  iconOnHover: false
};

export const dataTableItemTemplateIcon = (column:DataTableColumn, rowData:any, rowIndex:number, colIndex:number, columnCount:number, props?:TemplateProps)=>{
  const pr = props ? props : defaultTemplateIconCenter;
  return (
    <div className={calculateRootClassName(pr)}>
      {calculateIconElement(rowData[column.field])}
    </div>
  );
}
