import React from "react";
import classNames from 'classnames/bind';

const createWrapper = ( classnames, children ) => {
  const cn = classNames(classnames);
  return (
    <div className={cn}>
      {children}
    </div>
  );
};

export default createWrapper;
