import React from "react";
import classNames from "classNames/bind";

interface IFlatTableDataProps {
  className?: string;
  appendClassName?: boolean;
  showBorder?:boolean;
  gridLayout?: boolean;
}

/**
 *
 */
export class FlatTableData extends React.Component<IFlatTableDataProps> {
  static defaultProps = {
    className: null,
    appendClassName: true,
    showBorder: false,
    gridLayout: true
  };

  render() {
    const cn = this.props.className && !this.props.appendClassName? this.props.className:
      classNames('pb-2','mb-2', {'border-bottom-2 border-bottom-color-brown-grey-two':this.props.showBorder}, this.props.className);
    return (
      <div className={cn}>
        {this.props.children}
      </div>
    );
  }

};

