import React from "react";
import classNames from "classNames/bind";

export interface IFlatTableHeaderProps{
  gridLayout?: boolean;
  className?:string;
}
/**
 *
 */
export class FlatTableHeader extends React.Component<IFlatTableHeaderProps> {
static defaultProps = {
  gridLayout: true
}
  render() {
    const row = classNames({'row g-0':this.props.gridLayout}, this.props.className,'py-2', 'border-bottom-2', 'border-bottom-color-brown-grey-two');
    return (
      <div {...this.props} className={row}>
        {this.props.children}
      </div>
    );
  }
};


