import React from "react";
import moment from "moment";
import {FONT_WEIGHT, TemplateProps, TEXT_POSITION, TEXT_TRANSFORM} from "./template-props";
import {calculateRootClassName} from "./template-styles";
import {calculateIcon, calculateLabel} from "./template-elements";
import {DataTableColumn} from "../data-table/data-table-column";

export const defaultTemplateDate:TemplateProps = {
  position: TEXT_POSITION.CENTER,
  truncate: true,
  fontWeight: FONT_WEIGHT.NORMAL,
  textTransform: TEXT_TRANSFORM.NONE,
  iconOnHover: false,
  dateFormat: 'DD.MM.YYYY'
}

export const dataTableItemTemplateDate = (column:DataTableColumn, rowData:any, rowIndex:number, colIndex:number, columnCount:number, dateFormat:string,props?:TemplateProps) => {
  const pr = props ? props : defaultTemplateDate;
  if(rowData[column.field]===null){
    return (
      <div className={calculateRootClassName(pr)}>
        {calculateIcon(pr)}
        {calculateLabel(rowData[column.field], pr)}
      </div>
    );
  }
  return (
    <div className={calculateRootClassName(pr)}>
      {calculateIcon(pr)}
      {calculateLabel(moment(rowData[column.field]).format(dateFormat), pr)}
    </div>
  );
}
