import React from "react";
import {Link} from 'react-router-dom';
import {FONT_WEIGHT, TemplateProps, TEXT_POSITION, TEXT_TRANSFORM} from "./template-props";
import {calculateRootClassName} from "./template-styles";
import {calculateIcon, calculateLabel} from "./template-elements";
import {DataTableColumn} from "../data-table/data-table-column";

export const defaultTemplateStringFK: TemplateProps = {
  position: TEXT_POSITION.LEFT,
  truncate: true,
  fontWeight: FONT_WEIGHT.BOLD,
  textTransform: TEXT_TRANSFORM.NONE,
  icon: 'icon-next',
  iconOnHover: true
}

export const dataTableItemTemplateFK = (column:DataTableColumn, rowData:any, rowIndex:number, colIndex:number, columnCount:number,
                           fkValueExtractFunction: (rowData: any, column:any) => any,
                           target: (rowData: any, column:any) => string,
                           props?: TemplateProps) => {
  const pr = props ? props : defaultTemplateStringFK;
  return (
    <Link to={target(rowData, column)} className={'fk-table-link'}>
      <div className={calculateRootClassName(pr)}>
        {calculateIcon(pr)}
        {calculateLabel(fkValueExtractFunction(rowData,column), pr)}
      </div>
    </Link>
  );
};

