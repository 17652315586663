import React, {useEffect, useState} from "react";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import {InputComponent} from "./input-component";
import {useField, useFormikContext} from "formik";
import {getObjectValueFromPath} from "../../../utils/common/object_path";
import {Empty} from "./input-component-empty";
import {useCombobox} from 'downshift'
import {createInputTextClass} from "../utils/create_styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {v4} from "uuid";

export interface IInputComponentAutocompleteProps {
  id: string;
  name: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;

  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  editable?: boolean;
  info?: any;
  loading?: boolean;

  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?: boolean;
  /** Used as value if wrapInForm === false*/
  value?: any;
  //
  loadOptions: (inputValue) => Promise<any[] | any> | any[] | any;
  onBlur?: (e) => void;
  onFocus?: (e) => void;

  getOptionValue?: (option) => any;
  getOptionLabel?: (option) => any;
  onChangeOption?: (option) => void;

  onInputChange?: (inputValue: any) => boolean | void;
  onCreate?: (inputValue: any) => boolean | void;

  styleClassWrapper?: string;
  styleClassRoot?: string;
  styleClassLabel?: string;
  styleClassInput?: string;
  styleClassElement?: string;
  styleClassError?: string;
  styleClassIcon?: string;
  styleClassInfo?: string;

};

/**
 *
 */
export const InputComponentAutocomplete = React.forwardRef((props: IInputComponentAutocompleteProps, ref) => {
  // Return empty if not visible
  if (!props.visible) return <></>;
  const formik = useFormikContext();
  // visual state
  const [inFocus, setInFocus] = useState(false);
  const [inError, setInError] = useState(false);
  const [error, setError] = useState(null);
  // drop down possible values
  const [initialValue, setInitialValue] = useState<any>(null);
  const [possibleValues, setPossibleValues] = useState<Array<any>>([]);
  //
  // If wrap in form, fetch fields
  const [field, meta, helpers] = props.wrapInForm ? useField(props.name) : [null, null, null];
  /*
   *
   */
  /*
   * Error handling
   */
  useEffect(() => {
    if (props.wrapInForm && meta?.error && meta?.touched) {
      setInError(true);
      // @ts-ignore
      setError(meta.error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm, meta, meta?.error, meta?.touched]);

  useEffect(() => {
    if (!formik) {
      return;
    }
    const error = getObjectValueFromPath(props.name, formik.errors);
    //const touched = getObjectValueFromPath(props.name, formik.touched);
    if (props.wrapInForm && formik.errors && error) {
      setInError(true);
      // @ts-ignore
      setError(error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm, formik, formik?.errors]);

  const handleInputChange = async (searchValue: string | undefined) => {
    const val = await props.loadOptions(searchValue);
    if (val) {
      setPossibleValues(val);
    }
  };
  const parseValue = (item) => {
    if (props.getOptionLabel) {
      return props.getOptionLabel(item);
    }
    return `${item}`
  }

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    reset
  } = useCombobox({
    items: possibleValues,
    inputValue: initialValue,
    onInputValueChange: (inputvalue) => {
      handleInputChange(inputvalue.inputValue);
    },
    itemToString: item => parseValue(item),
    stateReducer: (state, actionAndChanges) => {
      const {type, changes} = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange: {
          setInitialValue(changes.inputValue);
          return {
            ...changes
          };
        }
        default:
          return changes // otherwise business as usual.
      }
    }
  });

  useEffect(() => {
    setInitialValue(parseValue(props.wrapInForm ? field?.value ? field.value : '' : props.value));
  }, [props.wrapInForm, field?.value, props.value]);


  useEffect(() => {
    if (selectedItem) {
      let value = selectedItem;
      if (props.getOptionValue) value = props.getOptionValue(selectedItem);

      if (props.onChangeOption) {
        props.onChangeOption(selectedItem)
      }
      if (props.wrapInForm) {
        helpers?.setValue(value);
      }
    }
  }, [selectedItem]);

  return (
    // @ts-ignore
    <InputComponent {...props} error={error} focused={inFocus}>
      {props.editable ? (
          <div className={"w-100"}>
            <div {...getComboboxProps({}, {suppressRefError: true})}
                 className={"position-relative d-inline-flex w-100 flex-row"}>
              <input
                {...getInputProps({}, {suppressRefError: true})}
                type={"text"}
                className={createInputTextClass(props.required, inError)}
                ref={ref}
                onFocus={(e) => {
                  setInFocus(true);
                  if (props.onFocus) {
                    props.onFocus(e);
                  }
                }}
                onBlur={(e) => {
                  setInFocus(false);
                  if (props.onBlur) props.onBlur(e)
                  field?.onBlur(e);
                }}
              />
              <button type="button" {...getToggleButtonProps()} aria-label="toggle menu"
                      className={"color-blue-grey-darken fs-5 fw-bold px-2"}>
                <FontAwesomeIcon icon={["far", "angle-down"]}/>
              </button>
            </div>
            {isOpen && possibleValues && possibleValues.length > 0 ?
              <ul {...getMenuProps({}, {suppressRefError: true})} className={"autocomplete-items mt-2"}>
                {possibleValues.map((item, index) => (
                  <li
                    className={""}
                    key={v4()}
                    {...getItemProps({item, index})}
                  >
                    <div className={highlightedIndex === index ? "item-active" : ""}>{parseValue(item)}</div>
                  </li>
                ))}
              </ul> : <></>}
          </div>
        ) :
        <Empty required={props.required} inError={inError}
          // @ts-ignore
               value={parseValue(props.wrapInForm ? field?.value ? field.value : '' : props.value)}/>
      }
    </InputComponent>
  );
});

InputComponentAutocomplete.defaultProps = {
  showBackground: false,
  required: false,
  visible: true,
  editable: true,
  wrapInForm: true,
  loading: false,
  getOptionValue: (value) => value,
  getOptionLabel: (label) => label,
}
