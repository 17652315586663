import React, {MutableRefObject, useEffect, useState} from "react";
import {useField} from "formik";
import {DEFAULT_INPUT_ICONS, DEFAULT_LIST_ITEM_RENDER} from "../input_element_contstants";
import {InputComponent} from "./input-component";
import {AutoComplete} from "primereact/autocomplete";
import NumberFormat from "react-number-format";
import {Empty} from "./input-component-empty";


export interface IInputComponentListProps {
  id: string;
  name: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;

  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  editable?:boolean;
  info?:any;

  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?:boolean;
  /** Used as value if wrapInForm === false*/
  value?:any;

  suggestions?: any[];

  parseItemValue: (item:any)=>any;
  /**
   * True if value is extracted (with item template) before save
   */
  parseBeforeItemSelect?:boolean;
  beforeSelectItemTemplate?: (item:any)=>{};
  itemTemplate?: (item:any)=> JSX.Element;
  selectedItemTemplate?: (item:any)=>JSX.Element;


  onChange?: (e) => void;
  onBlur?: (e) => void;
  onFocus?: (e) => void;

  onListSearch?: (event: Event, value: any) => void;
  onItemSelect?: (value: any, item: any) => void;

  styleClassWrapper?:string;
  styleClassRoot?:string;
  styleClassLabel?:string;
  styleClassInput?:string;
  styleClassElement?:string;
  styleClassError?:string;
  styleClassIcon?:string;
  styleClassInfo?:string;
}

export const InputComponentList = React.forwardRef((props: IInputComponentListProps, ref:MutableRefObject<any>) => {
  if(!props.visible)return null;
  const [field, meta, helpers] = props.wrapInForm ? useField(props.name) : [null,null,null];
  const [inFocus, setInFocus] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inError = props.wrapInForm ? (meta?.error !== undefined && meta?.touched) : false;

  useEffect(() => {
    const val = props.parseItemValue(props.wrapInForm?field?.value:props.value);
    setInputValue(!val && val === undefined ? '' : val.toString());
    },
    [field?.value, props.value]);

  const itemTemplate = props.itemTemplate?props.itemTemplate: (item) => { return DEFAULT_LIST_ITEM_RENDER(item,false, it=>props.parseItemValue(it)) }
  return (
    <InputComponent {...props} focused={inFocus}>
      {props.editable?<AutoComplete
        id={props.id}
        ref={ref}
        dropdown={true}
        value={inputValue}

        onChange={
          (e) => {
            if (typeof e.value === "string") {
              setInputValue(e.value);
            }
          }
        }
        onSelect={(e) => {
          if(props.onItemSelect)props.onItemSelect(e.originalEvent, e.value);
          helpers?.setValue(
            props.parseBeforeItemSelect?
              props.beforeSelectItemTemplate?
                props.beforeSelectItemTemplate(e.value):
                itemTemplate(e.value):
              e.value
          );
        }}
        suggestions={props.suggestions}
        completeMethod={(e) => {
          if (props.onListSearch) props.onListSearch(e.originalEvent, e.query)
        }}
        itemTemplate={itemTemplate}
        selectedItemTemplate={props.selectedItemTemplate}
      />:
        <Empty required={props.required} inError={inError} value={inputValue} ref={ref}/>}
    </InputComponent>
  );
});

InputComponentList.defaultProps = {
  showBackground: false,
  required: false,
  visible: true,
  editable: true,
  wrapInForm: true,
  parseBeforeItemSelect: false,
  parseItemValue: (item)=>item
}
