import React from "react";
import classNames from 'classnames/bind';

export interface IPaginationLinkProps {
  next: boolean;
  previous: boolean;
  first: boolean;
  last: boolean;
  className?: string;
  onClick?: (e)=>void;
}

export class PaginationLink extends React.Component<IPaginationLinkProps>{
  static defaultProps={
    first: false,
    last: false,
    next: false,
    previous: false
  }
  render() {
    return <div className={classNames('pagination-link', classNames)} onClick={this.props.onClick}>{this.props.children}</div>
  }
}

