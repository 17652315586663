import React from "react";
import classNames from 'classnames/bind';

export interface IPaginationItemProps {
  className?:string;
  disabled?:boolean;
  active?:boolean;
}

class PaginationItem extends React.Component<IPaginationItemProps> {
  static defaultProps={
    disabled: false
  }
  render() {
    return (
      <li className={classNames('pagination-item', this.props.className, {'active':this.props.active})}>
        {this.props.children}
      </li>
    );
  }
}

export default PaginationItem;

