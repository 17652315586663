import React from "react";


export type User = {
  username?:string,
  name?:string,
  firstName?:string;
  lastName?:string;
  orgUnit?:string|JSX.Element,
  orgUnitData?:{},
  company?:string|JSX.Element,
  avatar?:{
    data?:string
  }
};

const defaultUser:User = {
  username: 'jd',
  name: 'John Doe',
  firstName: 'John',
  lastName: 'Doe',
  orgUnit: 'Tester',
  company: 'Company Test',

};

export const UserContext = React.createContext(defaultUser);
