import React, {useContext, useState} from "react";
import classNames from 'classnames/bind';
import {UserContext} from "../../utils/auth/User";
import onClickOutside from "react-onclickoutside";
import {BrowserRouter as Router, Link} from "react-router-dom";
import {MenuItem} from "primereact/api";
import {InputText} from "primereact/inputtext";
import _ from 'lodash';

export const SettingsMenuContext = React.createContext({
  showMenu: false, setShowMenu: (showMenu: boolean) => {
  }
});
export const ProfileMenuContext = React.createContext({
  showProfile: false, setShowProfile: (showProfile: boolean) => {
  }
});

export interface IMainLayoutProps {
  showSideBar?: boolean;
  showProfile?: boolean;
  showSettings?: boolean;
  profileLabel?: string;
  profileLink?: string;
  preferencesLabel?: string;
  preferencesLink?: string;
  helpLabel?: string;
  helpLink?: string;
  appLogo: () => {};
  titleElement: JSX.Element;
  sideBarElement?: JSX.Element;
  content: () => {};
  logoutLabel?: string;
  logoutLink?: string;
  settingsTitle?: string;
  settingsSearchShow?: boolean;
  settingsSearchTitle?: string;
  settingsOnSearch?: (search: string, menuItems: MenuItem) => {}
  settingsMenu?: MenuItem[];
}

const AppLogo = (props: IMainLayoutProps) => {
  const appLogoStyle = classNames(
    'col-5'
  );
  return (
    <div className={appLogoStyle}>
      <Link to={"/"} className={"w-100"}>
        {props.appLogo ? props.appLogo() : <></>}
      </Link>
    </div>

  );
};

const Title = ({titleElement}) => {
  const appTitleStyle = classNames(
    'col-12'
  );
  return (<div className={appTitleStyle}>
      {titleElement}
    </div>
  );
};
//onClickOutside(,
//   {handleClickOutside: () => UserTitle.handleClickOutside}
// );
const UserTitle = ({showProfile, setShowProfile}) => {
  const userTitleStyle = classNames(
    'col-5',
    'main-layout-header-user-title'
  );
  // UserTitle.handleClickOutside = () => setShowProfile(false);

  return (
    <UserContext.Consumer>
      {(user) =>
        <div className={userTitleStyle} onClick={(e) => setShowProfile(!showProfile)}>
          <div className={"row justify-content-end align-center"}>
            <div className={"col-20"}>
              <div className={"row"}>
                <div className={"col-24 fw-bold d-flex justify-content-end"}>{user.name}</div>
                <div className={"col-24 d-flex justify-content-end"}>{user.orgUnit}</div>
              </div>
            </div>
            <div className={"col-4 justify-content-center align-self-center no-gutters"}>
              <div className={"rounded-circle background-brown-grey avatar"}></div>
            </div>
          </div>
        </div>}
    </UserContext.Consumer>
  );
};


const SettingsLabel = ({showMenu, setShowMenu}) => {
  const settingsHeaderStyle = classNames('col-2','main-layout-header-settings-title');
  const settingIconStyle = classNames(
    'icon icon-settings',
    'fs-20',
    'd-flex'
  );

  return (
    <div className={settingsHeaderStyle} onClick={(e) => setShowMenu(!showMenu)}>
      <div className={settingIconStyle}></div>
    </div>
  );
};


const Header = (props: IMainLayoutProps) => {
  const headerContainerStyle = classNames(
    'main-layout-header-wrapper',
    'container-fluid'
  );
  const headerContentStyle = classNames(
    'main-layout-header-content',
    'row',
    'align-items-center'
  );

  return (
    <div className={headerContainerStyle}>
      <div className={headerContentStyle}>
        <AppLogo {...props}/>
        <Title titleElement={props.titleElement}/>
        {props.showProfile ?
          <ProfileMenuContext.Consumer>{value => <UserTitle {...value}/>}</ProfileMenuContext.Consumer> :
          <></>}
        {props.showSettings ? <SettingsMenuContext.Consumer>
          {value => <SettingsLabel {...value}/>}
        </SettingsMenuContext.Consumer> : <></>}
      </div>
    </div>
  );
}


const MainLayoutBottomWrapper = (props: IMainLayoutProps) => {
  const containerStyle = classNames(
    'main-layout-bottom-wrapper',
    'container-fluid'
  );
  const rowStyle = classNames(
    'bottom-row',
    'row',
    'justify-content-center'
  );

  return (
    <div className={containerStyle}>
      <div className={rowStyle}>
        <SideBar {...props}/>
        <Content {...props}/>
      </div>
    </div>
  );
};

const Content = (props: IMainLayoutProps) => {
  const cn = classNames(
    'main-layout-content-wrapper',
    {'col-23 col-md-23': props.showSideBar},
    {'col-24 col-md-24': !props.showSideBar},
    'no-gutters'
  );
  return (
    <div className={cn}>
      {props.content()}
    </div>
  )
}

const SideBar = (props: IMainLayoutProps) => {
  const cn = classNames(
    'main-layout-sidebar-wrapper',
    'col-1',
    'col-md-1',
    'no-gutters',
    'p-0'
  );
  //
  return props.showSideBar&&props.sideBarElement ? <div className={cn}>{props.sideBarElement}</div> : <></>;
}

const Profile = onClickOutside(({setShowProfile, profileLink, profileLabel, preferencesLink, preferencesLabel, helpLink, helpLabel, logoutLink, logoutLabel}) => {
  const [search, setSearch] = useState('');

  Profile.handleClickOutside = () => setShowProfile(false);

  return (
    <UserContext.Consumer>
      {user => {
        return (
          <div>
            <div className={"row no-gutters"}>
              <div className={"col-24 username ps-3 pt-2 pe-3"}>{user.username}</div>
            </div>
            <div className={"row no-gutters"}>
              <div className={"col-24 name ps-3 pb-2 pe-3"}>{user.name}</div>
            </div>
            <div className={"row no-gutters "}>
              <div className={"col-24 company ps-3 pt-2 pb-2 me-3 border-top border-bottom"}>{user.company}</div>
            </div>
            <div className={"row no-gutters"}>
              <div className={"col-24 profile ps-3 pt-2 pe-3"}><Link
                to={profileLink}>{profileLabel}</Link></div>
            </div>
            <div className={"row no-gutters"}>
              <div className={"col-24 preferences ps-3 pe-3"}><Link
                to={preferencesLink}>{preferencesLabel}</Link></div>
            </div>
            <div className={"row no-gutters "}>
              <div className={"col-24 help ps-3 pb-2 pe-3 border-bottom"}><Link
                to={helpLink}>{helpLabel}</Link></div>
            </div>
            <div className={"row no-gutters"}>
              <div className={"col-24 signout ps-3 pt-2 pb-2 pe-3"}>
                <Link to={logoutLink}>{logoutLabel}</Link>
              </div>
            </div>
          </div>
        );
      }}
    </UserContext.Consumer>
  );
}, {handleClickOutside: () => Profile.handleClickOutside});


const Settings = onClickOutside(({setShowMenu, settingsTitle, settingsSearchTitle, settingsOnSearch, settingsMenu}) => {
  const [search, setSearch] = useState('');
  const [menu, setMenu] = useState(settingsMenu);

  Settings.handleClickOutside = () => setShowMenu(false);

  return (
    <>
      <div className={"container-fluid"}>
        <div className={"row"}>
          <div className={"settings-title col-24 ps-3 pe-1 pt-3 pb-3 border-bottom"}>{settingsTitle}</div>
        </div>
        <div className={"row "}>
          <div className={"col-24 settings-search"}>
          <span className="p-float-label settings-input">
            <InputText id="settingsSearch" value={search} onChange={
              (e) => {
                setSearch(e.currentTarget.value);
                if (settingsOnSearch) setMenu(settingsOnSearch(e.currentTarget.value, settingsMenu));
              }
            }/>
            <label htmlFor="settingsSearch">{settingsSearchTitle}</label>
          </span>
          </div>
        </div>
        <div className={"row"}>
          <SettingsMenu menuItems={menu as MenuItem[]}></SettingsMenu>
        </div>
      </div>
    </>
  );
}, {handleClickOutside: () => Settings.handleClickOutside});


const SettingsMenu = ({menuItems}) => {
  if (!menuItems) {
    return <></>;
  }
  return (
    <ul className={"settings-menuitems"} key={`outmu`}>
      {
        (menuItems && menuItems.map((menuItem: MenuItem, index: number) => {
              return (menuItem ?
                <SettingsMenuItem key={`smi-${menuItem.label}-${index}`} menuItem={menuItem} ind={index}/> : <></>);
            }
          )
        )
      }
    </ul>
  );
}

const SettingsMenuItem = ({menuItem, ind}) => {
  const [expanded, setExpanded] = useState(false);
  const [children] = useState(menuItem.items);

  const chEl =
    expanded ?
      <ul key={`mu-${menuItem.label}-${ind}`}>
        {children && children.map((child, index) => {
          return <SettingsMenuItem key={`smiin-${menuItem.label}-${index}`} menuItem={child} ind={index}/>
        })
        }
      </ul>
      : <></>;

  const cn = classNames(
    'settings-menuitem',
    {'expanded': expanded},
    menuItem.className
  );
  return (
    <>
      <li key={`mi-${menuItem.label}-${ind}`}
          className={cn}
          onClick={
            (e) => {
              menuItem.command && menuItem.command();
              setExpanded(!expanded);
            }
          }
      >
        {menuItem.target ?
          <Link to={menuItem.target}>{menuItem.label}</Link> :
          menuItem.url ?
            <a href={menuItem.url}>{menuItem.label}</a> :
            <span>{menuItem.label}</span>}
      </li>
      {chEl}
    </>
  );

}

const onSearchAction = (search: string, menuItems: []) => {
  const s = _.upperCase(_.trim(search));
  if (s === '' || s === null) {
    return menuItems
  }

  const mi = menuItems && menuItems.map((menu: MenuItem, index) => {
    if (!menu || !menu.label) {
      return;
    }
    const m = _.upperCase(_.trim(menu.label));
    if (!m || m.indexOf(s) === -1) {
      return;
    }
    return menu;
  });

  return mi;
}


export const MainLayout = (props: IMainLayoutProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const cn = classNames(
    'main-layout-wrapper'
  );
  const settingsMenuStyle = classNames(
    {'d-none': !showMenu},
    {'settings-menu': showMenu}
  );
  const profileMenuStyle = classNames(
    {'d-none': !showProfile},
    {'profile-menu': showProfile}
  );
  return (
    <SettingsMenuContext.Provider value={{showMenu: showMenu, setShowMenu: setShowMenu}}>
      <ProfileMenuContext.Provider value={{showProfile: showProfile, setShowProfile: setShowProfile}}>
        {props.showProfile ?
          <div className={settingsMenuStyle}><Settings setShowMenu={setShowMenu} {...props}/>
          </div> : <></>}
        <div className={profileMenuStyle}><Profile setShowProfile={setShowProfile} {...props}/></div>
        <div className={cn}>
          <Header {...props}/>
          <MainLayoutBottomWrapper {...props}/>
        </div>
      </ProfileMenuContext.Provider>
    </SettingsMenuContext.Provider>
  );
}


MainLayout.defaultProps = {
  showSideBar: true,
  appLogo: () => <div className={"appLogo"}></div>,
  showProfile: true,
  showSettings: true,
  title: 'Title',
  subtitle: 'Subtitle',
  helpLabel: 'Help & Feedback',
  helpLink: '#',
  profileLabel: 'Profile',
  profileLink: '#',
  preferencesLabel: 'Preferences',
  preferencesLink: '#',
  titleElement: (title, subtitle) => (
    <>
      <div className="row no-gutters align-items-center justify-content-center">
        <div className="col title">{title}</div>
      </div>
      <div className="row no-gutters align-items-center justify-content-center">
        <div className="col subtitle">{subtitle}</div>
      </div>
    </>
  ),
  logoutLabel: 'Sign out',
  logoutLink: '/logout',
  settingsTitle: 'Settings',
  settingsSearchShow: true,
  settingsSearchTitle: 'Search settings',
  settingsOnSearch: onSearchAction
}
