import React, {useState} from "react";

import {FormikHelpers} from "formik/dist/types";
import useScript from "../../utils/hooks/useScript";
import {Formik} from "formik";

import classNames from 'classnames/bind';
import * as Yup from 'yup';

import {
  STANDARD_SAVE_SUBMIT_PROPS
} from "../../components/action/action_button_constatns";
import {ActionButton} from "../../components/action/action_button";
import {Captcha} from "primereact/captcha";
import PropTypes from "prop-types";
import {Col, DEFAULT_INPUT_ICONS, Grid, INPUT_ELEMENT_TYPE, InputElement, Row} from "..";


export interface ForgotPasswordData {
  email: string
}

export interface ILoginForgotPassword {
  capchaSiteKey: string;
  labelTitle?: string;
  labelSubtitle?: string;
  labelEmail?: string;
  labelChangePassword?: string;
  initialValues: ForgotPasswordData;
  redirectTo?: string;
  makeRedirectAfterChangeRequest?: boolean;
  onForgotPasswordRequest: (values: ForgotPasswordData, formikHelpers: FormikHelpers<ForgotPasswordData>) => void | Promise<any>
}

export const LoginForgotPassword = (props: ILoginForgotPassword) => {

  useScript('https://www.google.com/recaptcha/api.js?render=explicit');
  const [valid, setValid] = useState(false);
  const formStyle = classNames(
    'login-form'
  );
  return (
    <div className="login-form-container">
      <div className={formStyle}>
        <div className="p-grid">
          <div className="p-col p-justify-start p-align-center login-title">
            {props.labelTitle}
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col p-justify-start p-align-center login-subtitle">
            {props.labelSubtitle}
          </div>
        </div>
        <Formik
          initialValues={props.initialValues}
          onSubmit={props.onForgotPasswordRequest}
          validationSchema={Yup.object({email: Yup.string().email().required('')})}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <Grid>
                <Row>
                  <Col>
                    <InputElement
                      id={"email"}
                      name={"email"}
                      type={INPUT_ELEMENT_TYPE.MAIL}
                      label={props.labelEmail}
                      icon={DEFAULT_INPUT_ICONS.MAIL}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Captcha siteKey={props.capchaSiteKey} onResponse={(e) => setValid(true)}/>
                  </Col>
                </Row>
              </Grid>
              <Row visible={true}>
                <Col classname={"d-flex justify-content-center"}>
                  <ActionButton
                    {...STANDARD_SAVE_SUBMIT_PROPS}
                    styleButton={"w-100"}
                    styleButtonAppend={true}
                    label={props.labelChangePassword}
                  />
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

LoginForgotPassword.defaultProps = {
  labelTitle: 'Welcome to MsvUI',
  labelSubtitle: 'Please enter your e-mail',
  labelEmail: 'e-mail',
  labelChangePassword: 'Send e-mail',
  redirectTo: '/',
  makeRedirectAfterChangeRequest: true
}

LoginForgotPassword.propTypes = {
  initialValues: PropTypes.object,
  labelTitle: PropTypes.string,
  capchaSiteKey: PropTypes.string,
  labelSubtitle: PropTypes.string,
  labelEmail: PropTypes.string,
  redirectTo: PropTypes.string,
  makeRedirectAfterChangeRequest: PropTypes.bool
}




