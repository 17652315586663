import React, {MutableRefObject, useEffect, useState} from "react";
import {useField, useFormikContext} from "formik";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import classNames from 'classnames/bind';
import {InputComponentRoot} from "./input-component-root";
import {InputComponentWrapper} from "./input-component-wrapper";
import {InputComponentLabelBooleanWrapper} from "./input-component-label-boolean-wrapper";
import {Storage} from "../../../utils";
import {getObjectValueFromPath} from "../../../utils/common/object_path";


export interface IInputComponentBooleanProps {
  id: string;
  name: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;
  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  info?:any;

  editable?:boolean;

  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?:boolean;
  /** Used as value if wrapInForm === false*/
  value?:boolean;
  /**If saveFormState.*/
  formId?:string;
  /**Does the state of this field is saved*/
  saveFormState?:boolean;

  onValueChange?: (value: boolean)=>void;

  styleClassWrapper?:string;
  styleClassRoot?:string;
  styleClassLabel?:string;
  styleClassInput?:string;
  styleClassElement?:string;
  styleClassError?:string;
  styleClassIcon?:string;
  styleClassInfo?:string;
}

export const InputComponentBoolean = React.forwardRef((props: IInputComponentBooleanProps, ref:MutableRefObject<any>) => {
  if(!props.visible)return null;
  const [field, meta, helpers] = props.wrapInForm?useField(props.name):[null,null,null];

  const [selected, setSelected] = useState(false);
  const formik = useFormikContext();
  const [inError, setInError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.wrapInForm && meta?.error && meta?.touched) {
      setInError(true);
      // @ts-ignore
      setError(meta.error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm, meta, meta?.error, meta?.touched]);

  useEffect(() => {
    if(!formik){return;}const error = getObjectValueFromPath(props.name, formik.errors);
    if (props.wrapInForm && formik.errors && error) {
      setInError(true);
      // @ts-ignore
      setError(error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm,formik, formik?.errors]);

  useEffect(() => {
    const val = props.wrapInForm ? field?.value : props.value;
    setSelected(val);
    },[field?.value, props.value] );


  const elementClass = classNames(
    'rounded-circle',
    'square-4',
    'border-top-1',
    'border-right-1',
    'border-bottom-1',
    'border-left-1',
    'me-2',
    {'background-dark-lime-green': selected},
    'border-left-color-dark-lime-green',
    'border-top-color-dark-lime-green',
    'border-right-color-dark-lime-green',
    'border-bottom-color-dark-lime-green',
  );
  return (
    <InputComponentWrapper  showBackground={props.showBackground} styleClass={props.styleClassWrapper}>
      <InputComponentRoot styleClass={props.styleClassRoot}>
        <div className={elementClass} ref={ref} onClick={
          (e) => {
            if(!props.editable){return;}
            const sel = !selected;
            setSelected(sel);
            // @ts-ignore
            e.currentTarget.value = sel;
            // @ts-ignore
            e.target.value = sel;
            // if (field.onChange) field.onChange(e);
            helpers?.setValue(sel);
            if(props.saveFormState&&props.formId){
              const entity = {
                ...Storage.local.get(props.formId),
              };
              entity[props.name] = sel;
              Storage.local.set(props.formId, entity);
            }
            if(props.onValueChange){
              props.onValueChange(sel);
            }
          }
        }/>
        <InputComponentLabelBooleanWrapper required={props.required} label={props.label}/>
      </InputComponentRoot>
    </InputComponentWrapper>
  );
});


InputComponentBoolean.defaultProps = {
  showBackground: false,
  required: false,
  visible: true,
  editable: true,
  wrapInForm: true
}
