export enum BUTTON_SHAPE {
  ROUNDED = 'rounded',
  RECTANGULAR = 'rectangular'
}

export enum ACTION_TYPE {
  SUBMIT = 'submit',
  RESET = 'reset',
  BUTTON = 'button',
  CUSTOM = 'custom',
  LINK = 'link',
  HISTORY_PUSH = 'history_push'
}

export enum LAYOUT_TYPE {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
  LEFT_WITH_ICON_RIGHT = 'left_with_icon_right',
  RIGHT_WITH_ICON_LEFT = 'right_with_icon_left',
  TOP_ICON_BOTTOM_TEXT = 'top_icon_bottom_text',
  TOP_TEXT_BOTTOM_ICON = 'top_text_bottom_icon',
  ICON_ONLY = 'icon_only',
  NO_ICON = 'no_icon'
};

export interface IButtonProps {
  name?: string;
  type?: string;
  shape?: string;
  layout?: string;
  showLabel: boolean;
  label?: any;
  background?: string;
  color?: string;
  icon?: string | string[];
  faIcon?:boolean;
  link?: string;
  url?: string;
  submit?: boolean;
  disabled?: boolean;
  visible?:boolean;
  command?: (e) => void;
  styleRoot?: string;
  styleLayout?: string;
  styleButton?: string;
  styleTitle?: string;
  styleLabel?: string;
  styleIcon?:string;
  styleRootAppend?: boolean;
  styleLayoutAppend?: boolean;
  styleButtonAppend?: boolean;
  styleTitleAppend?: boolean;
  styleLabelAppend?: boolean;
};


export const STANDARD_SAVE_SUBMIT_PROPS: IButtonProps = {
  name: 'save',
  type: ACTION_TYPE.SUBMIT,
  shape: BUTTON_SHAPE.RECTANGULAR,
  layout: LAYOUT_TYPE.RIGHT_WITH_ICON_LEFT,
  showLabel: true,
  background: 'background-dark-lime-green',
  color: 'color-white',
  icon: 'icon-save',
  styleRootAppend: true,
  styleLayoutAppend: true,
  styleButtonAppend: true,
  styleTitleAppend: true,
  styleLabelAppend: true
};

export const STANDARD_CANCEL_PROPS: IButtonProps = {
  name: 'cancel',
  type: ACTION_TYPE.BUTTON,
  shape: BUTTON_SHAPE.RECTANGULAR,
  layout: LAYOUT_TYPE.RIGHT_WITH_ICON_LEFT,
  showLabel: true,
  background: 'background-red',
  color: 'color-white',
  icon: 'icon-delete',
  styleRootAppend: true,
  styleLayoutAppend: true,
  styleButtonAppend: true,
  styleTitleAppend: true,
  styleLabelAppend: true
};
