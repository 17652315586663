import * as React from "react";
import {Moment} from "moment";
import classNames from 'classnames/bind';

export enum INPUT_ELEMENT_TYPE {
  STRING_NORMAL = 0,
  STRING_LARGE = 1,
  NUMBER = 2,
  DATE = 3,
  DATE_RANGE = 4,
  DATETIME = 5,
  TIME = 6,
  BOOLEAN = 7,
  LIST_ONE_LARGE = 8,
  LIST_ONE_SMALL = 9,
  LIST_MANY_LARGE = 10,
  LIST_MANY_SMALL = 11,
  LABELS = 12,
  ICONS = 13,
  PASSWORD=14,
  MAIL=15,
  DUMMY=16,
  CUSTOM=17
};


export const NUMBER_THOUSANDS_GROUP_STYLE = {
  THOUSAND: 'thousand',
  LAKH: 'lakh',
  WAN: 'wan'
};

export enum DEFAULT_INPUT_ICONS {
  LOCATION = 'icon-map-pin',
  HOME = 'icon-home',
  MESSAGE = 'icon-messages',
  MAIL = 'icon-mail',

  LINK = 'icon-link',
  SIGNATURE = 'icon-signature',
  PROFILE = 'icon-profile',
  LOCKED= 'icon-locked',

  DATE = 'icon-calendar',
  TIME = 'icon-time',

  EURO = 'icon-euro',
  DOCUMENT = 'icon-document-options',

  SEARCH = 'icon-search'
};


export interface InputElementString {
  length?: number;
  keyfilter?: number;
}

//https://github.com/s-yadav/react-number-format#readme
export interface InputElementNumber {
  thousandSeparator?: string | boolean;
  decimalSeparator?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
  allowEmptyFormating?: boolean;
  allowLeadingZeros?: boolean;
  prefix?: string;
  suffix?: string;
  isNumericString?: boolean;
  displayType?: string;
}

export interface InputElementDate {
  dateDisplayFormat?: string;
  numberOfMonthsToDisplay?: number;
  isDayBlocked?: (day) => boolean;
  isOutsideRange?: (day) => boolean;
  isDayHighlighted?: (day) => boolean;
  startDateId?: string;
  startDate?: Moment;
  endDateId?: string;
  endDate?: Moment;
  onDateChange?: (date) => void;
  onDatesChange?: (startDate, endDate) => void;
  //day, modifiers
}

export interface InputElementBoolean {

}

export interface InputElementListOne {
  listMenuStyle?: {};
  listWrapperStyle?: {};
  listAutoHighlight?: boolean;

  listElements?: any[];
  itemValue?: (item: any) => { };
  itemTemplate?: (item:any)=> JSX.Element;
  selectedItemTemplate?: (item:any)=> JSX.Element;
  isItemSelectable?: (item: any) => boolean;

  listLoading?: boolean;
  resultSize?: number;
  enterSearchLabel?: string;
  noResultsLabel?: string;
  numberOfResultsLabel?: string;

  onListSearch?: (event: Event, value: any) => void;
  onItemSelect?: (value: any, item: any) => void;
}


export interface InputElementListMany {

}

export interface InputElementLabel {

}


export interface InputElementProps
  extends InputElementString,
    InputElementNumber,
    InputElementDate,
    InputElementBoolean,
    InputElementListOne,
    InputElementListMany,
    InputElementLabel {
  id?: string;
  name: string;
  type: INPUT_ELEMENT_TYPE;
  label?: string | boolean;
  format?: string;
  mask?: string;
  required: boolean;
  focused?:boolean;
  //
  showBackground?:boolean;
  visible?:boolean;
  showValue?:boolean;
  //
  labelWidth?:string;
  //
  disabled?: boolean;
  //
  placeholder?: string;
  //
  icon?: string | string[];
  //
  onCreateError?:(props:InputElementProps)=>JSX.Element;
  onCreateComponent?:(props:InputElementProps)=>JSX.Element;

  //
  onChange?: (e)=>void;
  onBlur?:(e)=>void;
  onClick?:(e)=>void;
};

export const DEFAULT_NUMBER_INTEGER_PROPS: InputElementNumber = {
  thousandSeparator: true,
  decimalSeparator: '.',
  decimalScale: 0,
  fixedDecimalScale: true,
  allowNegative: false,
  allowEmptyFormating: true,
  allowLeadingZeros: false,
  displayType: 'input'
};

export const DEFAULT_NUMBER_2_PROPS: InputElementNumber = {
  thousandSeparator: true,
  decimalSeparator: '.',
  decimalScale: 2,
  fixedDecimalScale: true,
  allowNegative: true,
  allowEmptyFormating: true,
  allowLeadingZeros: false,
  displayType: 'input'
};

export const DEFAULT_NUMBER_4_PROPS: InputElementNumber = {
  thousandSeparator: true,
  decimalSeparator: '.',
  decimalScale: 4,
  fixedDecimalScale: true,
  allowNegative: true,
  allowEmptyFormating: true,
  allowLeadingZeros: false,
  displayType: 'input'
};

export const DEFAULT_NUMBER_6_PROPS: InputElementNumber = {
  thousandSeparator: true,
  decimalSeparator: '.',
  decimalScale: 6,
  fixedDecimalScale: true,
  allowNegative: true,
  allowEmptyFormating: true,
  allowLeadingZeros: false,
  displayType: 'input'
};


export const DEFAULT_LIST_ITEM_RENDER = (item, selected:boolean, extractValue: (item) => any) => {
  const cl = classNames('item', 'px-1', {'selected': selected}, {'not-selected': !selected});
  return <div key={extractValue(item)} className={cl}>{extractValue(item)}</div>
};


