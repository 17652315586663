import React from "react";
import {createBooleanLabelWrapperClass} from "../utils/create_styles";

export interface IInputComponentLabelBooleanWrapperProps {
  label?: string;
  required: boolean;
}

export class InputComponentLabelBooleanWrapper extends React.Component<IInputComponentLabelBooleanWrapperProps>{
  render() {
    return (
      this.props.label ?
        <div className={createBooleanLabelWrapperClass(this.props.required, this.props.label ? true : false)}>{this.props.label}</div> :
        <></>
    );
  }
}
