import React, {useEffect, useState} from "react";
import {DataTableColumn} from "./data-table-column";
import classNames from 'classnames/bind';
import _ from 'lodash';

export interface IDataTableHeaderCellProps {
  column: DataTableColumn;
  colIndex: number;
  columnCount:number;
  onSort: (columnName:DataTableColumn, colIndex:number, order:string)=>void;
  defaultSortOrder: boolean;
}

const DataTableHeaderCell = (props: IDataTableHeaderCellProps) => {
  const [sortOrder, setSortOrder] = useState('');
  useEffect(()=>{
    if(props.defaultSortOrder)setSortOrder('');
  },[props.defaultSortOrder]);

  const headerComp = props.column.headerTemplate ? props.column.headerTemplate(props.column, props.colIndex, props.columnCount) : (
    <div className={classNames('d-flex', 'flex-row', 'justify-content-between')}>
      <div className={classNames('text','ms-1')}>{props.column.header}</div>
      <div className={
        classNames(
        'sort',
        'flex-column',
        'justify-content-center',
        'align-items-center',
        {'d-none':!props.column.sortable},
        {'d-flex': props.column.sortable}
        )}
      >
        <div className={
          classNames(
            'asc',
            'mb-1',
            {'selected':sortOrder==='asc'},
            {'notselected':sortOrder!=='asc'}
            )
        }/>
        <div className={
          classNames(
            'desc',
            {'selected':sortOrder==='desc'},
            {'notselected':sortOrder!=='desc'}
            )
        }/>
      </div>
    </div>
  );

  return (
    <th
      className={
        classNames(
          'cell',
          {'first':props.colIndex===0},
          {'middle':props.columnCount-1!==props.colIndex&&props.colIndex!=0},
          {'last':props.columnCount-1===props.colIndex},
          props.column.headerClassName
        )
      }
      scope={props.column.headerScope}
      onClick={(e) => {
        if(!props.column.sortable){return;}
        const so = 'asc' == sortOrder ? 'desc' : 'asc';
        setSortOrder(so);
        props.onSort(props.column, props.colIndex,so);
      }}
    >
      {headerComp}
    </th>
  );
}

export default DataTableHeaderCell;

