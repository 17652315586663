import React  from 'react';
import { InputElementProps } from "./input_element_contstants";
import { createInputElement } from "./input_element_utils";

export const InputElement = (props:InputElementProps) => {
  const el =  createInputElement(props);
  return el?el:<></>;
}

InputElement.defaultProps = {
  required:false,
  error:false,
  label: false,
  focused:false,
  //
  showBackground:true,
  visible:true,
  showValue: true,
  placeholder: null,
  width: '175px',
  //date
  numberOfMonthsToDisplay:1,
  isDayBloked: (day)=>false,
  isOutsideRange: (day)=>false,
  //
  listLoading:false,

}





