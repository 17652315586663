import React from "react";
import classNames from "classNames/bind";

interface IFlatTableDataRowProps{
  last?:boolean;
  gridLayout?: boolean;
  className?:string;
}
/**
 *
 */
export class FlatTableDataRow extends React.Component<IFlatTableDataRowProps> {

  static defaultProps = {
    last: false,
    gridLayout: true
  };

  constructor(props) {
    super(props);
  };

  render() {
    const dataRow = classNames({'row g-0':this.props.gridLayout}, this.props.className, 'pt-1 pb-1','tablica_regular', {'border-bottom-1 border-bottom-color-silver ':!this.props.last});
    return (
      <div {...this.props} className={dataRow}>
        {this.props.children}
      </div>
    );
  }
};
