import React from "react";
import {Link} from "react-router-dom";
/**
 *  LayoutStandardHeaderLogo
 *
 */
export interface ILayoutStandardHeaderLogoProps{
  logo?: JSX.Element
  link?: string;
}

const LayoutStandardHeaderLogo = (props:ILayoutStandardHeaderLogoProps) => {
  return (
    <div className={"d-flex align-items-center appLogo mx-2"}> <Link to={props.link?props.link:"/"} className={"w-100"}>{props.logo}</Link></div>
  );
};

export default LayoutStandardHeaderLogo;
