import React from "react";
import {UserContext} from "../../../../../utils/auth/User";
import onClickOutside from "react-onclickoutside";
import {ProfileLink} from "../../utils/profile.link";
import {LayoutStandardProfileLink} from "./layout.standard.profile.link";
import {v4} from 'uuid';
import {LayoutHelp} from "../../utils/layout.help";
import {LayoutLogout} from "../../utils/layout.logout";
import {Link} from "react-router-dom";
/**
 *  LayoutStandardProfile
 *
 */
export interface ILayoutStandardProfileProps{
  setShowProfile: (showProfile:boolean)=>void;
  links?: ProfileLink[];
  help?:LayoutHelp;
  logout: LayoutLogout;
}

const LayoutStandardProfile = onClickOutside( (props:ILayoutStandardProfileProps) => {
  LayoutStandardProfile.handleClickOutside = () => props.setShowProfile(false);
  return (
    <UserContext.Consumer>
      {
        user => (
          <div>
            <div className={"d-flex no-gutters"}>
              <div className={"username ps-3 pt-2 pe-3"}>{user.username}</div>
            </div>
            <div className={"d-flex no-gutters"}>
              <div className={"col-24 name ps-3 pb-2 pe-3"}>{user.name}</div>
            </div>
            <div className={"d-flex no-gutters "}>
              <div className={"col-24 company ps-3 pt-2 pb-2 me-3 border-top border-bottom"}>{user.company}</div>
            </div>
            {props.links && props.links.map(link => <LayoutStandardProfileLink key={v4()} link={link}/>)}
            {props.help?.showHelp?<div className={"d-flex no-gutters "}>
              <div className={"col-24 help ps-3 pb-2 pe-3 border-bottom"}><Link to={props.help?.helpLink}>{props.help?.helpLabel}</Link></div>
            </div>:<></>}
            <div className={"row no-gutters"}>
              <div className={"col-24 signout ps-3 pt-2 pb-2 pe-3"}>
                <Link to={props.logout.link}>{props.logout.label}</Link>
              </div>
            </div>
          </div>
        )
      }
    </UserContext.Consumer>
  )
  },{handleClickOutside: () => LayoutStandardProfile.handleClickOutside});

export default LayoutStandardProfile;
