import React, {MutableRefObject, useEffect, useState} from "react";
import {InputComponent} from "./input-component";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import {useField, useFormikContext} from "formik";
import makeAnimated from 'react-select/animated';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import {Empty} from "./input-component-empty";
import {getObjectValueFromPath} from "../../../utils/common/object_path";

export interface IInputComponentSelectAsyncProps {
  id: string;
  name: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;

  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  editable?: boolean;
  info?: any;
  loading?: boolean;
  creatable?: boolean;
  multipleSelection?: boolean;

  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?: boolean;
  /** Used as value if wrapInForm === false*/
  value?: any;
  // props for Select element
  // https://react-select.com/home
  selectProps?: any;
  //
  loadOptions: (inputValue) => {};
  getOptionValue?: (option) => any;
  getOptionStringValue?: (option) => string;
  getOptionLabel?: (option) => any;
  onChangeOption?: (option) => void;
  onChange?: (newValue: any, actionMeta: any) => boolean | void;
  onInputChange?: (inputValue: any, actionMeta: any) => boolean | void;
  onCreate?: (inputValue: any) => boolean | void;

  styleClassWrapper?: string;
  styleClassRoot?: string;
  styleClassLabel?: string;
  styleClassInput?: string;
  styleClassElement?: string;
  styleClassError?: string;
  styleClassIcon?: string;
  styleClassInfo?: string;

};

const animatedComponents = makeAnimated();

export const InputComponentSelectAsync = React.forwardRef((props: IInputComponentSelectAsyncProps, ref: MutableRefObject<any>) => {
  // Return empty if not visible
  if (!props.visible) return <></>;
  // If wrapi in form, fetch fields
  const [field, meta, helpers] = props.wrapInForm ? useField(props.name) : [null, null, null];
  const value = props.wrapInForm ?
    field?.value ? field.value : '' :
    props.value;
  const formik = useFormikContext();
  const [inError, setInError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.wrapInForm && meta?.error && meta?.touched) {
      setInError(true);
      // @ts-ignore
      setError(meta.error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm, meta, meta?.error, meta?.touched]);

  useEffect(() => {
    if(!formik){return;}const error = getObjectValueFromPath(props.name, formik.errors);
    //const touched = getObjectValueFromPath(props.name, formik.touched);
    if (props.wrapInForm && formik.errors && error){
      setInError(true);
      // @ts-ignore
      setError(error);
    } else {
      setInError(false);
    }
  }, [props.wrapInForm,formik, formik?.errors]);
  const [inFocus, setInFocus] = useState(false);

  const [inputValue, setInputValue] = useState(null);

  const handleLoadOptions = (inVal) => {
      // const res = props.loadOptions(inVal);
      // if (props.creatable && Array.isArray(res)) {
      //   res.push("Create " + inVal);
      // }
    return new Promise(resolve => {
       resolve(props.loadOptions(inVal));
    });
  }

  const handleChange = (newValue: any, actionMeta: any) => {
    if (props.onChange && props.onChange(newValue, actionMeta)) {
      return;
    }
    switch (actionMeta?.action) {
      case "select-option":
      case "set-value": {
        props.getOptionValue ? helpers?.setValue(props.getOptionValue(newValue)) : helpers?.setValue(newValue);
        if (props.onChangeOption) props.onChangeOption(newValue);
        break;
      }
      case "remove-value":
      case "clear":
      case 'pop-value':
        helpers?.setValue(null);
        if (props.onChangeOption) props.onChangeOption(null);
        break;
      default:
        break;

    }
  };
  const handleInputChange = (inVal: any, actionMeta: any) => {
    setInputValue(inputValue);
    if (props.onInputChange && props.onInputChange(inVal, actionMeta)) {
      return;
    }
  };

  const handleCreate = (inVal: any) => {
    if (props.onCreate && props.onCreate(inVal)) {
      return;
    }
  };

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      border: 'none'
    }),
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: props.editable ? provided.display : 'none'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      display: props.editable ? provided.display : 'none'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontWeight: props.required ? '700 !important' : '400 !important',
      color: '#000000',

    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '999999 !important'
    })
  }

  const elProps = {
    ref: ref,
    // @ts-ignore
    defaultValue: value,
    components: animatedComponents,
    isDisabled: !props.editable,
    styles: customStyles,
    cacheOptions:true,
    defaultOptions:true,
    isLoading: props.loading,

    isMulti: props.multipleSelection,
    loadOptions: handleLoadOptions,
    onChange: handleChange,
    onInputChange: handleInputChange,
    onCreateOption: handleCreate,
    onFocus: (e) => {
      setInFocus(true);
    },
    onBlur: (e) => setInFocus(false),
    getOptionLabel: props.getOptionLabel,
    getOptionValue: props.getOptionStringValue,
    createOptionPosition: "last",
    allowCreateWhileLoading: true,
    ...props.selectProps
  }


  return (
    // @ts-ignore
    <InputComponent {...props} error={error} focused={inFocus}>
      {props.editable ?
        props.creatable ? <AsyncCreatableSelect {...elProps}/> : <AsyncSelect  {...elProps} /> :
        <Empty required={props.required} inError={inError}
          // @ts-ignore
               value={props.getOptionLabel(value)} ref={ref}/>}
    </InputComponent>
  );
});

InputComponentSelectAsync.defaultProps = {
  showBackground: false,
  required: false,
  visible: true,
  editable: true,
  wrapInForm: true,
  loading: false,
  creatable: false,
  multipleSelection: false,
  getOptionValue: (value) => value,
  getOptionLabel: (label) => label,
  selectProps: {},
}
