import React, {MutableRefObject} from "react";
import {useField} from "formik";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import { createInputTextClass } from "../utils/create_styles";
import {InputComponent} from "./input-component";
import {Empty} from "./input-component-empty";


export interface IInputComponentDummyProps {
  id: string;
  name: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;

  focused: boolean;
  showValue: boolean;
  editable?:boolean;
  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  info?:any;

  onClick: (e) => void;
}

export const InputComponentDummy = React.forwardRef((props: IInputComponentDummyProps, ref:MutableRefObject<any>) => {
  if(!props.visible)return null;
  const [field, meta] = useField(props.name);
  const inError = meta.error !== undefined && meta.touched;

  return (
    <InputComponent {...props}>
      <Empty required={props.required} inError={inError} ref={ref} onClick={(e)=>{if(!props.editable){return;}props.onClick(e)}} value={props.showValue ? field.value : ''}/>
    </InputComponent>
  );
});

InputComponentDummy.defaultProps = {
  showBackground: false,
  required: false,
  editable: true,
  showValue: true,
  visible: true
}
