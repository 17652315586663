import React from 'react';
import {IButtonProps, ACTION_TYPE, LAYOUT_TYPE} from './action_button_constatns';
import classNames from 'classnames/bind';
import {BUTTON_SHAPE} from './action_button_constatns';
import {Link, useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


const calculateIcon = (props: IButtonProps) => {
  const iconClass = classNames(
    {'icon': !props.faIcon},
    props.faIcon ? null : props.icon,
    props.styleIcon
  );

  const el = props.faIcon ?
    // @ts-ignore
    <FontAwesomeIcon className={iconClass} icon={props.icon}/>:
    <div className={iconClass}></div>;

  switch (props.layout) {
    case LAYOUT_TYPE.CENTER:
    case LAYOUT_TYPE.LEFT:
    case LAYOUT_TYPE.RIGHT:
    case LAYOUT_TYPE.LEFT_WITH_ICON_RIGHT:
    case LAYOUT_TYPE.RIGHT_WITH_ICON_LEFT:
    case LAYOUT_TYPE.TOP_ICON_BOTTOM_TEXT:
    case LAYOUT_TYPE.TOP_TEXT_BOTTOM_ICON:
    case LAYOUT_TYPE.ICON_ONLY:
      return props.icon ? el : <></>;
    case LAYOUT_TYPE.NO_ICON:
    default:
      return <></>;
  }
};

const calculateLabel = (props: IButtonProps) => {

  const labelClass = classNames(
    'label',
    props.styleLabel
  );
  const el = <div className={labelClass}>{props.label}</div>;
  return props.showLabel ? el : <></>;
}


const calculateButtonLayout = (props: IButtonProps) => {
  const layoutClass = classNames(
    {'action-button': props.styleRootAppend},
    props.styleRoot
  );
  const buttonClass = classNames(
    {'button': props.styleButtonAppend},
    props.background,
    props.color,
    {'btn-rounded': props.shape === BUTTON_SHAPE.ROUNDED},
    {'btn-rectangular': props.shape === BUTTON_SHAPE.RECTANGULAR},
    props.styleButton
  );
  switch (props.layout) {
    case LAYOUT_TYPE.NO_ICON:
    case LAYOUT_TYPE.CENTER:
      return (
        <div className={layoutClass}>
          <div className={classNames(buttonClass, 'layout-center')}>
            {calculateIcon(props)}
            {calculateLabel(props)}
          </div>
        </div>
      );
    case LAYOUT_TYPE.ICON_ONLY:
      return (
        <div className={layoutClass}>
          <div className={classNames(buttonClass, 'layout-center')}>
            {calculateIcon(props)}
          </div>
        </div>
      );
    case LAYOUT_TYPE.LEFT:
      return (
        <div className={layoutClass}>
          <div className={classNames(buttonClass, 'layout-left')}>
            {calculateIcon(props)}
            {calculateLabel(props)}
          </div>
        </div>
      );
    case LAYOUT_TYPE.RIGHT:
      return (
        <div className={layoutClass}>
          <div className={classNames(buttonClass, 'layout-right')}>
            {calculateIcon(props)}
            {calculateLabel(props)}
          </div>
        </div>
      );
    case LAYOUT_TYPE.LEFT_WITH_ICON_RIGHT:
      return (
        <div className={layoutClass}>
          <div className={classNames(buttonClass, 'layout-center')}>
            {calculateLabel(props)}
            {calculateIcon(props)}
          </div>
        </div>
      );
    case LAYOUT_TYPE.RIGHT_WITH_ICON_LEFT:
      return (
        <div className={layoutClass}>
          <div className={classNames(buttonClass, 'layout-center')}>
            {calculateIcon(props)}
            {calculateLabel(props)}
          </div>
        </div>
      );
    case LAYOUT_TYPE.TOP_ICON_BOTTOM_TEXT:
      return (
        <div className={layoutClass}>
          <div className={classNames(buttonClass, 'layout-center')}>
            {calculateIcon(props)}
          </div>
          {calculateLabel(props)}
        </div>
      );
    case LAYOUT_TYPE.TOP_TEXT_BOTTOM_ICON:
      return (
        <div className={layoutClass}>
          {calculateLabel(props)}
          <div className={classNames(buttonClass, 'layout-center')}>
            {calculateIcon(props)}
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

const calculateButtonType = (props: IButtonProps) => {
  switch (props.type) {
    case ACTION_TYPE.SUBMIT:
      return 'submit';
    case ACTION_TYPE.RESET:
      return 'reset';
    case ACTION_TYPE.LINK:
    case ACTION_TYPE.HISTORY_PUSH:
    case ACTION_TYPE.BUTTON:
    case ACTION_TYPE.CUSTOM:
    default:
      return 'button';
  }
};

const createButton = (props: IButtonProps) => {
  const rootStyle = classNames(
    props.styleRoot
  );
  const history = useHistory();
  const el = (
    <button
      name={props.name}
      type={calculateButtonType(props)}
      className={rootStyle}
      onClick={
        (e) => {
          if (props.command) {
            props.command(e);
          }
          if (props.type === ACTION_TYPE.HISTORY_PUSH) {
            history.push(props.link);
          }
        }
      }
      disabled={props.disabled}
    >
      {calculateButtonLayout(props)}
    </button>
  );
  return props.type === ACTION_TYPE.LINK ? <Link to={props.link}>{el}</Link> : el;
};


export const ActionButton = (props: IButtonProps) => {
  return props.visible?createButton(props):<></>;
};

// @ts-ignore
ActionButton.defaultProps = {
  type: ACTION_TYPE.CUSTOM,
  shape: BUTTON_SHAPE.ROUNDED,
  layout: LAYOUT_TYPE.TOP_ICON_BOTTOM_TEXT,
  faIcon: false,
  label: 'button',
  showLabel: true,
  visible: true,
  background: 'backgroud-btn-default',
  color: 'color-btn-default',
  icon: null,
  submit: false,
  disabled: false,
  styleRootAppend: true,
  styleLayoutAppend: true,
  styleButtonAppend: true,
  styleTitleAppend: true,
  styleLabelAppend: true
};




