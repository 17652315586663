import React from "react";
import classNames from 'classnames/bind';
import {MESSAGE_TYPE} from "./form-message";
import ReactLoading from "react-loading";


export interface IFormInputSimpleProps {
  title: string;
  useDefaultStyles: boolean;
  formStyleClass?: string;
  wrapperStyleClass?: string;
  titleStyleClass?: string;
  contentStyleClass?: string;
  messageType?: MESSAGE_TYPE;
  message?: any;
  loading?:boolean;
  onEsc?: (e) => void;
}

export class FormInputSimple extends React.Component<IFormInputSimpleProps> {
  static defaultProps = {
    useDefaultStyles: true,
    onEsc: () => {
    }
  }

  constructor(props) {
    super(props);
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.props.onEsc) {
      this.props.onEsc(event);
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const formClass = classNames(
      {'form-ui': this.props.useDefaultStyles},
      this.props.formStyleClass
    );
    const formWrapperClass = classNames(
      {'form-wrapper': this.props.useDefaultStyles},
      this.props.wrapperStyleClass
    );
    const formTitleClass = classNames(
      {'form-title': this.props.useDefaultStyles},
      this.props.titleStyleClass
    );
    const formErrorClass = classNames(
      {'error-message': this.props.messageType === MESSAGE_TYPE.ERROR},
      {'warrning-message': this.props.messageType === MESSAGE_TYPE.WARRNING},
      {'info-message': this.props.messageType === MESSAGE_TYPE.INFO},
      {'d-none': !this.props.message}
    );
    const formContentClass = classNames(
      {'form-content': this.props.useDefaultStyles},
      this.props.contentStyleClass
    );
    const formElement = (
      <div className={formClass}>
        <div className={formWrapperClass}>
          <div className={formTitleClass}>{this.props.title}</div>
          {this.props.message ? <div className={formErrorClass}>{this.props.message.message?this.props.message.message:JSON.stringify(this.props.message)}</div> : <></>}
          <div className={formContentClass}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
    const loadingElement = (
      <div className={"d-flex h-100 w-100 justify-content-center align-items-center"}>
        <ReactLoading type={'spinningBubbles'}  color={'#000000'} height={'30%'} width={'30%'}/>
      </div>
    );

    return this.props.loading?loadingElement:formElement;
  }
}

