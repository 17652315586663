import React, {MutableRefObject, useEffect, useState} from "react";
import {useField} from "formik";
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";
import {InputComponent} from "./input-component";
import ReactMarkdown from 'react-markdown/with-html';
import {Controlled as CodeMirror} from 'react-codemirror2';
import toc from 'remark-toc';

/*
 *  https://github.com/rexxars/react-markdown#options
 */

export interface IInputComponentMarkdownProps {
  id: string;
  name: string;
  label?: string;
  icon?: DEFAULT_INPUT_ICONS;

  /** If wrapInForm=== true, component expects Formik handler above*/
  wrapInForm?: boolean;
  /** Used as value if wrapInForm === false*/
  value?: any;
  config?: any;
  /**If saveFormState.*/
  formId?: string;
  /**Does the state of this field is saved*/
  saveFormState?: boolean;

  editable?: boolean;
  required: boolean;
  showBackground?: boolean;
  visible?: boolean;
  info?: any;

  onChange?: (e) => void;
  onBlur?: (e) => void;
  onFocus?: (e) => void;
}

export const InputComponentMarkdown = React.forwardRef((props: IInputComponentMarkdownProps, ref: MutableRefObject<any>) => {
  if (!props.visible) return null;
  const [field, meta, helpers] = props.wrapInForm ? useField(props.name) : [null, null, null];
  const [inFocus, setInFocus] = useState(false);
  const inError = props.wrapInForm ? (meta?.error !== undefined && meta?.touched) : false;


//   onChange={(editor, data, value)=>{
//     if (props.onChange) props.onChange(value);
//     field?.onChange(value);
//     if(props.saveFormState&&props.formId){
//       const entity = {
//         ...Storage.local.get(props.formId),
//       };
//       entity[props.name] = value;
//       Storage.local.set(props.formId, entity);
//     }
//   }
// }


  return (
    <InputComponent focused={inFocus}>
      {props.editable ?
        // @ts-ignore
        <CodeMirror mode="markdown"
                    theme="material"
                    value={props.wrapInForm ? field?.value : props.value}
                    options={{
                      mode: 'markdown',
                      highlightFormatting: true,
                      allowAtxHeaderWithoutSpace: true
                    }}
                    onBeforeChange={(editor, data, value) => {
                      console.log('-->' + value);
                      helpers?.setValue(value);
                    }}
        />
        :
        // ref={this.setRef}
        <div>
          <pre>
        <code>
          <ReactMarkdown
            source={props.wrapInForm ? field?.value : props.value}
            escapeHtml={false}
          />
        </code>
            </pre>
        </div>
      }
    </InputComponent>
  );
});

InputComponentMarkdown.defaultProps = {
  showBackground: false,
  required: false,
  visible: true,
  wrapInForm: true,
  editable: true
};


