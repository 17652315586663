import React from "react";
import {defaultTemplateStringFK} from "./templateFK";
import {calculateRootClassName} from "./template-styles";
import {calculateIcon, calculateLabel} from "./template-elements";
import {TemplateProps} from "./template-props";
import {DataTableColumn} from "../data-table/data-table-column";


export const dataTableItemTemplateChildEntity = (column:DataTableColumn, rowData:any, rowIndex:number, colIndex:number, columnCount:number,
                                    fkValueExtractFunction: (rowData: any, column:DataTableColumn) => any, props?: TemplateProps) => {
  const pr = props ? props : defaultTemplateStringFK;
  return (
    <div className={calculateRootClassName(pr)}>
      {calculateIcon(pr)}
      {calculateLabel(fkValueExtractFunction(rowData,column), pr)}
    </div>
  );
};
