import classNames from 'classnames/bind';
import {DEFAULT_INPUT_ICONS} from "../input_element_contstants";

export const createInputElementWrapperClass = (showBackground, styleClass?:string) => classNames(
  'input-element',
  {'use-background': showBackground},
  'mx-1',
  'my-1',
  styleClass
);


export const createInputElementClass = (styleClass?:string) => classNames(
  'input-element-wrapper',
  'd-flex',
  'flex-row',
  'px-2',
  'pb-1',
  'pt-2',
  styleClass
);

export const createLabelWrapperClass = (required:boolean, visible:boolean, styleClass?:string) => classNames(
  'label-wrapper',
  'me-2',
  'd-flex',
  'flex-row',
  'justify-content-start',
  'align-items-center',
  {'fw-bold': required},
  {'font-weight-normal': !required},
  {'d-none': !visible},
  styleClass
);

export const createBooleanLabelWrapperClass = (required:boolean, visible:boolean, styleClass?:string) => classNames(
  'label-wrapper',
  'd-flex',
  'flex-row',
  'justify-content-start',
  'align-items-center',
  {'fw-bold': required},
  {'font-weight-normal': required},
  {'d-none': !visible},
  styleClass
);

export const createInputWrapperClass = (inFocus: boolean, inError: boolean, styleClass?:string) => classNames(
  'input-wrapper',
  'd-flex',
  'col',
  'flex-row',
  'justify-content-start',
  'align-items-center',
  {'infocus': inFocus},
  {'error': inError},
  styleClass
);

export const createIconWrapperClass = (icon?:string|string[], styleClass?:string) => classNames(
  'icon-wrapper',
  'ms-1',
  'icon',
  icon,
  styleClass
);

export const createElementWrapperClass = (styleClass?:string) => classNames(
  'element-wrapper',
  styleClass
);

export const createInputTextClass = (required: boolean, inError: boolean, styleClass?:string, appendStyleClass?:boolean) => appendStyleClass||!styleClass?classNames(
  'w-100',
  'inputtext',
  {'fw-bold': required},
  {'font-weight-normal': !required},
  {'error': inError},
  styleClass
):styleClass;



export const createErrorWrapperClass = (styleClass?:string) => classNames(
  'error-message',
  'text-truncate',
  styleClass
);

export const createInfoWrapperClass = (styleClass?:string) => classNames(
  'info-message',
  'text-truncate',
  styleClass
);
