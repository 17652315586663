import React from "react";
import classNames from 'classnames/bind';

import {IButtonProps} from "../../components";


export interface IActionBarProps {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;

  editMode?:boolean;

  rootClassName?: string;
  rootClassNameAppend?: boolean;
  titleWrapperClassName?: string;
  titleWrapperClassNameAppend?: boolean;
  titleClassName?: string;
  titleClassNameAppend?: boolean;
  subtitleClassName?: string;
  subtitleClassNameAppend?: boolean;
  actionsClassName?:string;
  actionsClassNameAppend?:boolean;
}

export class ActionBar extends React.Component<IActionBarProps> {
  static defaultProps = {
    rootClassNameAppend: true,
    titleClassNameAppend: true,
    titleWrapperClassNameAppend: true,
    subtitleClassNameAppend: true,
    actionsClassNameAppend: true,
    editMode: false
  }

  constructor(props) {
    super(props);
  }


  render() {
    // ~~~~~ styles ~~~~~
    const rootStyle = this.props.rootClassNameAppend ? classNames(
      'actionbar',
      {'editable': this.props.editMode},
      this.props.rootClassName
    ) : this.props.rootClassName;
    const titleWrapperStyle = this.props.titleWrapperClassNameAppend ? classNames(
      'title-wrapper',
      this.props.titleWrapperClassName
    ) : this.props.titleWrapperClassName;
    const titleStyle = this.props.titleClassNameAppend ? classNames(
      'title',
      this.props.titleClassName
    ) : this.props.titleClassName;
    const subtitleStyle = this.props.subtitleClassNameAppend ? classNames(
      'subtitle',
      this.props.subtitleClassName
    ) : this.props.subtitleClassName;
    const actionsStyle = this.props.actionsClassNameAppend ? classNames(
      'actions',
      this.props.actionsClassName
    ) : this.props.actionsClassName;
    const actionsLeftStyle = classNames( actionsStyle, 'left');
    const actionsRightStyle = classNames( actionsStyle, 'right');

    // ~~~~~ elements ~~~~~
    const title = this.props.title ? (
      <h1 className={titleStyle}>{this.props.title}</h1>
    ) : null;
    const subtitle = this.props.title ? (
      <span className={subtitleStyle}>{this.props.subtitle}</span>
    ) : null;
    const titleWrapper = this.props.title ? (
      <div className={titleWrapperStyle}>{title}{subtitle}</div>
    ) : null;
    const actionsRight = this.props.children ? (
      <div className={actionsRightStyle}>{this.props.children}</div>
    ) : <div/>;
    // actionsLeft -> mogucnost da se ubace akcije i s lijeve strane? trenutno placeholder radi flexa
    const actionsLeft = <div className={actionsLeftStyle} />;

    return (
      <div className={rootStyle}>
        {actionsLeft}
        {titleWrapper}
        {actionsRight}
      </div>
    );
  }
}
